import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "../../../config/endPoints";
import { defaultErrorNotification, defaultSuccessNotification } from "../../../constants"


const formatDate = (d) => {
  var day, month, year;
  month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

const defaultFilter = {
  id: "",
  id_app: "",
  id_slot: "",
  type: "",
  value: "",
  payment_type: "",
  status: "",
  start_date: "",
  end_date: "",
  observation: "",
  bank: "",
}

const defaultVipSaleItem = {
  id: 0,
  player_id: null,
  club_id: 0,
  type: 0,
  value: 0,
  payment_type: 0,
  status: 0,
  bank_id: null,
  date: "",
  receipt_file: "",
  receipt: "",
  pix_id: 0,
  date_picker: new Date(),
  date_created: "",
  contact_id: "",
  player_id_withdraw: null, 
  club_id_withdraw: "",
  contact_name: "",
  pix_identifier: "",
  instance_id: ""
};

const defaultPix = {
  available: false,
  found: false,
  value: 0,
  inscription: "",
  e2e: "",
}

export default {
  namespaced: true,
  state: {
    items: [],
    banks: [],
    selectedItem: {},
    mode: "",
    loading: false,
    totalItems: 0,
    deleteDialog: false,
    endToEndId_readed: "",
    concludeDialog: false,
    filterDialog: false,
    showDialog: false,
    dialogReceipt: false,
    depositReceipt: {},
    receiptMimeType: 'pdf',
    receipt_show: "",
    overlay: false,
    screen: "",
    filter: {
      player_id: "",
      club_id: "",
      type: "",
      value: "",
      payment_type: "",
      status: "",
      start_date: "",
      end_date: "",
      bank_name: "",
      user_id: "",
    },
    notification: {
      show: false,
      message: "",
      type: "",
    },
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },

    loadingSearchPix: false,
    dialogPixRelated: false,
    dialogSearchPix: false,
    totalPixes: 0,
    pix_relateds: [],
    pix_found: [],
    pix_identifier_readed: "",
    paginationPix: {
      page: 1,
      itemsPerPage: 5
    },
    pix: Object.assign({}, defaultPix),
    pix_search: {
      date_start: '',
      date_end: '',
      bank_pix: 77,
    },
    pix_searched: false,
  },
  mutations: {
    add: (state, payload) => {
      state.mode = "ADD";
      defaultVipSaleItem.date_picker = new Date();
      state.selectedItem = Object.assign({}, defaultVipSaleItem);
      if (payload) {
        state.screen = payload.screen
        state.selectedItem.contact_id = payload.contact_id
        state.selectedItem.contact_name = payload.contact_name
        state.selectedItem.instance_id = payload.instance_id
        state.selectedItem.message_id = payload.message_id
        if(payload.payment_type) state.selectedItem.payment_type = payload.payment_type
        if(payload.receipt) state.selectedItem.receipt_file = payload.receipt
      } else {
        state.endToEndId_readed = ""
      }
      state.showDialog = true;
    },
    deleteVip: (state, payload) => {
      state.selectedItem = payload;
      state.deleteDialog = true;
    },
    concludeVip: (state, payload) => {
      state.selectedItem = payload;
      state.concludeDialog = true;
    },
    edit: (state, payload) => {
      payload.date_picker = new Date(payload.date_created);
      state.selectedItem = Object.assign({}, payload);
      state.showDialog = true;
      state.mode = "EDIT";
    },
    view: (state, payload) => {
      state.selectedItem = payload;
      state.selectedItem.date_picker = new Date(payload.date_created);
      state.showDialog = true;
      state.mode = "VIEW";
    },
    clearFilter: (state) => {
      state.filter = Object.assign({}, defaultFilter)
    },

    overlay: (state, payload) => {
      state.overlay = payload.show
    },
    setItems: (state, payload) => (state.items = payload),
    setTotalItems: (state, payload) => (state.totalItems = payload),
    setLoading: (state, payload) => (state.loading = payload),
    showNotification: (state, payload) => (state.notification = payload),
    setSelectedItem: (state, payload) => state.selectedItem = payload,
    setDeleteDialog: (state, payload) => (state.deleteDialog = payload),
    setConcludeDialog: (state, payload) => (state.concludeDialog = payload),
    setShowDialog: (state, payload) => {
      state.showDialog = payload;
    },
    setShowFilter: (state, payload) => (state.filterDialog = payload),
    setPage: (state, payload) => {
      state.pagination.page = payload.page;
      state.pagination.itemsPerPage = payload.itemsPerPage;
    },
    setDepositReceipt: (state, payload) => state.depositReceipt = payload.item,
    setReceiptMimeType: (state, payload) => state.receiptMimeType = payload.receiptMimeType,
    setDialogReceipt: (state, payload) => (state.dialogReceipt = payload.show),
    setReceipt: (state, payload) => (state.receipt_show = payload.receipt),
    setScreen: (state, payload) => state.screen = payload.screen,
    showDialogAbate: (state, payload) => state.dialogAbate = payload.show,
    setPixRelateds: (state, payload) => {
      state.totalPixes = payload.total,
      state.pix_relateds = payload.pixes
    },
    showDialogPixRelateds: (state, payload) => state.dialogPixRelated = payload.show,
    showDialogSearchPix: (state, payload) => {
      state.dialogSearchPix = payload.show
    },
    setLoadingSearchPix: (state, payload) => {
      state.loadingSearchPix = payload.show
    },
    setPix: (state, payload) => state.pix = payload.pix,
    resetPix: (state) => {
      state.pix = Object.assign({}, defaultPix)
      state.pix_searched = false
    },
    setPixSearched: (state, payload) => state.pix_searched = payload.searched,
    updateField,
  },
  actions: {
    closeDialog: (({ commit, state }) => {
      state.selectedItem = Object.assign({}, defaultVipSaleItem);
      
      if (state.screen != "messages") {
        commit('setShowDialog', false)
      } else {
        commit('setShowDialog', false)
        commit('message/showDialogVipSale', { show: false }, { root: true })
      }
    }),
    createByChat: async ({ commit, dispatch, state }, payload) => {
      if(payload.receipt) {
        await dispatch('getEndToEnd', { receipt: payload.receipt })
      }
      await commit('add', { screen: payload.screen, contact_id: payload.contact_id, contact_name: payload.contact_name, instance_id: payload.instance_id, message_id: payload.message_id, payment_type: payload.payment_type, receipt: payload.receipt })
      commit('message/showDialogVipSale', { show: true }, { root: true })
      if(state.pix.found && state.pix.available) {
        state.selectedItem.endToEndId = state.pix.e2e;
      }
    },
    searchPix: async ({ state, commit }) => {
      commit('setLoadingSearchPix', { show: true })
      commit('showDialogSearchPix', { show: false })

      const url = endPoints.pix + "/search?start=" + state.pix_search.date_start + "&end=" + state.pix_search.date_end + "&bank=" + state.pix_search.bank_pix

      try {
        await axios.get(url).then(() => {
          commit('setLoadingSearchPix', { show: false })
          let notification = {
            show: true,
            message: "Os Pix do período informado foram carregados com sucesso!",
            type: "success"
          }

          commit('showNotification', notification)
        })

      } catch (error) {
        commit('setLoadingSearchPix', { show: false })
        commit("showNotification", defaultErrorNotification(error?.response?.data?.message));
      }
    },

    linkDeposit: ({ state, dispatch, commit }, payload) => {
      let url = endPoints.vip_sales + "/linkpix";
      if (typeof state.selectedItem.value === "string") {
        state.selectedItem.value = parseFloat(state.selectedItem.value.replace(/,/, "."));
      }
      state.selectedItem.pix_id = payload.item.id;

      axios.post(url, state.selectedItem).then(() => {
        let notification = {
          show: true,
          message: "Venda de Vip vinculada com sucesso",
          type: "success"
        }

        dispatch('getItems', { resetPage: true })
        commit('showDialogPixRelateds', { show: false })
        commit('showNotification', notification)

      }).catch((error) => {
        let notification = {
          show: true,
          message: error.response.data.message,
          type: "error"
        }
        commit('showNotification', notification)
      })
    },

    getItems: async ({ commit, state }, payload) => {

      let filter = state.filter
      let url = `${endPoints.vip_sales}`;


      if (payload.resetPage === true) {
        url += "?page=" + 1 + "&" + "itemsPerPage=" + 10;
        commit("setPage", { page: 1, itemsPerPage: 10 });
      } else {
        url +=
          "?page=" +
          state.pagination.page +
          "&" +
          "itemsPerPage=" +
          state.pagination.itemsPerPage;
      }

      Object.keys(filter).forEach(function (field) {
        if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {
          if (field == "end_date" || field == "start_date") {
            var e = new Date(filter[field])
            url += "&" + field + "=" + formatDate(e) + " " + e.toLocaleTimeString()
          } else {
            url += "&" + field + "=" + filter[field]
          }
        }
      })

      if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
        url += "&sort_by=" + state.pagination.sortBy[0]
        url += "&sort_desc=" + state.pagination.sortDesc[0]
      }

      commit("setLoading", true);

      try {
        let items = await axios.get(url);

        commit("setItems", items.data.data);
        commit("setTotalItems", items.data.total);

      } catch (error) {
        commit("showNotification", defaultErrorNotification(error?.response?.data?.message));
      }

      commit("setLoading", false);
    },

    deleteItem: async ({ commit, dispatch, state }) => {

      commit("setLoading", true);

      try {
        await axios.delete(`${endPoints.vip_sales}/${state.selectedItem.id}`);

        commit("setLoading", false);
        commit("setDeleteDialog", false);
        commit("setSelectedItem", {});
        commit("showNotification", defaultSuccessNotification("Venda removida com sucesso"));

        return dispatch('getItems', { resetPage: true })

      } catch (error) {
        commit("showNotification", defaultErrorNotification(error?.response?.data?.message));
        commit("setLoading", false);

      }
    },
    getEndToEnd: async ({ commit, state }, payload) => {
      commit('resetPix')
      state.endToEndId_readed = ""
      var file = payload.receipt

      const toBase64 = file => new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
      });
      let notification = {
        show: true,
        notificationTime: 7000,
        type: "error"
      }
      let base64_file = await toBase64(file)

      var url = endPoints.receipt + "/e2e";
      let data = {
          receipt: base64_file
      }
      try {
          let response = await axios.post(url, data)
          state.endToEndId_readed = response.data.e2e
          if (!response.data.found) {
              notification.message = "Pix não encontrado para esse identificador"
          } else {
              if (!response.data.available) {
                notification.message =  "Identificador já utilizado. Possível Golpe!"
              } else {
                commit('setPix', {pix: response.data})
                notification.message = "Identificador válido"
                notification.type = "success"
              }
          }
          commit('message/showNotification', notification, { root: true })
          return response.data
      } catch (err) {
        notification.message = "Nenhum identificador encontrado. Preencha manualmente",
        commit('message/showNotification', notification, { root: true })
      }
    },
    concludeItem: async ({ commit, state, dispatch }) => {

      let url = `${endPoints.vip_sales}/update/${state.selectedItem.id}`;
      let func = axios.put;

      commit("setLoading", true);

      try {
        state.selectedItem.status = 3
        if (typeof state.selectedItem.value === "string") {
          state.selectedItem.value = parseFloat(state.selectedItem.value.replace(/,/, "."));
        }
        await func(url, state.selectedItem);

        commit("setSelectedItem", {});
        commit("setConcludeDialog", false);
        commit("setLoading", false);
        commit("showNotification", defaultSuccessNotification("Venda concluída com sucesso"));
        return dispatch('getItems', { resetPage: true })

      } catch (error) {
        commit("showNotification", defaultErrorNotification(error?.response?.data?.message));
        commit("setLoading", false);
      }
    },



    save: async ({ commit, state, dispatch, rootState }) => {
      let url = endPoints.vip_sales;
      let func = "";
      state.mode === "ADD"
        ? (func = axios.post)
        : ((func = axios.put), (url += `/update/${state.selectedItem.id}`));

      commit("setLoading", true);
      try {
        state.selectedItem.created_user = rootState.login.userData.id;

        state.selectedItem.date_created =
          state.selectedItem.date_picker.toISOString().substr(0, 10) + " " +
          state.selectedItem.date_picker.toLocaleTimeString();

        state.selectedItem.date =
          state.selectedItem.date_picker.toISOString().substr(0, 10) + " " +
          state.selectedItem.date_picker.toLocaleTimeString();

        if (typeof state.selectedItem.club_id === "string") {
          state.selectedItem.club_id = parseFloat(
            state.selectedItem.club_id.replace(/,/, ".")
          );
        }

        if (typeof state.selectedItem.club_id_withdraw === "string") {
          state.selectedItem.club_id_withdraw = parseFloat(
            state.selectedItem.club_id_withdraw.replace(/,/, ".")
          );
        }

        if (typeof state.selectedItem.player_id === "string") {
          state.selectedItem.player_id = parseFloat(
            state.selectedItem.player_id.replace(/,/, ".")
          );
        }

        if (typeof state.selectedItem.player_id_withdraw === "string") {
          state.selectedItem.player_id_withdraw = parseFloat(
            state.selectedItem.player_id_withdraw.replace(/,/, ".")
          );
        }

        if (state.selectedItem.receipt_file) {
          var file = state.selectedItem.receipt_file;

          const toBase64 = (file) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = (error) => reject(error);
            });

          let base64_file = await toBase64(file);
          state.selectedItem.receipt = base64_file;
        }

        if (typeof state.selectedItem.value === "string") {
          state.selectedItem.value = parseFloat(state.selectedItem.value.replace(/,/, "."));
        }
        console.log(state.selectedItem)
        await func(url, state.selectedItem);

        if (state.screen != "messages") {
          commit("showNotification", defaultSuccessNotification("Venda salva com sucesso"));
          commit("setShowDialog", false);
          commit("setLoading", false);

        }else{
          commit("setShowDialog", false);
          commit('message/showDialogVipSale', { show: false }, { root: true })
          commit('message/showNotification', defaultSuccessNotification("Venda salva com sucesso"), { root: true })
        }
        dispatch('getItems', { resetPage: true })

      } catch (error) {
        commit("showNotification", defaultErrorNotification(error?.response?.data?.message));
        commit("setLoading", false);
      }
    },

    updateSelectedItem: ({ commit, state }) => {
      if (typeof state.selectedItem.value === "string") {
        state.selectedItem.value = parseFloat(state.selectedItem.value.replace(/,/, "."));
      }
      commit("setSelectedItem", state.selectedItem)
    },

    showReceipt: ({ commit }, payload) => {
      let url = endPoints.receipt;

      url += '/' + payload.id + '?type=4'
      commit('setDepositReceipt', { item: payload })
      commit('overlay', { show: true })

      axios.get(url).then(r => {
        let file = r.data.file
        let mimetype = file.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
        mimetype = mimetype.replace("application/", "").replace("image/", "")
        commit('setReceiptMimeType', { receiptMimeType: mimetype })
        commit('setReceipt', { receipt: r.data.file })

        commit('setDialogReceipt', { show: true })
        commit('overlay', { show: false })

      }).catch(() => {
        let notification = {
          show: true,
          message: "Comprovante não encontrado",
          type: "error"
        }
        commit('showNotification', notification)
        commit('overlay', { show: false })
      });
    },

    getRelatedPixes: async ({ commit, state }, payload) => {
      let url = endPoints.pix;

      url += '?page=' + state.paginationPix.page + '&' + 'itemsPerPage=' + 15;
      url += `&value=${payload.deposit.value}&used=0&type=1&status=1&date=${payload.deposit.date_created}`

      try {
        let urlReceipt = endPoints.receipt;
        urlReceipt += '/' + payload.deposit.id + '?type=4'
        let receipt = await axios.get(urlReceipt)

        let file = receipt.data.file
        let mimetype = file.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
        mimetype = mimetype.replace("application/", "").replace("image/", "")

        commit('setReceiptMimeType', { receiptMimeType: mimetype })
        commit('setReceipt', { receipt: receipt.data.file })

      } catch (err) {
        commit('setReceipt', { receipt: null })
      }


      axios.get(url).then(pixes => {
        if (pixes) {
          state.selectedItem = Object.assign({}, payload.deposit)

          commit('setPixRelateds', { pixes: pixes.data.data, total: pixes.data.total })
          commit('showDialogPixRelateds', { show: true })

        } else {
          commit('setPixRelateds', { pixes: [] })
          commit('showDialogPixRelateds', { show: false })
        }
      });
    },
    validateEndToEndId: async ({ commit, state }, payload) => {
      var url = endPoints.pix + "/check-identifier/" + payload;
      let notification = {
        show: true,
        notificationTime: 7000,
        type: "error"
      }
      try {
          let response = await axios.get(url)
          console.log(response.data)
          commit('setPix', {pix: response.data})
          commit('setPixSearched', {searched: true})
          if (!response.data.found) {
              notification.message = "Pix não encontrado para esse identificador"
          } else {
              if (!response.data.available) {
                notification.message =  "Identificador já utilizado. Possível Golpe!"
              } else {
                notification.message = "Identificador válido"
                notification.type = "success"
              }
          }
          if (state.screen == 'messages') {
              commit('message/showNotification', notification, { root: true })
          } else {
              commit('showNotification', notification)
          }
      } catch (err) {
          notification = {
              show: true,
              message: "Erro ao validar Identificador",
              notificationTime: 7000,
              type: "error"

          }
          if (state.screen == 'messages') {
              commit('message/showNotification', notification, { root: true })
          } else {
              commit('showNotification', notification)
          }
          console.log(err)
      }
  },
  },
  getters: {
    getField,
  },
};
