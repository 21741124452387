<template>
    <v-dialog v-model="dialog" width="1100">
        <v-card>
            <v-card-title class="justify-center">Editar Imagem</v-card-title>
            <v-card-text>
                <div ref="imageEditorContainer" class="image-editor"></div>
            </v-card-text>
            <v-card-actions>
                <v-col>

                    <v-btn color="red" @click="closeEditor">Cancelar</v-btn>
                </v-col>
                <v-col class="text-right">
                    <v-btn color="primary" @click="saveImage">Salvar</v-btn>
                </v-col>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import ImageEditor from 'tui-image-editor';
import 'tui-image-editor/dist/tui-image-editor.css';
import { mapMutations } from 'vuex/dist/vuex.common.js';

export default {
    props: {
        imgsrc: String,
        visible: Boolean,
    },
    created() {
        console.log('mounted', this.visible)
        this.initEditor();
    },
    data() {
        return {
            dialog: this.visible,
            editorInstance: null,
        };
    },
    watch: {
        visible(newVal) {
            console.log("new val", newVal);
            this.dialog = newVal;
            if (newVal) {
                console.log("visible", this.imgsrc);
                this.initEditor();
            }
        },
    },
    methods: {
        ...mapMutations('chat', ['setImageBase64MessageToSend']),
        initEditor() {
            console.log("init editor", this.imgsrc);
            if (this.editorInstance) {
                this.editorInstance.destroy();
            }

            this.$nextTick(() => {
                console.log("aqui")
                this.editorInstance = new ImageEditor(this.$refs.imageEditorContainer, {
                    includeUI: {
                        loadImage: {
                            path: this.imgsrc,
                            name: 'SampleImage',
                        },
                        theme: {},
                        menu: ['crop', 'draw', 'text', 'rotate'],
                        initMenu: '',
                        uiSize: {
                            width: '100%',
                            height: '700px',
                        },
                        menuBarPosition: 'bottom',
                    },

                    cssMaxWidth: 900,
                    cssMaxHeight: 800,

                });
            });
        },
        saveImage() {
            const editedImage = this.editorInstance.toDataURL();
            console.log("edited image", editedImage);
            // this.$emit('image-edited', editedImage);
            this.setImageBase64MessageToSend(editedImage);
            this.closeEditor();
        },
        closeEditor() {
            this.$emit('update:visible', false);
        },
    },
};
</script>

<style>
.image-editor {
    width: 100%;
    height: 500px;
}
/* .tui-image-editor-header-buttons {
    display: none !important;
} */
.tui-image-editor-header-logo {
    display: none !important;
}

</style>