
<template>
  <v-card class="pa-2">
    <v-card-title class="justify-center">Editar Mensagem </v-card-title>
    <v-card-text>
      <chat-image v-if="true" :imgsrc="messageToEdit.image_base64" :thumbsrc="messageToEdit.image_base64"
        :imgWidth="500"></chat-image>
        <v-row class="mx-auto ma-5"><automatic-messages-chip :clickInName="setTextEdit" :origin="'edit_message'"></automatic-messages-chip></v-row>
        <v-row>

          <v-col class="d-flex" cols="12" sm="12">
            <v-menu :close-on-content-click="false" offset-y top v-model="showEmojiMenu" :position-x="x" :position-y="y">
              <v-list>
                <v-list-item>
                  <Picker :data="emojiIndex" @select="selectEmoji" :i18n="i18n"></Picker>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-textarea prepend-inner-icon="mdi-emoticon-wink-outline" @click:prepend-inner="showEmoji" auto-grow
            label="Mensagem" background-color="white" outlined id="textareamessage" hide-details="true" rows=1
            v-model="textEdit" :value="textEdit">
          </v-textarea>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-col>
        <v-btn text @click="showDialogEditMessage({ show: false, message: '' })">Cancelar</v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn text type="submit" right @click="editMessage()">Editar</v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import Image from "./Image.vue";
import emojiData from "emoji-mart-vue-fast/data/all.json";
import { Picker, EmojiIndex } from "emoji-mart-vue-fast";
// Import default CSS
import "emoji-mart-vue-fast/css/emoji-mart.css";
import AutomaticMessagesChip from './AutomaticMessagesChip.vue';
import { mapActions } from 'vuex/dist/vuex.common.js';
let emojiIndex = new EmojiIndex(emojiData);
export default {
  props: [
    'contacts',
    'editMessage',
    'showDialog',
    'showDialogEditMessage',
    'image'
  ],
  components: {
    "chat-image": Image,
    Picker,
    "automatic-messages-chip": AutomaticMessagesChip,
  },
  computed: {
    ...mapFields('message', [
      'instance_id',
      'textEdit',
    ]),
    ...mapGetters("instances", { instances: "instancesChat" }),
    ...mapState('message', ['forward_mode', 'messageToEdit']),
  },
  data: () => ({
    selected_contacts: [],
    searchInput: '',
    emojiIndex: emojiIndex,
    showEmojiMenu: false,
    x: 0,
    y: 0,
  }),
  methods: {
    ...mapActions('message', ['setTextEdit']),
    showEmoji(e) {
      this.showEmojiMenu = false
      this.x = e.clientX
      this.y = e.clientY - 20
      this.$nextTick(() => {
        this.showEmojiMenu = true
      })
    },
    selectEmoji(emoji) {
      if (emoji && emoji.native) {
        this.textEdit += emoji.native
      }
      this.showEmojiMenu = false
    },
    customFilter(item) {
      return item
    },
    getText(item) {
      if (item.name) {
        if (item.name.length == 1) {
          return item.name
        }

        var text = ""
        item.name.forEach((name) => {
          text += name + " | "
        })
        return text
      }
      else {
        return text
      }
    },
  },
  watch: {
    searchInput(val) {
      var payload = {
        search: val,
        contacts: this.selected_contacts
      }
      this.getContacts(payload)
    },
  },
}
</script>

<style>
.contato-autocomplete {
  margin: 10px !important;
}
</style>