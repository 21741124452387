<template>
    <div>
        <div @drop.prevent="addDropFile" @dragover.prevent @paste="onPaste">
            <v-card>
                <v-card-title>
                    <span class="headline">Saques</span>
                    <v-expansion-panels v-if="screen == 'messages'">
                        <v-expansion-panel>
                            <v-expansion-panel-header class="message-with-header">Mensagem De: {{ contact }} |
                                {{ instance_name }}</v-expansion-panel-header>
                            <v-expansion-panel-content class="message-with-content">
                                <div class="message-withdrawal" v-html="messageTreated"></div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <v-spacer></v-spacer>
                    <v-progress-circular v-if="loading" indeterminate class="text-right" color="primary"
                        size="24"></v-progress-circular>
                </v-card-title>

                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12 sm6 md6>
                                    <v-select clear-icon label="Tipo *" :items="possible_types.filter(
                                        (type) =>
                                            (type != 2 && type != 6) ||
                                            this.phone,
                                    )
                                        " v-model="type" :readonly="this.mode != 'ADD'" item-text="description"
                                        item-value="id" outlined :rules="[rules.required]" @change="typeChange">
                                    </v-select>
                                </v-flex>
                                <v-flex xs12 sm6 md6>
                                    <v-text-field required label="CPF/CNPJ *" v-model="inscription"
                                        :readonly="this.mode != 'ADD'" :rules="[rules.required, rules.cpfcnpj]"
                                        outlined></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md12>
                                    <v-text-field required label="Nome *" v-model="name" :readonly="this.mode != 'ADD'"
                                        :rules="[
                                            rules.required,
                                            rules.max_length(this.name, 255),
                                        ]" outlined></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 md6 v-if="this.mode == 'FINISH'">
                                    <v-select label="Banco Origem*" :items="banks" v-model="bank_payment"
                                        :readonly="this.mode != 'FINISH'" :error-messages="errors.collect('bank_payment')
                                            " v-validate="'required:Banco de Origem,bank_payment'
                                                " :item-text="getTextBank" item-value="id" data-vv-name="bank_payment"
                                        outlined>
                                    </v-select>
                                </v-flex>
                                <v-flex xs12 sm6 md6 v-if="type == 0 || type == 3 || type == 6 || type == 7">
                                    <v-autocomplete :label="getPlayerLabel('Plataforma')" :items="platforms"
                                        v-model="platform_name" :readonly="this.mode != 'ADD'" item-text="description"
                                        item-value="description" outlined :rules="[rules.required]"
                                        @input="updatePlatformName">
                                    </v-autocomplete>
                                </v-flex>
                                <!--Exibe se o tipo for fichas-->
                                <v-flex xs12 sm6 md6 v-if="type == 0 || type == 3 || type == 6 || type == 7">
                                    <v-autocomplete :label="getPlayerLabel('Clube')" v-model="idClub" :items="this.clubsApp.filter(
                                        (club) =>
                                            club.platform ==
                                            platform_name_data || club.platform == platform_name,
                                    )
                                        " :readonly="this.mode != 'ADD' ||
                                            !platform_name_data
                                            " item-text="clubLabel" item-value="id" outlined :rules="[rules.required]">
                                    </v-autocomplete>
                                </v-flex>
                                <v-flex xs12 sm6 md6 v-if="type == 0 || type == 3 || type == 6 || type == 7">
                                    <v-text-field required :label="getPlayerLabel('ID App')" v-model="idApp"
                                        :readonly="this.mode != 'ADD'" :rules="[rules.required]" data-vv-name="idApp"
                                        outlined></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 md6>
                                    <v-text-field label="Valor *" v-model="value" :readonly="this.mode != 'ADD'"
                                        :error-messages="errors.collect('value')
                                            " :rules="[
                                                rules.required,
                                                rules.decimal(2),
                                                rules.minValue(this.value, 0),
                                            ]" outlined></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 md6 v-if="this.typeWithdrawalShowPix(type)">
                                    <v-text-field label="Chave Pix *" v-model="pixKey" :readonly="this.mode != 'ADD'"
                                        :rules="this.typeWithdrawalShowPix(type)
                                            ? [
                                                rules.required,
                                                rules.max_length(
                                                    this.pixKey,
                                                    255,
                                                ),
                                            ]
                                            : []
                                            " outlined></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 md6 v-if="type == 3">
                                    <v-autocomplete label="Plataforma (Envio) *" :items="platforms"
                                        v-model="platformSend" :readonly="this.mode != 'ADD'" item-text="description"
                                        item-value="description" outlined :rules="[rules.required]">
                                    </v-autocomplete>
                                </v-flex>
                                <v-flex v-if="type == 3" xs12 sm6 md6>
                                    <v-autocomplete label="Clube (Envio) *" :items="clubsApp.filter(
                                        (club) =>
                                            club.platform ==
                                            platformSend,
                                    )
                                        " v-model="transferIdClub" :readonly="this.mode != 'ADD'" item-text="clubLabel"
                                        item-value="id" outlined></v-autocomplete>
                                </v-flex>
                                <v-flex v-if="type == 3" xs12 sm6 md6>
                                    <v-text-field :required="type == 3" label="ID App (Envio) *" v-model="transferIdApp"
                                        :readonly="this.mode != 'ADD'" :rules="[rules.required]"
                                        outlined></v-text-field>
                                </v-flex>

                                <v-flex xs12 sm4 md4 v-if="this.mode != 'ADD'">
                                    <v-select clear-icon label="Status *" :items="possible_status" v-model="status"
                                        :readonly="true" :error-messages="errors.collect('status')
                                            " :v-validate="'required:Status,status'" item-text="description"
                                        item-value="status" data-vv-name="status" outlined>
                                    </v-select>
                                </v-flex>
                                
                                <v-flex v-if="editedItem.status == 5" xs12 sm12 md12>
                                    <v-select required label="Agendado Por" :readonly="true" outlined
                                        v-model="editedItem.scheduledBy" :items="users" item-text="name"
                                        item-value="id"></v-select>
                                </v-flex>
                                <v-flex xs12 sm12 md12 v-if="editedItem.status == 5">
                                    <v-autocomplete label="Motivo do Agendamento" outlined v-model="editedItem.scheduleReason"
                                        :items="scheduleReasons" item-text="description" item-value="id"
                                        :rules="[rules.requiredAllowZero]" readonly>
                                    </v-autocomplete>
                                </v-flex>
                                <v-flex xs12 sm12 md12>
                                    <v-text-field :label="type == 5
                                        ? 'Comentários ( Usuário ) *'
                                        : 'Comentários ( Usuário )'
                                        " v-model="comments" :readonly="this.mode == 'VIEW'" outlined :rules="type == 5
                                            ? [
                                                rules.required,
                                                rules.max_length(
                                                    this.comments,
                                                    255,
                                                ),
                                            ]
                                            : []
                                            "></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md12>
                                    <v-text-field label="Observações ( Sistema )" v-model="observation"
                                        :readonly="this.mode == 'VIEW'" :error-messages="errors.collect('observation')
                                            " data-vv-name="observation" outlined disabled></v-text-field>
                                </v-flex>
                                <v-expansion-panels v-if="
                                    mode == 'VIEW' && screen == 'withdrawal'
                                ">
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>Auditoria</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row>
                                                <v-flex xs12 sm4 md4>
                                                    <v-text-field required label="Criado em" :readonly="true" outlined
                                                        :value="getDate(
                                                            this.createdAt,
                                                        )
                                                            "></v-text-field>
                                                </v-flex>
                                                <v-flex v-if="payedAt" xs12 sm4 md4>
                                                    <v-text-field required label="Pago em" :readonly="true" outlined
                                                        :value="getDate(
                                                            this.payedAt,
                                                        )
                                                            "></v-text-field>
                                                </v-flex>
                                                <v-flex v-if="finishAt" xs12 sm4 md4>
                                                    <v-text-field required label="Finalizado em" :readonly="true"
                                                        outlined :value="getDate(
                                                            this.finishAt,
                                                        )
                                                            "></v-text-field>
                                                </v-flex>
                                                <v-flex v-if="refusedAt" xs12 sm4 md4>
                                                    <v-text-field required label="Recusado em" :readonly="true" outlined
                                                        :value="getDate(
                                                            this.refusedAt,
                                                        )
                                                            "></v-text-field>
                                                </v-flex>
                                            </v-row>
                                            <v-row>
                                                <v-flex v-if="createdBy" xs12 sm4 md4>
                                                    <v-select required label="Criado Por" :readonly="true" outlined
                                                        v-model="createdBy" :items="users" item-text="name"
                                                        item-value="id"></v-select>
                                                </v-flex>
                                                <v-flex v-if="payedUser" xs12 sm4 md4>
                                                    <v-select required label="Pago Por" :readonly="true" outlined
                                                        v-model="payedUser" :items="users" item-text="name"
                                                        item-value="id"></v-select>
                                                </v-flex>
                                                <v-flex v-if="finishUser" xs12 sm4 md4>
                                                    <v-select required label="Finalizado Por" :readonly="true" outlined
                                                        v-model="finishUser" :items="users" item-text="name"
                                                        item-value="id"></v-select>
                                                </v-flex>
                                                <v-flex v-if="refusedBy" xs12 sm4 md4>
                                                    <v-select required label="Recusado Por" :readonly="true" outlined
                                                        v-model="refusedBy" :items="users" item-text="name"
                                                        item-value="id"></v-select>
                                                </v-flex>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                                <v-expansion-panels v-if="
                                    mode == 'VIEW' && screen == 'withdrawal'
                                ">
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>Pix
                                            Relacionados</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-data-table :headers="headersPix" :loading="loadingPix"
                                                :items="withdrawalPix"
                                                class="elevation-6 ma-2 table-margin px-6 pt-3 row-pointer"
                                                no-data-text="Nenhum pix encontrado" :footer-props="{
                                                    'items-per-page-options': [
                                                        10, 20, 50, 100,
                                                    ],
                                                    'items-per-page-text':
                                                        'Itens por página',
                                                }">
                                                <template v-slot:[`item.date`]="{
                                                    item,
                                                }">
                                                    <span>{{
                                                        getDate(item.date)
                                                        }}</span>
                                                </template>
                                                <template v-slot:[`item.value`]="{
                                                    item,
                                                }">
                                                    <span>{{
                                                        item.value.toLocaleString(
                                                            'pt-BR',
                                                            {
                                                                style: 'currency',
                                                                currency: 'BRL',
                                                            },
                                                        )
                                                    }}</span>
                                                </template>
                                                <template v-slot:[`item.type`]="{
                                                    item,
                                                }">
                                                    <span>{{
                                                        item.type == 1
                                                            ? 'Entrada'
                                                            : 'Saída'
                                                    }}</span>
                                                </template>
                                                <template v-slot:[`item.status`]="{
                                                    item,
                                                }">
                                                    <v-chip text-color="white" :color="item.status == 1
                                                        ? 'black'
                                                        : item.status ==
                                                            2
                                                            ? 'green'
                                                            : 'red'
                                                        ">
                                                        <span>{{
                                                            getStatusPix(
                                                                item.status,
                                                            )
                                                        }}</span>
                                                    </v-chip>
                                                </template>
                                            </v-data-table>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                                <v-expansion-panels>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>Dados do Cliente</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row>

                                                <v-flex v-if="clientName" xs12 sm4 md4>
                                                    <v-text-field required label="Nome Cliente" :readonly="true"
                                                        outlined v-model="clientName"></v-text-field>
                                                </v-flex>

                                                <v-flex v-if="dealType" xs12 sm4 md4>
                                                    <v-text-field required label="Tipo de Fechamento" :readonly="true"
                                                        outlined v-model="dealType"></v-text-field>
                                                </v-flex>


                                            </v-row>

                                            <v-row>
                                                <v-flex v-if="agentPlayerId" xs12 sm4 md4>
                                                    <v-text-field required label="ID Agente" :readonly="true" outlined
                                                        v-model="agentPlayerId"></v-text-field>
                                                </v-flex>
                                                <v-flex v-if="agentName" xs12 sm4 md4>
                                                    <v-text-field required label="Nome Agente" :readonly="true" outlined
                                                        v-model="agentName"></v-text-field>
                                                </v-flex>


                                            </v-row>

                                            <v-row>

                                                <v-flex v-if="contactId" xs12 sm4 md4>
                                                    <v-text-field required label="Contato ID" :readonly="true" outlined
                                                        v-model="contactId"></v-text-field>
                                                </v-flex>
                                                <v-flex v-if="contactName" xs12 sm4 md4>
                                                    <v-text-field required label="Grupo" :readonly="true" outlined
                                                        v-model="contactName"></v-text-field>
                                                </v-flex>


                                            </v-row>
                                        </v-expansion-panel-content>

                                    </v-expansion-panel>
                                </v-expansion-panels>
                                <v-flex xs12 sm12 md12>
                                    <v-file-input v-model="receipt_file" v-if="this.mode == 'FINISH'"
                                        label="Comprovante" outlined dense></v-file-input>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-col>
                        <v-btn text @click="closeDialog">{{
                            mode == 'VIEW' ? 'Voltar' : 'Cancelar'
                            }}</v-btn>
                    </v-col>
                    <v-col class="">
                        <v-btn text type="submit" right v-if="showSave() && errorPositiveBalance"
                            @click="saveWithdrawal(true)">Forçar
                            Saldo Positivo</v-btn>
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text type="submit" right v-if="showSave()" @click="saveWithdrawal(false)">Salvar</v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import validCpfCnpj from '../../../util/CpfCnpj'

export default {
    name: 'Withdrawls',
    props: ['screen'],
    computed: {
        ...mapGetters('login', ['getInstanceId']),
        ...mapState('bank', { banks: 'items' }),
        ...mapState('clubs', { clubsApp: 'clubs', platforms: 'platforms' }),
        ...mapState('slots', ['slots']),
        ...mapState('withdrawal', [
            'message',
            'contact',
            'item',
            'instance_name',
            'loading',
            'mode',
            'loadingPix',
            'withdrawalPix',
            'editedItem',
        ]),
        ...mapState('users', ['users']),
        ...mapFields('withdrawal', [
            'editedItem.id',
            'editedItem.scheduledBy',
            'editedItem.scheduleReason',
            'editedItem.name',
            'editedItem.bank',
            'editedItem.idClub',
            'editedItem.comments',
            'editedItem.createdAt',
            'editedItem.createdBy',
            'editedItem.finishAt',
            'editedItem.finishUser',
            'editedItem.payedUser',
            'editedItem.refusedBy',
            'editedItem.bank_payment',
            'editedItem.platform_name',
            'editedItem.createdUserName',
            'editedItem.finishUserName',
            'editedItem.inscription',
            'editedItem.idApp',
            'editedItem.transferIdApp',
            'editedItem.transferIdClub',
            'editedItem.observation',
            'editedItem.payedAt',
            'editedItem.phone',
            'editedItem.value',
            'editedItem.status',
            'editedItem.sended',
            'editedItem.type',
            'editedItem.pixKey',
            'editedItem.refusedAt',
            'editedItem.refusedByName',
            'editedItem.forcePositiveBalance',
            'detailClientWithdarawalSox.agentName',
            'detailClientWithdarawalSox.agentPlayerId',
            'detailClientWithdarawalSox.agentsId',
            'detailClientWithdarawalSox.clientName',
            'detailClientWithdarawalSox.dealType',
            'detailClientWithdarawalSox.contactId',
            'contactName',
            'selected_bank',
            'errorPositiveBalance',
            // ...
        ]),
        messageTreated: function () {
            if (this.message) {
                var messageTreated = this.message
                    .replace(/&/g, '&amp;')
                    .replace(/</g, '&lt;')
                    .replace(/>/g, '&gt;')
                    .replace(/"/g, '&quot;')
                    .replace(/'/g, '&#039;')
                    .replace(/\n/g, '<br>')
                    .replace(/\*([^]+?)\*/g, '<b>$1</b>')
                return messageTreated
            } else {
                return ''
            }
        },
        clubsByPlatform() {
            if (this.platform) {
                return this.clubsApp.filter(
                    (club) => club.platform == this.platform,
                )
            }
            return []
        },
    },

    created() {
        this.$validator.extend('cpfcnpj', {
            getMessage: () => 'CPF/CNPJ inválido.',
            validate: (value) => {
                return validCpfCnpj(value)
            },
        })
        this.$validator.extend('required', {
            getMessage: (field, [label]) => label + ' deve ser informado',
            validate: (_, [, field]) => {
                if (
                    (this[field] || this[field] === 0) &&
                    this[field] !== '' &&
                    this[field] !== null
                ) {
                    return true
                } else {
                    return false
                }
            },
        })
        this.$validator.extend('max_length', {
            getMessage: (_, [length]) => 'No máximo ' + length + ' caracteres.',
            validate: (val, [length]) => {
                if (val.length > length) {
                    return false
                } else {
                    return true
                }
            },
        })
        this.getSlots({
            resetPage: false,
            noPages: true,
            onlyIfNotSearched: true,
        })
    },
    methods: {
        ...mapActions('withdrawal', ['closeDialog', 'save', 'showReceipt']),
        ...mapActions('slots', ['getSlots']),
        addDropFile(e) {
            if (this.mode == 'FINISH') {
                if (e.dataTransfer) {
                    if (e.dataTransfer.files[0]) {
                        this.receipt_file = e.dataTransfer.files[0]
                    }
                }
            }
        },
        updatePlatformName(newPlatformName) {
            this.platform_name_data = newPlatformName
        },
        getAlertMessage() {
            if (typeof this.bank == 'object') {
                return this.bank.observation
            }
            return ''
        },
        getAccountType(type) {
            switch (type) {
                case 0:
                    return 'Corrente'
                case 1:
                    return 'Poupança'
                case 2:
                    return 'Pix'
            }
        },
        getDate(date) {
            if (date && date != '') {
                var f_date =
                    date.substr(0, 3) + date.substr(3, 3) + date.substr(6)
                var d = new Date(f_date)
                return d.toLocaleDateString() + ' ' + d.toLocaleTimeString()
            }
            return ''
        },
        getStatus(type) {
            switch (type) {
                case 0:
                    return 'Pendente'
                case 1:
                    return 'Cancelado'
                case 2:
                    return 'Concluido'
            }
        },
        getTextBank(item) {
            if (item.account) {
                return item.name + ' | ' + item.account
            } else {
                return item.name
            }
        },
        getTextSlot(item) {
            let platform = ''
            if (item.plataform_id == 0) {
                platform = 'Suprema'
            } else if (item.plataform_id == 1) {
                platform = 'Pppoker'
            } else if (item.plataform_id == 2) {
                platform = 'Cacheta'
            }
            return platform + ' | ' + item.name
        },
        saveWithdrawal(forcePositiveBalance) {
            let valid = this.$refs.form.validate()
            if (valid) {
                event.target.disabled = true
                this.value = parseFloat(this.value)
                this.forcePositiveBalance = forcePositiveBalance
                this.save().then(() => {
                    event.target.disabled = false
                })
            }
        },
        onPaste(e) {
            if (
                this.mode == 'FINISH' &&
                e.clipboardData &&
                e.clipboardData.items.length != 0
            ) {
                var file = e.clipboardData.items[0]
                var file_data = file.getAsFile()
                this.receipt_file = file_data
            }
        },
        showSave() {
            if (this.mode == 'EDIT' || this.mode == 'ADD') {
                return true
            }
            return false
        },
        getPlayerLabel(label) {
            let newLabel = label
            if (this.type === 3) newLabel += ' (Retirada)'

            newLabel += ' *'
            return newLabel
        },
        wrapperHeight() {
            if (!this.mounted) return
            const height =
                document.getElementById('photoWrapper').offsetHeight - 120
            return height
        },
        getStatusPix(status) {
            let possible_status = ['Pendente', 'Concluido', 'Erro']
            return possible_status[status - 1]
        },
        typeWithdrawalShowPix(type) {
            let typesPix = [0, 2, 5]
            if (typesPix.includes(type)) {
                return true
            }
        },
        typeChange() {
            if (this.type == 2 || this.type == 5) {
                this.idApp = null
                this.idClub = null
                this.plataform = null
                this.platformSend = null
                this.transferIdClub = null
                this.transferIdApp = null
            } else if (this.type == 3) {
                this.pixKey = null
            }
        },
    },
    data: () => ({
        account_types: [
            { account_type: 0, description: 'Corrente' },
            { account_type: 1, description: 'Poupança' },
            { account_type: 2, description: 'Pix' },
        ],
        headersPix: [
            { text: 'CPF/CPNJ', value: 'clientKey', sortable: true },
            {
                text: 'Data',
                align: 'left',
                value: 'date',
                sortable: true,
            },
            { text: 'Valor', value: 'value', sortable: true },
            { text: 'endToEndId', value: 'endToEndId', sortable: false },
            { text: 'Status', value: 'status', sortable: false },
        ],
        platform_name_data: null,
        possible_status: [
            { status: 0, description: 'Pendente' },
            { status: 4, description: 'Aguardando Pagamento' },
            { status: 1, description: 'Cancelado' },
            { status: 2, description: 'Concluido' },
            { status: 3, description: 'Estornado' },
            { status: 5, description: 'Agendado/Erro' },
            { status: 6, description: 'Em Processamento' },
            { status: 7, description: 'Recusado' },
        ],
        possible_types: [
            { id: 0, description: 'Fichas' },
            { id: 2, description: 'Saldo Positivo' },
            { id: 3, description: 'Transferência de Fichas' },
            { id: 5, description: 'Devolução' },
            { id: 6, description: 'Abate de Anotados' },
            { id: 7, description: 'Retirada Ganhos e Perdas' },
        ],
        scheduleReasons: [
            {
                id: 0, 
                description: "Saldo insuficiente"
            },
            {
                id: 1,
                description: "Problemas com a chave pix"
            },
            {
                id: 2,
                description: "Aguardando prints"
            },
            {
                id: 3, 
                description: "Problema com o contato"
            },
            {
                id: 4, 
                description: "Aguardando aprovação/liberação interna SX"
            },
            {
                id: 5, 
                description: "Agurdando retorno do banco"
            },
            {
                id: 6, 
                description: "Erros Técnicos e Revisões"
            },
            {
                id: 7, 
                description: "Cancelamento por solicitação do jogador"
            },
            {
                id: 8, 
                description: "Duplicidade de solicitação"
            },
            {
                id: 9, 
                description: "Aguardando liberação do cliente"
            },
            {
                id: 10, 
                description: "Análise da Supervisão/Coordenação"
            },
            {
                id: 11,
                description: "Outros ( Especificar )"
            }
        ],
        platformSend: null,
        rules: {
            required: (value) => !!value || value == 0 || 'Campo Obrigatório.',
            counter: (value) => value.length <= 20 || 'Max 20 characters',
            minValue: (value, minValue) =>
                value > minValue || `Valor deve ser maior que ${minValue}`,
            max_length: (value, maxLength) => {
                if (value) {
                    return value.length <= maxLength || 'Max 255 characters'
                }
                return true
            },
            decimal: (value) => {
                if (value) {
                    return (
                        value.toString().match(/^[0-9]+(\.[0-9]{1,2})?$/) ||
                        'Valor inválido'
                    )
                }
                return true
            },
        },
        valid: false,
    }),

}
</script>
