<template>
    <div @drop.prevent="addDropFile" @dragover.prevent>
      <v-card>
        <v-card-title>
          <span class="headline">Venda VIP</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="formVipSales" lazy-validation>
            <v-container grid-list-md>
              <v-row>
                <v-col cols="12" lg="6" md="6" sm="12" xs="12">
                    <v-select
                      label="Tipo de Pagamento *"
                      required
                      :items="payment_types"
                      v-model="payment_type"
                      :readonly="this.mode == 'VIEW'"
                      item-text="name"
                      item-value="id"
                      outlined
                      :rules="[rules.requiredAllowZero]"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" lg="6" md="6" sm="12" xs="12">
                    <v-select
                      label="Tipo *"
                      required
                      :items="possible_types"
                      v-model="type"
                      :readonly="this.mode == 'VIEW'"
                      :item-text="getTextType"
                      item-value="id"
                      @change="setValue"
                      :rules="[rules.requiredAllowZero]"
                      outlined
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" lg="6" md="6" sm="12" xs="12">
                    <v-text-field 
                      required 
                      label="Valor" 
                      v-model="value"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6" md="6" sm="12" xs="12">
                    <v-select
                      label="Status *"
                      required
                      disabled
                      :items="possible_status"
                      v-model="status"
                      :readonly="this.mode == 'VIEW'"
                      :rules="[rules.requiredAllowZero]"
                      :item-text="getTextStatus"
                      item-value="id"
                      outlined
                    >
                    </v-select>
                    <span v-if="observation != null"> {{observation}} </span>
                  </v-col>
                </v-row>
                <v-card-title>
                  Dados retirada
                </v-card-title>
                <v-divider class="mb-6"></v-divider>
                <v-row v-if="payment_type == 0">
                  <v-col cols="12" lg="6" md="6" sm="12" xs="12">
                    <v-autocomplete
                      label="Clube Retirada*"
                      :items="slots"
                      v-model="club_id_withdraw"
                      :readonly="this.mode == 'VIEW'"
                      :rules="[rules.required]"
                      :item-text="getTextSlot"
                      item-value="id"
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" lg="6" md="6" sm="12" xs="12">
                    <v-text-field
                      required
                      label="ID do Jogador Retirada *"
                      v-model="player_id_withdraw"
                      :readonly="this.mode == 'VIEW'"
                      :rules="[rules.required]"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>  
                <v-row v-if="payment_type == 1">
                  <v-col cols="12" lg="12" md="12" sm="12" xs="12" v-if="this.mode == 'ADD' && endToEndId_readed">
                    <v-text-field
                        label="Identificador Lido"
                        v-model="endToEndId_readed"
                        :readonly="true"
                        outlined 
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="12" md="12" sm="12" xs="12">
                    <v-text-field
                        label="Identificador"
                        v-model="endToEndId"
                        :disabled="loadingE2E"
                        counter="32"
                        :counter-value="(value) => value ? value.replaceAll('-', '').length + ' / 32' : '0/32'"
                        :rules="rulesIdentifier"
                        :readonly="this.mode == 'VIEW'"
                        outlined
                        :append-icon="this.mode == 'VIEW' || !endToEndId_readed ? '' : 'mdi-content-copy'"
                        @click:append="() => {endToEndId = endToEndId_readed}"
                        @input="handleChangeEndToEndId"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="12" md="12" sm="12" xs="12">
                    <v-file-input
                      v-validate="'required: ' + payment_type == 1"
                      :disabled="payment_type !== 1"
                      class="mt-2"
                      type="file"
                      v-model="receipt_file"
                      v-if="this.mode == 'ADD'"
                      label="Comprovante"
                      :rules="payment_type == 1 ? rules.required : []"
                      outlined
                    >
                    </v-file-input>
                  </v-col>
                </v-row>
                <v-row v-if="payment_type == 2">
                  <v-col cols="12" lg="12" md="12" sm="12" xs="12">
                    <v-text-field
                        label="Grupo"
                        :readonly="true"
                        v-model="contact_name"
                        outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-card-title>
                  Dados envio Vip
                </v-card-title>
                <v-divider class="mb-6"></v-divider>
                <v-row>
                  <v-col cols="12" lg="6" md="6" sm="12" xs="12">
                    <v-autocomplete
                      label="Clube *"
                      :items="slots"
                      v-model="club_id"
                      :readonly="this.mode == 'VIEW'"
                      :rules="[rules.required]"
                      :item-text="getTextSlot"
                      item-value="id"
                      outlined
                      :append-icon="payment_type == 0 ? 'mdi-content-copy' : 'mdi-menu-down'"
                      @click:append="payment_type == 0 ? club_id = club_id_withdraw : () => {}"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" lg="6" md="6" sm="12" xs="12">
                    <v-text-field
                      required
                      label="ID do Jogador *"
                      v-model="player_id"
                      :readonly="this.mode == 'VIEW'"
                      :rules="[rules.required]"
                      outlined
                      :append-icon="payment_type == 0 ? 'mdi-content-copy' : ''"
                      @click:append="payment_type == 0 ? player_id = player_id_withdraw : () => {}"
                    ></v-text-field>
                  </v-col>
                </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-col>
            <v-btn
              text
              @click="closeDialog"
              >{{ mode == "VIEW" ? "Voltar" : "Cancelar" }}</v-btn
            >
          </v-col>
          <v-col class="text-right">
            <v-btn
              text
              type="submit"
              right
              v-if="mode == 'EDIT' || mode == 'ADD'"
              @click="saveRelease"
              >Salvar</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import rules from '../../../util/rules';
import toBase64 from '@util/Base64'

export default {
    name: 'VipSalesForm',
    computed: {
        ...mapState('vip_sales', [
            'mode',
            'selectedItem',
            'screen',
            'showDialog',
            'pix',
            'pix_searched'
        ]),
        ...mapState('slots', ['slots']),
        ...mapState('bank', { banks: 'items' }),
        ...mapFields('vip_sales', [
            'selectedItem.id',
            'selectedItem.player_id',
            'selectedItem.club_id',
            'selectedItem.date_picker',
            'selectedItem.type',
            'selectedItem.value',
            'selectedItem.bank_id',
            'selectedItem.payment_type',
            'selectedItem.status',
            'selectedItem.observation',
            'selectedItem.receipt',
            'selectedItem.receipt_file',
            'selectedItem.player_id_withdraw',
            'selectedItem.club_id_withdraw',
            'selectedItem.contact_name',
            'selectedItem.endToEndId',
            'endToEndId_readed',
        ]),
        fileBase64: function () {
            if (this.fileString) {
                return this.fileString
            }
            return ''
        },
        possible_payment_types: function () {
            if (this.selectedItem.payment_type == 2) {
                return [
                    {
                        id: 2,
                        name: 'Anotado',
                    },
                ]
            }
            return this.payment_types
        },
        rulesIdentifier() {
          return this.payment_type == 1 ? [
            rules.required, 
            rules.endToEndId, 
            !this.loadingE2E || 'Buscando Identificador ...', 
            this.pix_searched ? (this.pix.found || 'Identificador não encontrado') : true,
            this.pix_searched ? (this.pix.available || 'Identificador já utilizado. Possível Golpe!') : true
          ] : []
        }
    },
    created() {
        this.getSlots({ resetPage: false, noPages: true })
        this.setValue(this.selectedItem.type)
    },

    beforeUpdate() {
        this.setValue(this.selectedItem.type)
    },
    methods: {
        ...mapActions('slots', ['getSlots']),

        ...mapActions('vip_sales', ['updateSelectedItem', 'closeDialog', 'validateEndToEndId']),
        ...mapMutations('vip_sales', ['setShowDialog', 'setSelectedItem', 'resetPix']),
        ...mapActions('vip_sales', ['save']),
        async validateE2E(value) {
            try {
                this.loadingE2E = true
                await this.validateEndToEndId(value)
            } catch (error) {
                console.error('Erro ao validar identificador:', error)
            } finally {
                this.loadingE2E = false
            }
        },
        addDropFile(e) {
            if (this.mode == 'ADD' || this.mode == 'EDIT') {
                if (e.dataTransfer) {
                    if (e.dataTransfer.files[0]) {
                        this.receipt_file = e.dataTransfer.files[0]
                    }
                }
            }
        },
        saveRelease() {
          let valid = this.$refs.formVipSales.validate();
          console.log("teste: ", valid);
          if (valid) {
              var d = new Date(this.date_picker)
              this.date =
                  d.toISOString().substr(0, 10) +
                  ' ' +
                  d.toLocaleTimeString()
              this.save()
          }
        },
        handleChangeEndToEndId(value) {
          this.resetPix()
            if (
                (value.startsWith('E') && value.length === 32) ||
                /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/i.test(
                    value,
                )
            ) {
                this.loadingE2E = true
                this.validateE2E(value)
            } else {
                this.loadingE2E = false
                console.warn('Formato inválido.')
            }
        },
        setValue(itemId) {
            const a = this.possible_types.find((type) => type.id == itemId)
            this.selectedItem.value = a.price.toString().replace('.', ',')
        },
        getTextBank(item) {
            if (item.account) {
                return item.name + ' | ' + item.account
            } else {
                return item.name
            }
        },
        getTextSlot(item) {
            return item.name
        },
        getTextType(item) {
            // this.selectedItem.value = item.price.replace(".", ",");
            // this.onInputChange(item.price.toString().replace(".", ","));
            item.value = item.price
            return item.name
        },
        getTextPaymentType(item) {
            return item.name
        },
        getTextStatus(item) {
            if (item.account) {
                return item.name + ' | ' + item.account
            } else {
                return item.name
            }
        },
        setFileBase64() {
            if (this.receipt_file && this.receipt_file.name) {
                toBase64(this.receipt_file).then((fileBase64) => {
                    this.fileString = fileBase64
                })
            } else {
                this.fileString = ''
            }
        },
    },

    data: () => ({
      loadingE2E: false,
      possible_types: [
          {
              id: 0,
              name: 'Coelho',
              price: 6.9,
          },
          {
              id: 1,
              name: 'Time Bank',
              price: 19.4,
          },
          {
              id: 2,
              name: 'Emoji',
              price: 6.9,
          },
          {
              id: 3,
              name: 'Bronze 1 dia',
              price: 3.5,
          },
          {
              id: 4,
              name: 'Bronze 7 dias',
              price: 9.5,
          },
          {
              id: 5,
              name: 'Bronze 30 dias',
              price: 19,
          },
          {
              id: 6,
              name: 'Bronze 90 dias',
              price: 49,
          },
          {
              id: 7,
              name: 'Bronze 365 dias',
              price: 159,
          },
          {
              id: 8,
              name: 'Prata 1 dia',
              price: 5,
          },
          {
              id: 9,
              name: 'Prata 7 dias',
              price: 20,
          },
          {
              id: 10,
              name: 'Prata 30 dias',
              price: 79,
          },
          {
              id: 11,
              name: 'Prata 90 dias',
              price: 194,
          },
          {
              id: 12,
              name: 'Prata 365 dias',
              price: 569,
          },
          {
              id: 13,
              name: 'Ouro 1 dia',
              price: 9.7,
          },
          {
              id: 14,
              name: 'Ouro 7 dias',
              price: 48.5,
          },
          {
              id: 15,
              name: 'Ouro 30 dias',
              price: 194,
          },
          {
              id: 16,
              name: 'Ouro 90 dias',
              price: 474,
          },
          {
              id: 17,
              name: 'Ouro 365 dias',
              price: 1394,
          },
      ],
      payment_types: [
          {
            id: 0,
            name: 'Fichas',
          },
          {
            id: 1,
            name: 'Pix',
          },
          {
            id: 2, 
            name: 'Anotado'
          }
      ],
      possible_status: [
          {
              id: 0,
              name: 'Pendente',
          },
          {
              id: 1,
              name: 'Aguardando',
          },
          {
              id: 2,
              name: 'Pago',
          },
          {
              id: 3,
              name: 'Concluído',
          },
          {
              id: 4,
              name: 'Erro bot',
          },
      ],
      timeProps: {
          format: '24hr',
      },
      rulesFields: [(value) => !!value || 'Campo obrigatório'],
      rules: rules,
    }),
    watch: {
        receipt_file: {
            handler() {
                this.setFileBase64()
            },
        },
    },
}
</script>


<style>
:disabled {
    opacity: 55%;
}
</style>