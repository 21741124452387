<template>
    <v-card class="card-chat elevation-10">
        <v-row no-gutters class="row-chat">
            <v-snackbar :color="notificationType" top :timeout="2500" v-model="showNotification">{{ notificationMessage
            }}</v-snackbar>
            <v-col v-if="showChats()" sm="12" md="3" lg="3" xl="3" class="col-chats">
                <chats :chats="chats" :contact_id="contact_id" :getChats="getChats" :isGroup="openedChat.is_group"
                    :openChat="openChat" :openedChat="openedChat" :setChatUnreaded="setChatUnreaded"></chats>
            </v-col>
            <v-col v-if="showMessages()" @drop.prevent="addDropFile" @dragover.prevent sm="9" md="9" lg="9" xl="9"
                class="col-chat-messages">
                <header class="contact-desc-header" :class="getColorDescription()" v-if="openedChat.contact_id">
                    <v-icon v-if="isMobile()" @click="clearContactId">mdi-chevron-left</v-icon>

                    <v-avatar size="40" class="avatar-header">
                        <v-img v-if="openedChat.avatar" :src="openedChat.avatar"></v-img>
                    </v-avatar>

                    <div class="contact-name" @click="
                        openDialogContactDescription({
                            contact_description: openedChat.description
                                ? openedChat.description
                                : openedChat.description,
                        })
                        ">
                        {{
                            openedChat.contact_name
                                ? openedChat.contact_name
                                : openedChat.contact_id
                        }}
                        <div class="contact-desc-text" v-if="
                            openedChat.description &&
                            openedChat.description.text
                        ">
                            {{
                                openedChat.description
                                    ? openedChat.description.text
                                    : ''
                            }}
                        </div>
                    </div>

                    <div class="chips-contact" v-if="!isMobile()">
                        <v-chip active-class="" v-for="chip in getActiveChips()" class="ma-1" :key="chip.id"
                            :color="getColorChip(chip)" :text-color="chip.id != 9 ? 'white' : 'black'">
                            {{ chip.label }}
                        </v-chip>
                    </div>
                    <div v-if="audit_mode == true">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on"
                                    @click="showDialogAudit({ show: true })">mdi-calendar</v-icon>
                            </template>
                            <span>Buscar Mensagem por Data</span>
                        </v-tooltip>
                    </div>
                    <div class="chips-menu-contact" v-if="isMobile()">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" dark outlined small v-bind="attrs" v-on="on">
                                    Info
                                </v-btn>
                            </template>
                            <v-list>
                                <v-chip active-class="" v-for="chip in getActiveChips()" class="ma-1" :key="chip.id"
                                    :color="getColorChip(chip)" :text-color="'white'">
                                    {{ chip.label }}
                                </v-chip>
                            </v-list>
                        </v-menu>
                    </div>
                    <div class="send_contact" v-if="true">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" @click="openDialogRakeback()" v-on="on">mdi-cash-refund</v-icon>
                            </template>
                            <span>Adiantar Rakeback</span>
                        </v-tooltip>
                    </div>
                    <div class="send_contact">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-if="!flashFilter" v-bind="attrs" @click="setFlashFilter()"
                                    v-on="on">mdi-star</v-icon>
                                <v-icon v-else v-bind="attrs" @click="setFlashFilter()" v-on="on">mdi-star-off</v-icon>
                            </template>
                            <span>Filtrar Mensagens Automáticas</span>
                        </v-tooltip>
                    </div>
                    <div class="send_contact" v-if="openedChat.registered > 0">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" @click="openPlayerData()" :color="getColorRegistered(
                                    openedChat.registered,
                                )
                                    " v-on="on">mdi-check-decagram</v-icon>
                            </template>
                            <span>Visualizar Cadastro</span>
                        </v-tooltip>
                    </div>
                    <div v-if="
                        this.userData.role >= 90 || this.userData.role == 80
                    ">
                        <div class="send_contact" v-if="!openedChat.block_annotated">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" @click="
                                        toggleBlockAnnotated(openedChat)
                                        ">mdi-pencil-off</v-icon>
                                </template>
                                <span>Bloquear Anotados</span>
                            </v-tooltip>
                        </div>
                        <div class="send_contact" v-else>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" @click="
                                        toggleBlockAnnotated(openedChat)
                                        ">mdi-pencil</v-icon>
                                </template>
                                <span>Desbloquear Anotados</span>
                            </v-tooltip>
                        </div>
                    </div>
                    <div class="send_contact">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" @click="
                                    openBlockAnnotatedDialog({
                                        contact_id: openedChat.contact_id,
                                    })
                                    ">mdi-chart-box-outline</v-icon>
                            </template>
                            <span>Histórico Bloqueio Anotados</span>
                        </v-tooltip>
                    </div>
                    <div class="send_contact">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on"
                                    @click="annotatedAgent({ show: true })">mdi-file-chart</v-icon>
                            </template>
                            <span>Histórico de Anotados</span>
                        </v-tooltip>
                    </div>
                    <div class="send_contact">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" @click="
                                    showDialogContacts({
                                        show: true,
                                        mode: 'send-contact',
                                    })
                                    ">mdi-account-plus</v-icon>
                            </template>
                            <span>Enviar Contato</span>
                        </v-tooltip>
                    </div>
                    <div class="right-icon">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" @click="selectFile()">mdi-paperclip</v-icon>
                                <input ref="uploader" class="d-none" type="file" accept=".pdf, image/*"
                                    @keyup.esc="teste" @change="onFileChanged" />
                            </template>
                            <span>Enviar Arquivo</span>
                        </v-tooltip>
                    </div>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" @click="
                                showDialogRecoverMessages({ show: true })
                                ">mdi-email-alert-outline</v-icon>
                        </template>
                        <span>Recuperar Mensagens</span>
                    </v-tooltip>
                </header>
                <v-divider class="divider-black" v-if="openedChat.contact_id"></v-divider>
                <div class="messages-container" v-on:scroll="onScroll" ref="chatContainer">
                    <v-dialog width="1280" height="720" v-model="showContactDescription">
                        <contact-description :avatar="openedChat.avatar" :contact_id="openedChat.contact_id"
                            :contact_name="openedChat.contact_name" :hash_contact_id="openedChat.hash_contact_id"
                            :generate_hash="openedChat.hash_contact_id ? false : true
                                " :description="openedChat.description
                                    ? openedChat.description.text
                                    : ''
                                    " start_mode="VIEW" screen="chat" :showContactDescription="showContactDescription"
                            :openedChat="openedChat" @updateProp="updateValuePropHash"></contact-description>
                    </v-dialog>
                    <messages :contact_id="contact_id" :isGroup="openedChat.is_group" :messages="messages"
                        :loading="loading" :openedChat="openedChat" parent="chat"></messages>
                    <v-overlay :value="loading || loadingRakeback" :absolute="true" :opacity="0.5">
                        <v-progress-circular indeterminate size="32"></v-progress-circular>
                    </v-overlay>
                </div>
                <div class="inputmessagearea" v-if="contact_id">
                    <v-btn absolute @click="this.scrollToEnd" fab right small>
                        <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                    <v-btn v-if="audit_mode == true" absolute @click="this.scrollToStart" fab right small top
                        class="btn-scroll-top">
                        <v-icon>mdi-chevron-up</v-icon>
                    </v-btn>
                    <v-menu offset-y top v-if="isMobile()">
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip class="ma-1" color="info" @click="sendAnnotated()">Anotados</v-chip>
                            <v-chip class="ma-1" color="info" @click="finishChat()"
                                v-if="!openedChat.is_group">Finalizar</v-chip>
                            <v-chip class="ma-1" color="info" v-if="openedChat.is_group"
                                @click="finishChatGroup()">Finalizar
                            </v-chip>
                            <v-chip class="ma-2" color="info" v-bind="attrs" v-on="on">Mensagens</v-chip>
                        </template>

                    </v-menu>
                    <div v-else>
                        <automatic-messages-chip :clickInName="fillMessageByAutomaticMessage" :origin="'chat'"></automatic-messages-chip>
                        <v-chip class="ma-1" color="info" @click="sendAnnotated()">Anotados</v-chip>
                        <v-chip class="ma-1" color="info" @click="finishChat()"
                            v-if="!openedChat.is_group">Finalizar</v-chip>
                        <v-chip class="ma-1" color="info" v-if="openedChat.is_group"
                            @click="finishChatGroup()">Finalizar
                        </v-chip>
                    </div>
                    <v-row no-gutters>
                        <v-layout wrap>
                            <v-flex xs12 sm12 md12>
                                <v-menu :close-on-content-click="false" offset-y top v-model="showEmojiMenu"
                                    :position-x="x" :position-y="y">
                                    <v-list>
                                        <v-list-item>
                                            <Picker :data="emojiIndex" @select="handleSelectEmoji" :i18n="i18n">
                                            </Picker>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-flex>
                            <v-flex class="flex-reply" xs12 sm12 md12 v-if="reply_message._id">
                                <div class="message-reply">
                                    <reference-message :contact_name="getMessageContactName(reply_message)
                                        " :close="true" :message_reference="reply_message" :reference_id="reply_message.reference_id
                                            " :openedChat="openedChat" :type="2"></reference-message>
                                </div>
                            </v-flex>
                            <v-flex xs12 sm12 md12>
                                <v-textarea auto-grow :autofocus="!isMobile()" append-icon="mdi-send"
                                    prepend-inner-icon="mdi-emoticon-wink-outline" @click:prepend-inner="showEmoji"
                                    @click:append="onSendMessage(false)" class="textarea" background-color="white"
                                    v-if="contact_id" outlined id="textareamessage" hide-details="true" rows="1"
                                    @paste="onPaste" v-on:keyup.enter="onEnterPressed" v-model="messageText"
                                    :value="messageText" ref="textmessage"></v-textarea>
                            </v-flex>
                        </v-layout>
                    </v-row>
                </div>
                <div class="empty-container" v-if="!openedChat.contact_id">
                    teste
                </div>
            </v-col>
            <v-dialog :value="dialog_contacts" @click:outside="showDialogContacts({ show: false, mode: '' })"
                width="600">
                <contact :contacts_mode="contacts_mode" :showDialogContacts="showDialogContacts"></contact>
            </v-dialog>
            <v-dialog :value="dialogRakeback" width="1000" height="720" @click:outside="closeRake">
                <rakeback :avatar="openedChat.avatar" :contact_id="openedChat.contact_id"
                    :contact_name="openedChat.contact_name" :description="openedChat.description
                        ? openedChat.description.text
                        : ''
                        " start_mode="VIEW" screen="chat" :showContactDescription="showContactDescription"
                    :openedChat="openedChat"></rakeback>
            </v-dialog>

            <v-dialog @click:outside="closeTest" v-model="dialogTest" max-width="290">
                <v-card>
                    <v-card-title class="text-h5">
                        ID PPPoker: {{ transfer_form.id_pppoker }} <br />
                        não foi registrado <br />
                        no sistema
                    </v-card-title>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="red darken-1" text @click="closeTest()">
                            Cancelar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog @click:outside="closeNoBound" v-model="dialogNoBound" max-width="290">
                <v-card>
                    <v-card-title class="text-h5">
                        ID PPPoker: {{ transfer_form.id_pppoker }} <br />
                        não esta vinculado <br />
                        ao contato
                    </v-card-title>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="red darken-1" text @click="closeNoBound()">
                            Cancelar
                        </v-btn>

                        <v-btn color="green darken-1" text @click="nextTransferBound">
                            Prosseguir
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog @click:outside="setDialogGetInfo" v-model="dialoGetInfo" max-width="290">
                <v-card>
                    <v-card-title class="text-h5">
                        Consultar ID PPPoker
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="form">
                            <v-container grid-list-md>
                                <v-layout wrap>
                                    <v-flex xs12 sm12 md12>
                                        <v-text-field required label="ID PPPoker / Nome PPPoker *" v-model="id_pppoker"
                                            data-vv-name="id_pppoker" outlined></v-text-field>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog :value="dialogSupreme" width="1600" @click:outside="closeDialogSupreme">
                <chips-transfer-form></chips-transfer-form>
            </v-dialog>

            <v-dialog :value="dialog_block_history" @click:outside="showDialogBlockHistory({ show: false })"
                width="1000">
                <v-card>
                    <v-card-title>Histórico de Bloqueio Anotados</v-card-title>
                    <v-data-table :headers="blockHistoryHeaders" :items="blockHistoryItems"
                        no-data-text="Nenhum bloqueio encontrado">
                        <template v-slot:[`item.operation`]="{ item }">
                            <span>{{ getOperation(item.operation) }}</span>
                        </template>
                    </v-data-table>
                </v-card>
            </v-dialog>

            <v-dialog :value="dialog_annotated" @click:outside="showDialogAnnotated({ show: false })" width="800">
                <v-card>
                    <v-card-title class="headline text-center justify-center">Histórico Anotados</v-card-title>
                    <v-divider class="divider-black"></v-divider>
                    <v-data-table :headers="headers" :items="annotated_list" :options.sync="pagination_annotated"
                        :server-items-length="totalItemsAnnotated" class="elevation-10 table-margin table-at">
                        <template v-slot:[`item.date_created`]="{ item }">
                            <span>{{ getDate(item.date_created) }}</span>
                        </template>
                        <template v-slot:[`item.pending_payment`]="{ item }">
                            <span>{{
                                item.pending_payment
                                    ? item.pending_payment.toLocaleString(
                                        'pt-BR',
                                        {
                                            style: 'currency',
                                            currency: 'BRL',
                                        },
                                    )
                                    : 'R$ 0.00'
                            }}</span>
                        </template>
                        <template v-slot:[`item.current_week`]="{ item }">
                            <span>{{
                                item.current_week
                                    ? item.current_week.toLocaleString(
                                        'pt-BR',
                                        {
                                            style: 'currency',
                                            currency: 'BRL',
                                        },
                                    )
                                    : 'R$ 0.00'
                            }}</span>
                        </template>
                        <template v-slot:[`item.changed_value`]="{ item }">
                            <span>{{
                                item.changed_value
                                    ? item.changed_value.toLocaleString(
                                        'pt-BR',
                                        {
                                            style: 'currency',
                                            currency: 'BRL',
                                        },
                                    )
                                    : 'R$ 0.00'
                            }}</span>
                        </template>
                    </v-data-table>
                </v-card>
            </v-dialog>
            <v-dialog :value="dialog_recover" @click:outside="showDialogRecoverMessages({ show: false })" persistent
                width="400">
                <v-card>
                    <v-card-title class="headline text-center justify-center">Sincronizar Mensagens</v-card-title>
                    <v-flex xs12 sm12 md12 class="recover-margin">
                        <v-text-field required label="Quantidade *" v-model="recover_quantity"
                            data-vv-name="recover_quantity" type="number" outlined></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md12 class="recover-margin">
                        <v-checkbox v-model="deleteLastQuantityMessages"
                            :label="`Apagar últimas ${recover_quantity} mensagens`"></v-checkbox>
                    </v-flex>
                    <v-card-actions>
                        <v-col>
                            <v-btn text @click="
                                showDialogRecoverMessages({ show: false })
                                ">Cancelar</v-btn>
                        </v-col>
                        <v-col class="text-right">
                            <v-btn text type="submit" right @click="recoverCall()">Confirmar</v-btn>
                        </v-col>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog :value="dialog_player_data" persistent width="1000">
                <v-card>
                    <player-form-chat></player-form-chat>
                </v-card>
            </v-dialog>

            <v-dialog :value="dialog_audit" @click:outside="showDialogAudit({ show: false })" persistent width="400">
                <v-card>
                    <v-flex>
                        <v-col xs="12" md="12" sm="12">
                            <v-datetime-picker v-model="dateAudit" date-format="dd/MM/yyyy"
                                :time-picker-props="timeProps" label="A partir de:">
                                <template slot="dateIcon">
                                    <v-icon>mdi-calendar</v-icon>
                                </template>
                                <template slot="timeIcon">
                                    <v-icon>mdi-clock</v-icon>
                                </template>
                            </v-datetime-picker>
                        </v-col>
                    </v-flex>
                    <v-card-actions>
                        <v-col>
                            <v-btn text @click="showDialogAudit({ show: false })">Cancelar</v-btn>
                        </v-col>
                        <v-col class="text-right">
                            <v-btn text type="submit" right @click="setLimitByMessagesPeriod">Confirmar</v-btn>
                        </v-col>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog :value="occurrenceDialog" width="800">
                <occurrences-form></occurrences-form>
            </v-dialog>

            <v-dialog :value="dialogDescriptionFileToSend" width="800">
                <v-card>
                    <v-card-title class="justify-center">Enviar Arquivo</v-card-title>
                    <v-card-text>

                        <image-editor v-if="editDialog" :visible.sync="editDialog"
                            :imgsrc="messageToSend.image_base64" />

                        <chat-image v-if="messageToSend.image_base64" :imgsrc="messageToSend.image_base64"
                            :thumbsrc="messageToSend.image_base64" :imgWidth="600"></chat-image>
                        <chat-document v-else-if="messageToSend.document_name"
                            :mimetype="messageToSend.document_mimetype" :content="messageToSend.document_name"
                            :docsrc="messageToSend.document_thumb" :thumbsrc="messageToSend.document_base64"
                            :message_id="messageToSend._id" :is_group="messageToSend.is_group"></chat-document>
                        <v-btn text class="d-flex justify-center align-center mx-auto mt-5 mb-3" @click="openEditor">
                            <v-icon color="black">mdi-image-edit</v-icon>
                        </v-btn>
                        <v-expansion-panels>
                            <v-expansion-panel>
                                <v-expansion-panel-header>Ver Mensagens Automáticas</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <automatic-messages-chip
                                        :clickInName="fillMessageByAutomaticMessage" :origin="'message_send'"></automatic-messages-chip>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>


                        <v-col class="d-flex mt-4" cols="12" sm="12">
                            <v-textarea auto-grow label="Mensagem" background-color="white" outlined
                                prepend-inner-icon="mdi-emoticon-wink-outline" @click:prepend-inner="showEmoji"
                                v-on:keyup.enter="onEnterPressed" @click:append="onSendMessage(false)"
                                id="textareamessage" hide-details="true" rows=2 v-model="messageToSend.content"
                                :value="messageToSend.content">
                            </v-textarea>
                        </v-col>

                    </v-card-text>
                    <v-card-actions>
                        <v-col>
                            <v-btn color="red" @click="closeDialogSendDocument()">Cancelar</v-btn>
                        </v-col>
                        <v-col class="text-right
                            ">
                            <v-btn color="green" type="submit" right @click="onSendMessage(false)">Enviar</v-btn>
                        </v-col>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-card>
</template>

<script>
import Messages from './components/Messages.vue'
import Chats from './components/Chats.vue'
import Contact from './components/Contact.vue'
import { mapFields } from 'vuex-map-fields'
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import ContactDescription from './components/ContactDescription.vue'
import ReferenceMessage from './components/ReferenceMessage.vue'
import Rakeback from './components/Rakeback.vue'
import PlayerFormChat from '../../players/PlayerFormChat.vue'
import ChipsTransferForm from './components/ChipsTransferForm.vue'
import OccurrencesForm from './components/OccurrencesForm'
import Document from './components/Document.vue'
import AutomaticMessagesChip from './components/AutomaticMessagesChip.vue'
import Image from './components/Image.vue'
import ImageEditor from './components/ImageEditor.vue'
import emojiData from "emoji-mart-vue-fast/data/all.json";
import { Picker, EmojiIndex } from "emoji-mart-vue-fast";
// Import default CSS
import "emoji-mart-vue-fast/css/emoji-mart.css";
let emojiIndex = new EmojiIndex(emojiData);


export default {
    beforeDestroy() {
        this.closeWebSocketConnection()
    },
    created() {
        let contact_id = this.$route.params.contact_id
        this.startWebSocketConnection()
        if (contact_id) {
            this.openLink('/chat')
        }
        this.getBanks()
        this.getAutomaticMessages({ type: 1 })
    },
    data: () => ({
        editDialog: false,
        searchAutomaticMessage: '',
        active: [],
        active_flash: [],
        avatar: null,
        open: [],
        open_flash: [],
        users: [],
        files: [],
        searchEmoji: '',
        emojiIndex: emojiIndex,
        i18n: {
            search: 'Pesquisar...',
            categories: {
                Activity: 'Atividades',
                Flags: 'Bandeiras',
                Foods: 'Comida',
                Frequently: 'Frequentes',
                Objects: 'Objetos',
                Nature: 'Natureza',
                Peoples: 'Pessoas',
                Symbols: 'Símbolos',
                Places: 'Locais',
            },
        },
        timeProps: {
            format: '24hr',
        },
        headers: [
            { text: 'Contato', value: 'contact_name', sortable: false },
            { text: 'Nick', value: 'nick_agent', sortable: false },
            { text: 'P. Pendente', value: 'pending_payment', sortable: false },
            { text: 'Semana Atual', value: 'current_week', sortable: false },
            { text: 'Valor Alterado', value: 'changed_value', sortable: false },
            { text: 'Data', value: 'date_created', sortable: false },
            { text: 'Observação', value: 'observation', sortable: false },
            { text: 'Usuario', value: 'user_name', sortable: false },
        ],
        blockHistoryHeaders: [
            { text: 'Contato', value: 'contact_id', sortable: false },
            { text: 'Grupo', value: 'contact_name', sortable: false },
            { text: 'Nick', value: 'nick', sortable: false },
            { text: 'Data', value: 'date', sortable: false },
            { text: 'Operação', value: 'operation', sortable: false },
            { text: 'Usuário', value: 'user_name', sortable: false },
        ],
        content: '',
        lastUserIdMessage: '',
        isSelecting: false,
        recover_quantity: '5',
        showEmojiMenu: false,
        deleteLastQuantityMessages: false,
        x: 0,
        y: 0,
    }),
    components: {
        contact: Contact,
        'contact-description': ContactDescription,
        messages: Messages,
        chats: Chats,
        'player-form-chat': PlayerFormChat,
        'reference-message': ReferenceMessage,
        Rakeback,
        ChipsTransferForm,
        Picker,
        'occurrences-form': OccurrencesForm,
        "chat-document": Document,
        "chat-image": Image,
        "image-editor": ImageEditor,
        "automatic-messages-chip": AutomaticMessagesChip,
    },
    computed: {
        am2() {
            return this.automatic_messages.map((a) => (a.id = 2))
        },
        ...mapFields('chat', [
            'info_form.id_pppoker',
            'dateAudit',
            'messageText',
            'showContactDescription',
            'dialog_audit',
            'dialog_contacts',
            'dialog_player_data',
            'dialog_recover',
            'showNotification',
            'dialog_annotated',
            'dialog_block_history',
            'pagination_annotated',
            'totalItemsAnnotated',
            'messageToSend'
        ]),
        ...mapState('login', ['userData']),
        ...mapState('profile', ['profile']),
        ...mapState('automatic_messages', ['automatic_messages']),
        ...mapState('chat', [
            'dialogDescriptionFileToSend',
            'occurrenceDialog',
            'transfer_form',
            'dialoGetInfo',
            'flashFilter',
            'transfer_form',
            'dialogRakeback',
            'chips_transfer',
            'dialogSupreme',
            'dialogTest',
            'dialogNoBound',
            'audit_mode',
            'annotated_list',
            'blockHistoryItems',
            'attendances',
            'contact_id',
            'contacts_mode',
            'chats',
            'loading',
            'messages',
            'notificationType',
            'notificationMessage',
            'openedChat',
            'reply_message',
        ]),
        ...mapState('rakeback', ['loadingRakeback']),
        ...mapGetters('login', ['getUserName']),
        ...mapGetters('chat', ['userOpenChat']),
        selected() {
            if (!this.active_flash[0]) {
                return undefined
            }

            const id = this.active_flash[0]
            let am = this.automatic_messages.find(
                (automatic_message) => automatic_message._id === id,
            )

            if (am == undefined) {
                let children_am = []
                this.automatic_messages.forEach((element) => {
                    if (element.childrens.length > 0) {
                        element.childrens.forEach((son) => {
                            children_am.push(son)
                        })
                    }
                })
                console.log(children_am)
                am = children_am.find(
                    (automatic_message) => automatic_message._id === id,
                )
            }
            console.log(am)
            return am
        },
    },
    methods: {
        sendFile() {
            console.log('Enviando arquivo...', this.messageToSend)
        },
        openEditor() {
            console.log('Abrindo editor...');
            this.editDialog = true;
        },

        updateImage(editedImage) {
            this.messageToSend.image_base64 = editedImage;
        },
        ...mapActions('bank', { getBanks: 'getItems' }),
        addDropFile(e) {
            var file = e.dataTransfer.files[0]

            if (
                file.type.indexOf('image') != -1 ||
                file.type.indexOf('application/pdf') != -1
            ) {
                var payload = {
                    files: [file],
                }
                this.onSendDocument(payload)
            }
        },
        updateValuePropHash(value) {
            this.openedChat.hash_contact_id = value
        },
        getHelloPart() {
            let data = new Date()
            var hora = data.getHours()
            if (hora >= 0 && hora < 12) {
                return 'Bom Dia'
            } else if (hora >= 12 && hora < 18) {
                return 'Boa Tarde'
            } else if (hora >= 18 && hora < 24) {
                return 'Boa Noite'
            }
        },
        getOperation(operation) {
            return operation == 1 ? 'Bloqueio' : 'Desbloqueio'
        },
        getMessageContent(content) {
            if (typeof content == 'string' && content.length > 0) {
                var messageTreated = content
                    .replaceAll('[nome_usuario]', this.getUserName)
                    .replaceAll('[parte_do_dia]', this.getHelloPart)
                return messageTreated
            }

            return ''
        },
        openDialogRakeback() {
            this.getWeeklyAdvanceAndLimitByContactId()
            this.getDetailsClientByContactId()
        },
        ...mapMutations('chat', [
            'setDialogGetInfo',
            'closeWebSocketConnection',
            'showDialogRakeback',
            'showDialogTest',
            'showDialogNoBound',
            'setContentMessage',
            'setFlashFilter',
            'showDialogSupreme',
            'closeDialogSupreme',
            'closeDialog',
            'showRakebackAvailable',
            'showDialogContactDescription',
            'showDialogContacts',
            'showDialogRecoverMessages',
            'showDialogAnnotated',
            'showDialogAudit',
            'showDialogBlockHistory',
            'setPayloadFinish',
        ]),
        ...mapMutations('chat', {
            sNotification: 'showNotification',
        }),
        ...mapActions('chat', [
            'closeDialogRakeback',
            'annotatedAgent',
            'changeLimit',
            'clearContactId',
            'openDialogContactDescription',
            'finishChatGroup',
            'finishAttendance',
            'getOccurrences',
            'getRakesPass',
            'getChats',
            'getMessagesByChatId',
            'onEnterPressed',
            'onSendDocument',
            'onSendMessage',
            'openChat',
            'openPlayerData',
            'recoverMessages',
            'sendChipMessage',
            'sendAnnotated',
            'showAutomaticMessages',
            'startWebSocketConnection',
            'setChatUnreaded',
            'setConnectionByContactId',
            'toggleBlockAnnotated',
            'setLimitByMessagesPeriod',
            'openBlockAnnotatedDialog',
            "getWeeklyAdvanceAndLimitByContactId",
            "getDetailsClientByContactId",
            "closeDialogSendDocument",
            'fillMessageByAutomaticMessage'
        ]),
        ...mapActions('automatic_messages', ['getAutomaticMessages']),
        ...mapActions('menu', ['openLink']),
        getColorRegistered(registered) {
            if (registered == 10) {
                return 'black'
            } else if (registered == 50) {
                return 'yellow'
            } else if (registered == 99) {
                return 'blue'
            }
        },
        fillMessageToSend(text) {
            this.messageToSend.content = text
        },
        closeRake() {
            this.closeDialogRakeback()
        },
        closeTest() {
            this.showDialogTest({ show: false })
        },
        closeNoBound() {
            this.showDialogNoBound({ show: false })
        },
        nextTransferTest() {
            this.closeTest()
            this.showDialogSupreme({ show: true, transfer: this.transfer_form })
        },
        nextTransferBound() {
            this.closeNoBound()
            this.showDialogSupreme({
                show: true,
                transfer: this.chips_transfer[0],
            })
        },
        getDate(date) {
            if (date) {
                var d = new Date(date)
                return d.toLocaleDateString() + ' ' + d.toLocaleTimeString()
            } else {
                return ''
            }
        },
        getColorDescription() {
            if (this.openedChat) {
                if (this.openedChat.description) {
                    if (
                        this.openedChat.description.chips &&
                        this.openedChat.description.chips.length != 0
                    ) {
                        if (this.openedChat.description.chips[3].status == 1) {
                            return 'description-red'
                        } else if (
                            this.openedChat.description.chips[7].status == 1
                        ) {
                            return 'description-yellow'
                        } else if (this.openedChat.description.chips[10] != null && this.openedChat.description.chips[10] != undefined) {
                            if (
                                this.openedChat.description.chips[10].status != null && this.openedChat.description.chips[10].status == 1
                            ) {
                                return 'description-light-green'
                            }
                        } else {
                            return 'description-default'
                        }
                    }
                }
            }
            return ''
        },
        getActiveChips() {
            if (this.openedChat) {
                if (this.openedChat.description) {
                    if (
                        this.openedChat.description.chips &&
                        this.openedChat.description.chips.length != 0
                    ) {
                        return this.openedChat.description.chips.filter(
                            (c) => c.status == 1,
                        )
                    }
                }
            }
            return []
        },
        getColorChip(chip) {
            if (chip.id == 1) {
                return 'orange'
            } else if (chip.id == 2) {
                return 'green'
            } else if (chip.id == 3) {
                return 'blue'
            } else if (chip.id == 4) {
                return 'red'
            } else if (chip.id == 5) {
                return 'purple'
            } else if (chip.id == 9 || chip.id == 8) {
                return 'yellow'
            } else if (chip.id == 10) {
                return 'grey'
            } else {
                return 'black'
            }
        },
        getMessageContactName(message) {
            if (this.openedChat.is_group == false) {
                return message.type == 0
                    ? message.contact_name
                        ? message.contact_name
                        : '-'
                    : this.openedChat.contact_name
                        ? this.openedChat.contact_name
                        : this.openedChat.contact_id
            } else {
                return message.type == 0
                    ? message.contact_name
                        ? message.contact_name
                        : '-'
                    : message.contact_name
                        ? message.contact_name
                        : message.participant_phone
            }
        },
        onPaste(e) {
            if (e.clipboardData && e.clipboardData.items.length != 0) {
                var file = e.clipboardData.items[0]
                var file_data = file.getAsFile()
                var payload = {
                    files: [file_data],
                }
                this.onSendDocument(payload)
            }
        },
        showChats() {
            var showChats = !this.isMobile() || !this.contact_id
            return showChats
        },
        showMessages() {
            var showMessages = !this.isMobile || this.contact_id
            return showMessages
        },
        showEmoji(e) {
            this.showEmojiMenu = false
            this.x = e.clientX
            this.y = e.clientY - 20
            this.$nextTick(() => {
                this.showEmojiMenu = true
            })
        },
        isMobile() {
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent,
                )
            ) {
                return true
            }
            return false
        },
        finishAt(message) {
            var payload = {
                type: 'message',
                message: message,
            }
            this.finishAttendance(payload)
        },
        finishChat() {
            if (
                this.openedChat.is_group == false &&
                this.userOpenChat.id != this.userData.id &&
                !this.profilePermissions('finish_atendance') &&
                this.userOpenChat.id != -1
            ) {
                let notification = {
                    show: true,
                    message:
                        'Atendimento em andamento! Aberto por: ' +
                        this.userOpenChat.name,
                    type: 'error',
                }
                this.sNotification(notification)
            } else {
                var payload = {
                    type: 'chat',
                    chat: this.openedChat,
                }
                this.setPayloadFinish(payload)
                this.getOccurrences()
            }
        },
        profilePermissions(param) {
            return this.profile[param] == 1
        },
        onFileChanged(e) {
            if (e.target.files.length) {
                var payload = {
                    files: e.target.files,
                }
                this.onSendDocument(payload)
            }
        },
        onScroll() {
            var container = this.$el.querySelector('.messages-container')
            if (container.scrollTop == 0 && this.audit_mode == false) {
                this.changeLimit({ scrollPosition: container.scrollHeight })
            }
        },
        recoverCall() {
            if (this.recover_quantity == 0) {
                let notification = {
                    show: true,
                    message: 'Informe a quantidade de mensagens!',
                    type: 'error',
                }
                this.sNotification(notification)
                return
            } else if (this.recover_quantity > 50) {
                let notification = {
                    show: true,
                    message:
                        'Quantidade de mensagens não pode ser superior a 50!',
                    type: 'error',
                }
                this.sNotification(notification)
            }
            this.recoverMessages({
                quantity: this.recover_quantity,
                deleteLastQuantityMessages: this.deleteLastQuantityMessages,
            })
        },
        handleSelectEmoji(emoji) {
            if (this.dialogDescriptionFileToSend) {
                this.selectEmojiDialog(emoji);
            } else {
                this.selectEmoji(emoji);
            }
        },
        selectEmoji(emoji) {
            if (emoji && emoji.native) {
                this.messageText += emoji.native
            }
            this.showEmojiMenu = false
        },
        selectEmojiDialog(emoji) {
            if (emoji && emoji.native) {
                this.messageToSend.content += emoji.native
            }
            this.showEmojiMenu = false
        },
        selectFile() {
            this.isSelecting = true
            window.addEventListener(
                'focus',
                () => {
                    this.isSelecting = false
                },
                { once: true },
            )

            this.$refs.uploader.click()
        },
        scrollTo() {
            let currentHeight = this.$refs.chatContainer.scrollHeight
            let difference = currentHeight - this.totalChatHeight
            var container = this.$el.querySelector('.messages-container')
            container.scrollTop = difference
        },
        scrollToEnd() {
            this.$nextTick(() => {
                var container = document.querySelector('.messages-container')
                if (container) {
                    container.scrollTop = container.scrollHeight
                }
            })
        },
        scrollToStart() {
            this.$nextTick(() => {
                var container = document.querySelector('.messages-container')
                if (container) {
                    container.scrollTop = 0
                }
            })
        },
    },
    watch: {
        '$route.params.contact_id': function (contact_id) {
            this.setConnectionByContactId(contact_id)
        },
        messages: function (messages) {
            if (messages.length > 0) {
                // this.scrollToEnd()
            }
        },
        pagination_annotated: function () {
            this.annotatedAgent({ show: true })
        },
        reply_message: function (reply_message) {
            if (reply_message._id) {
                this.$refs.textmessage.focus()
            }
        },
        searchAutomaticMessage(val) {
            var payload = {
                filter: val,
                type: 1,
            }
            this.getAutomaticMessages(payload)
        },
    },
}
</script>

<style>
@import './chat.css';

.filter-flash {
    padding: 10px;
    margin: 0;
    height: 90px;
}

.fundo-branco {
    background-color: white;
}

.tamanho-bom {
    width: 520px;
    height: 500px;
}

.message {
    margin-bottom: 5px;
}

.message.own {
    text-align: right;
}

.otherthingT {
    width: 250px;
    min-height: 270px;
    max-width: 100%;
    min-width: 100%;
}

.box-message-automatic {
    width: 250px;
}

.otherthingS {
    width: 250px;
    max-width: 100%;
}

.wpp {
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 4px;
    padding-bottom: 2px;
    border-radius: 10px;
    background-color: #dcf8c6;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
        0 2px 1px -1px rgba(0, 0, 0, 0.12);
    max-width: 100%;
    width: 185px;
    word-wrap: break-word;
    -webkit-transition: background-color 50ms linear;
    -ms-transition: background-color 50ms linear;
    transition: background-color 50ms linear;
    text-align: center;
    font-family: sans-serif;
    margin: 18%;
    align-items: center;
}

.alturaM {
    width: 312px;
    max-height: 300px;
}

.alturaMeT {
    width: 312px;
    max-height: 300px;
    margin-bottom: 12px;
}

.alturaMeW {
    width: 312px;
    max-height: 300px;
    margin-bottom: 12px;
    margin-right: 12px;
}

.alturaMA {
    max-height: 250px;
}

.off {
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 4px;
    padding-bottom: 2px;
    border-radius: 10px;
    background-color: rgb(248, 145, 145);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
        0 2px 1px -1px rgba(0, 0, 0, 0.12);
    max-width: 100%;
    width: 185px;
    word-wrap: break-word;
    -webkit-transition: background-color 50ms linear;
    -ms-transition: background-color 50ms linear;
    transition: background-color 50ms linear;
    text-align: center;
    font-family: sans-serif;
    margin: 18%;
    align-items: center;
}

.box {
    width: 100px;
    height: 100px;
    position: fixed;
    z-index: 1000;
    left: 1rem;
    top: 1rem;
    background-color: #f00;
}

.teste {
    max-height: 800px !important;
    overflow: hidden;
    max-height: 800px !important;
}
</style>