<template>
    <div>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-snackbar :color="notification.type" right top :timeout="2000" v-model="notification.show">
            {{ notification.message }}
        </v-snackbar>

        <v-toolbar flat class="elevation-5">
            <v-icon color="black" left>mdi-phone</v-icon>
            <v-toolbar-title>Números SAC</v-toolbar-title>
            <v-spacer></v-spacer>
            <div class="button-toolbar">

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="black" dark class="ma-2" @click="getItems" v-bind="attrs" v-on="on"
                            small><v-icon>mdi-refresh</v-icon></v-btn>
                    </template>
                    <span>Recarregar</span>
                </v-tooltip>


            </div>
        </v-toolbar>

        <v-data-table :headers="headers" :loading="loading" :items="items" :options.sync="pagination"
            :server-items-length="totalItems" class="elevation-10 table-margin" no-data-text="Nenhuma falta encontrada">
            <template v-slot:[`item.actions`]="{ item }">

                <v-tooltip bottom>
                    <template v-slot:activator="{ attrs, on }">
                        <v-icon v-bind="attrs" v-on="on" small class="mr-2"
                            @click="edit({ item: item })">mdi-pencil</v-icon>
                    </template>
                    <span>Editar</span>
                </v-tooltip>
            </template>


        </v-data-table>

        <v-dialog :value="dialog" width="600" @click:outside="closeDialog">
            <help-numbers-form></help-numbers-form>
        </v-dialog>


    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import HelpNumbersForm from './HelpNumbersForm.vue'

export default {
    name: 'HelpNumbers',

    created() {
        this.getItems({ resetPage: false })
    },

    components: {
        HelpNumbersForm,
    },

    methods: {
        ...mapActions('help_numbers', ['getItems', 'closeDialog']),
        ...mapMutations('help_numbers', [
            'edit',
            'view',
            'addConfig',
            'setShowFilter',
        ]),

    },
    computed: {
        ...mapState('login', ['userData']),
        ...mapState('help_numbers', [
            'dialog',
            'items',
            'notification',
            'mode',
            'loading',
            'totalItems',
        ]),
        ...mapFields('help_numbers', ['pagination']),
    },
    data: () => ({
        headers: [
            {
                text: 'Id',
                value: 'id',
                sortable: true,
                align: 'left',
            },
            {
                text: 'Telefone',
                value: 'phone',
                sortable: true,
                align: 'left',
            },

            {
                text: 'Ações',
                value: 'actions',
                sortable: false,
                align: 'center',
            },
        ],
    }),

    watch: {
        pagination: {
            handler() {
                this.getItems({ resetPage: false })
            },
        },
    },
}
</script>