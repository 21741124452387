<template>
    <div @drop.prevent="addDropFile" @dragover.prevent>
        <v-card>
            <v-card-title>
                <span class="headline"> Editar Número </span>
            </v-card-title>

            <v-card-text>
                <v-alert type="info" :value="true" elevation="2">
                    <strong>Atenção!</strong> Preencha o número sem o 9º dígito.
                </v-alert>
            </v-card-text>

            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12 sm12 md12>
                                <v-text-field required :rules="[rules.phone]" label="Número de Telefone"
                                    placeholder="Ex: 553187654321" v-model="phone" prepend-inner-icon="mdi-phone"
                                    data-vv-name="Número de Telefone" :disabled="this.mode === 'VIEW'"
                                    outlined></v-text-field>
                            </v-flex>



                        </v-layout>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-col>
                    <v-btn text @click="setShowDialog(false)">{{
                        mode == 'VIEW' ? 'Voltar' : 'Cancelar'
                    }}</v-btn>
                </v-col>
                <v-col class="text-right" v-if="mode != 'VIEW'">
                    <v-btn text type="submit" right @click="saveHelpNumber">Salvar</v-btn>
                </v-col>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
    name: 'HelpNumbersForm',
    created() {
        this.$validator.extend('required', {
            getMessage: (field, [label]) => label + ' deve ser informado',
            validate: (_, [, field]) => {
                if (this[field] || this[field] === 0) {
                    return true
                } else {
                    return false
                }
            },
        })
        this.$validator.extend('max_length', {
            getMessage: (_, [length]) => 'No máximo ' + length + ' caracteres.',
            validate: (val, [length]) => {
                if (val.length > length) {
                    return false
                } else {
                    return true
                }
            },
        })
    },
    methods: {
        ...mapActions('help_numbers', ['save']),
        ...mapMutations('help_numbers', ['setShowDialog']),

        saveHelpNumber() {
            let valid = this.$refs.form.validate()
            if (valid) {
                this.save({})
            }
        },
    },
    computed: {
        ...mapState('help_numbers', ['mode', 'selectedItem', 'dialog']),
        ...mapFields('help_numbers', [
            'selectedItem.phone',

        ]),
    },
    data: () => ({
        valid: false,

        rules: {
            required: (value) => !!value || 'Campo Obrigatório',
            phone: (value) => {
                if (value.length != 12) return 'Telefone inválido'
                return true
            },
        },
    }),

}
</script>