const defaultItem = {
    contactId: null,
    instanceId: null,
    instanceName: "",
    name: "",
    inscription: "",
    bank: null,
    idClub: null,
    idApp: null,
    message: "",
    idMessage: "",
    value: 0,
    status: 0,
    sended: 0,
    type: 0,
    pixKey: "",
    typeSend: 0,
    transferIdApp: null,
    transferIdClub: null,
    forcePositiveBalance: false,
    receiptName: null,
    receiptFile: null,
    receipt: null,
    scheduleReason: ''
};

export default defaultItem