<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline text-center justify-center">Filtro</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  label="ID "
                  v-model="id"
                  data-vv-name="id"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  label="ID do Jogador"
                  v-model="player_id"
                  data-vv-name="player_id"
                  :clearable="true"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                  <v-autocomplete
                    label="Clube *"
                    :items="slots"
                    v-model="club_id"
                    :readonly="this.mode == 'VIEW'"
                    :error-messages="errors.collect('slots')"
                    v-validate="'required'"
                    :item-text="getTextSlot"
                    item-value="id"
                    data-vv-name="slots"
                    outlined
                  ></v-autocomplete>
                </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  label="Tipo"
                  :items="possible_types"
                  v-model="type"                  
                  :item-text="getTextType"
                  item-value="id"
                  :clearable="true"
                  outlined
                >
                </v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field required label="Valor" v-model="value" outlined></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  label="Tipo de Pagamento"
                  :items="possible_payment_types"
                  v-model="payment_type"
                  item-text="name"
                  item-value="id"
                  :clearable="true"
                  outlined
                >
                </v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  label="Banco"
                  :items="banks"
                  v-model="bank"
                  :readonly="this.mode == 'VIEW'"
                  :error-messages="errors.collect('bank')"
                  v-validate="'required'"
                  :item-text="getTextBank"
                  item-value="id"
                  data-vv-name="bank"
                  :clearable="true"
                  outlined
                >
                </v-select>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-datetime-picker
                  v-model="date_picker"
                  date-format="dd/MM/yyyy"
                  clearText="Limpar"
                  okText="Confirmar"
                  :time-picker-props="timeProps"
                  :readonly="this.mode == 'VIEW'"
                  label="Data"
                  :textFieldProps="{ outlined: true }"
                >
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-select
                  label="Status"
                  :items="possible_status"
                  v-model="status"
                  :readonly="this.mode == 'VIEW'"
                  :error-messages="errors.collect('status')"
                  v-validate="'required'"
                  :item-text="getTextStatus"
                  item-value="id"
                  data-vv-name="name"
                  :clearable="true"
                  outlined
                >
                </v-select>
              </v-flex>

            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn
            text
            @click="
              setShowFilter(false);
              clearFilter();
            "
            >Cancelar</v-btn
          >
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "VipSalesFilter",
  computed: {
    ...mapState("vip_sales", ["mode", "selectedItem", "showDialog"]),
    ...mapState("bank", {"banks": "items"}),
    ...mapFields("vip_sales", [
      "filter.id",
      "filter.player_id",
      "filter.club_id",
      "filter.date_picker",
      "filter.type",
      "filter.value",
      "filter.bank",
      "filter.payment_type",
      "filter.status",
      "filter.user_id",
    ]),
    ...mapState("slots", ["slots"]),
  },   
  methods: {
    ...mapMutations("vip_sales", ["setShowFilter", "clearFilter"]),
    ...mapActions("vip_sales", ["getItems"]),
    filter() {
      this.getItems({ resetPage: true });
      this.setShowFilter(false);
    },
    getTextBank(item) {
      if(item.account) {
        return item.name + ' | ' + item.account
      } else {
        return item.name
      }
    },
    getTextType(item) {
      if(item.account) {
        return item.name + ' | ' + item.account
      } else {
        return item.name
      }
    },
    getTextPaymentType(item) {
      if(item.account) {
        return item.name + ' | ' + item.account
      } else {
        return item.name
      }
    },
    getTextSlot(item) {
      return item.name
    },
    getTextStatus(item) {
      if(item.account) {
        return item.name + ' | ' + item.account
      } else {
        return item.name
      }
    },
  },
  data: () => ({
    possible_companies: [
      {
        id: 0,
        name: "Stars",
      },
      {
        id: 1,
        name: "Aquarios",
      },
      {
        id: 2,
        name: "Jacks",
      },
      {
        id: 3,
        name: "Canubet",
      },
      {
        id: 4,
        name: "Grupo Stars",
      },
      {
        id: 5,
        name: "Stars Horizon",
      },
      {
        id: 6,
        name: "Outros",
      },
      {
        id: 7,
        name: "CGP",
      },
      {
        id: 8,
        name: "PHD Digital",
      },
      {
        id: 9,
        name: "Se precisar",
      },
    ],
    possible_types: [
        {
          id: 0,
          name: "Tipo 1"
        },
        {
          id: 1,
          name: "Tipo 2"
        },
      ],
      possible_payment_types: [
        {
          id: 0,
          name: "Fichas"
        },
        {
          id: 1,
          name: "Pix"
        },
      ],
      possible_status: [
        {
          id: 0,
          name: "Pendente"
        },
        {
          id: 1,
          name: "Pago"
        },
        {
          id: 2,
          name: "Concluido"
        },
        {
          id: 3,
          name: "Erro bot"
        }
      ],
    timeProps: {
      format: "24hr",
    },
  }),
};
</script>
