import { validateCPF } from './validation.js'

export default {
  required: (value) => {
    let result = !!value || "Obrigatório"
    return result
  },
  requiredAllowZero: (value) => value !== undefined && value !== null && value !== "" || "Obrigatório",
  min: (v) => v.length >= 8 || "Min 8 characters",
  email: (value) => /.+@.+\..+/.test(value) || 'Email inválido.',
  emailMatch: () => `The email and password you entered don't match`,
  cpfValid: (value) => validateCPF(value) || "CPF inválido",
  alreadyExists: (loginInUse) => !loginInUse || "Usuário já cadastrado",
  passwordMatch: (password, confirmationPassword) => password === confirmationPassword || 'As senhas não coincidem',
  dayValid: v => (v && v > 0 && v <= 31) || 'Dia inválido',
  yearValid: v => (v && v.length === 4 && v > 1900 && v <= new Date().getFullYear()) || 'Ano inválido',
  monthValid: v => (v && v > 0 && v <= 12) || 'Mês inválido',
  decimal: v => /^\d+(\.\d{1,2})?$/.test(v) || 'Insira um valor válido', 
  maxLength: (value, length) => value.length >= length || `Máximo de ${length} caracteres`,
  endToEndId: (value) => value.replace('-').length === 32 && ( value && value[0].toLowerCase() == 'e')|| 'EndToEndId inválido',
  phone: v => /^\d{10,11}$/.test(v) || 'Insira um número de telefone válido',
};
