const formatMoney = (value) => {
    return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
    })
}

export const  verificarSeparadorDecimal = (numeroStr) =>  {
    let ultimaVirgula = numeroStr.lastIndexOf(',');
    let ultimoPonto = numeroStr.lastIndexOf('.');

    if (ultimaVirgula > ultimoPonto) {
        return ',';
    } else if (ultimoPonto > ultimaVirgula) {
        return '.';
    } else {
        return null; // Nenhum separador decimal encontrado
    }
}


export default formatMoney;