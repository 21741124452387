<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar :color="notificationType" center top :timeout="2000" v-model="showNotification">
      {{ notificationMessage }}
    </v-snackbar>
    <v-toolbar flat class="elevation-5">
      <div>
        <v-toolbar-title>Pix</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div class="button-toolbar">
        <!-- <download-excel
          :before-generate="generateSheet"
          :before-finish="finishSheet"
          :name="getSheetName()"
          :fetch="getItemsNoPage"
          :fields="sheet_fields"
          class="excel"
          worksheet = "Pix"
        >
            <v-btn color="black" dark class="ma-2" small><v-icon>mdi-download</v-icon></v-btn> 
        </download-excel> -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="black" dark class="ma-2" @click="downloadXlsx({ show: true })" v-bind="attrs" v-on="on" small>
              <v-icon>mdi-file-excel</v-icon>
            </v-btn>
          </template>
          <span>Gerar Planilha</span>
        </v-tooltip>
        <v-btn color="black" dark class="ma-2" @click="showFilter({ show: true })"
          small><v-icon>mdi-filter</v-icon></v-btn>
        <v-btn color="black" dark class="ma-2" @click="getItems" small><v-icon>mdi-refresh</v-icon></v-btn>
      </div>
    </v-toolbar>


    <v-data-table :headers="headers" :loading="loading" :items="items" :item-class="getClassColor"
      :options.sync="pagination" :server-items-length="totalItems" no-data-text="Nenhuma despesa encontrada" show-expand
      :expanded.sync="expanded"
      class="elevation-10 table-margin" :footer-props="{
        'items-per-page-options': [10, 20, 50, 100],
        'items-per-page-text': 'Itens por página',
        'show-first-last-page': true,
        'page-text': `Página ${pagination.page} de ${parseInt(totalItems / pagination.itemsPerPage) + 1
          }`,
      }">
      <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
        <v-icon @click="expand(true)" v-if="item.observation && !isExpanded"
          >mdi-chevron-down</v-icon
        >
        <v-icon @click="expand(false)" v-if="item.observation && isExpanded"
          >mdi-chevron-up</v-icon
        >
      </template>
      
      <template v-slot:expanded-item="{ item }">
        <td :colspan="(headers.length/2)">
          Motivo uso externo: {{ item.observation }}
        </td>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <span>{{ getDate(item.date) }}</span>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <v-chip :color="item.type == 1 ? 'green' : 'red'" class="ma-2" text-color="white" label>
          {{ item.type == 1 ? "Entrada" : "Saída" }}
        </v-chip>
      </template>
      <template v-slot:[`item.depositId`]="{ item }">
        <span>{{ getTransactionId(item) }}</span>
      </template>
      <template v-slot:[`item.withdrawalId`]="{ item }">
        <span>{{ getTypeTransaction(item) }}</span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <span>{{ getStatus(item.status) }}</span>
      </template>
      <template v-slot:[`item.used`]="{ item }">
        <v-chip :color="isPixUsed(item) ? 'blue' : 'green'" class="ma-2" text-color="white" label>{{ isPixUsed(item) ?
          "Sim" : "Não" }}</v-chip>
      </template>
      <template v-slot:[`item.externalUsed`]="{ item }">
        <v-chip :color="item.externalUsed ? 'blue' : 'green'" class="ma-2" text-color="white" label>{{ item.externalUsed ?
          "Sim" : "Não" }}</v-chip>
      </template>
      <template v-slot:[`item.value`]="{ item }">
        <span>{{
          parseFloat(item.value).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
        }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom v-if="!isPixUsed(item)">
          <template v-slot:activator="{ on, attrs }">
            <v-icon small v-on="on" v-bind="attrs" class="mr-2"
              @click="openDialogExternalUse({ item: item })">mdi-block-helper</v-icon>
          </template>
          <span>Uso Externo</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog :value="dialogFilter" width="600" @click:outside="showFilter({ show: false })">
      <pix-filter></pix-filter>
    </v-dialog>

    <v-dialog :value="dialog" width="600" @click:outside="showDialog(false)">
      <pix-form></pix-form>
    </v-dialog>´

    <v-dialog :value="dialogExternalUse" width="600" @click:outside="showDialogExternalUse({ show: false })">
      <div>
        <v-card>
          <v-card-title>
            <span class="headline text-center">Uso Externo</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm12 md12>
                    <v-textarea v-model="observation" label="Descrição do uso externo *" required outlined rows="3"
                      :rules="[(v) => !!v || 'Campo obrigatório', (v) => (v && v.length <= 255) || 'Máximo de 255 caracteres']"></v-textarea>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            
            <v-btn color="red" dark @click="showDialogExternalUse({ show: false })">Cancelar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green" dark @click="pixExternalUse" type="submit">Finalizar</v-btn>
          </v-card-actions>

        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import PixFilter from './PixFilter'
import PixForm from './PixForm.vue';
// import JsonExcel from 'vue-json-excel'
import { utils, writeFile } from "xlsx";

export default {
  name: 'Pix',
  computed: {
    ...mapState('login', ['userData']),
    ...mapState('pix', ['dialog', 'item', 'errors', 'loading', 'mode', 'dialogExternalUse', 'notificationType', 'notificationMessage', 'totalItems', 'items']),
    ...mapFields('pix', [
      'pagination',
      'dialogFilter',
      'overlay',
      'showNotification',
      "editedItem.observation",
    ])
  },
  created() {
    this.watchPix()
  },
  components: {
    // "download-excel": JsonExcel,
    "pix-filter": PixFilter,
    "pix-form": PixForm
  },
  methods: {
    
    isPixUsed(item) {
      return item.autoAttendanceId || item.annotatedId || item.clientDepositId || item.clientWithdrawalId || item.expenseId || item.depositId || item.withdrawalId || item.externalUsed || item.vipSaleId
    },
    ...mapMutations('pix', ['add', 'edit', 'viewItem', 'showFilter', 'setDialogReceipt', 'showDialogExternalUse']),
    ...mapActions('pix', ['closeDialog', 'getItems', 'getItemsNoPage', 'openDialogExternalUse', 'pixExternalUse']),
    isMobile() {
      if ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
        return true;
      }
      return false
    },
    getClassColor(item) {
      if (this.isPixUsed(item)) {
        return 'row-green'
      } else if (item.withdrawal_id && item.status == 3) {
        return 'row-red'
      } else {
        return 'row-blue'
      }
    },
    getDate(date) {
      var d = new Date(date)
      return d.toLocaleDateString() + " " + d.toLocaleTimeString()
    },
    getStatus(status) {
      let possible_status = ["Pendente", "Concluido", "Erro"]
      return possible_status[status - 1]
    },
    finishSheet() {
      this.overlay = false
    },
    generateSheet() {
      this.overlay = true
    },
    getTransactionId(item) {
      if (item.depositId) {
        return item.depositId
      } else if (item.withdrawalId) {
        return item.withdrawalId
      } else if (item.expenseId) {
        return item.expenseId
      } else if (item.autoAttendanceId) {
        return item.autoAttendanceId
      } else if (item.annotatedId) {
        return item.annotatedId
      } else if (item.clientDepositId) {
        return item.clientDepositId
      } else if (item.clientWithdrawalId) {
        return item.clientWithdrawalId
      } else if (item.vipSaleId) {
        return item.vipSaleId
      }
      return ''
    },
    getTypeTransaction(item) {
      if (item.deposit_id) {
        return "Depósito"
      } else if (item.withdrawalId) {
        return "Saque"
      } else if (item.expenseId) {
        return "Despesa"
      } else if (item.autoAttendanceId) {
        return "Auto Atendimento"
      } else if (item.annotatedId) {
        return "Atendimento Anotado"
      } else if (item.clientDepositId) {
        return "Depósito Cliente"
      } else if (item.clientWithdrawalId) {
        return "Saque Cliente"
      } else if(item.vipSaleId) {
        return "Venda de Vip"
      } else {
        return ''
      }
    },
    getSheetName() {
      var date = new Date()
      var name = 'pix_' + date.toLocaleDateString() + " " + date.toLocaleTimeString()
      return name + '.xls'
    },
    async downloadXlsx() {
      this.generateSheet()
      try {
        let itemsNoPage = await this.getItemsNoPage()
        if (itemsNoPage) {
          const wb = utils.book_new()
          let items = itemsNoPage.map(row => ({
            "CPF/CNPJ": row.client_key,
            "Valor": this.valueXLSX(row.value),
            "Data": this.dateXLSX(row.date),
            "EndToEndId": row.endToEndId,
            "Deposito": row.deposit_id,
            "Saque": row.withdrawal_id,
            "Despesa": row.expense_id,
          }))
          const ws = utils.json_to_sheet(items)
          utils.book_append_sheet(wb, ws, "Despesas")
          await writeFile(wb, this.getSheetName())
        }
      } catch (error) {
        this.finishSheet()
      } finally {
        this.finishSheet()
      }
    },
    watchPix() {
      if(this.mode == 'LIST') {
        this.getItems({ resetPage: false })
      }
      if(this.$router) {
        if(this.$router.history.current.name == "pix") {
          setTimeout(() => {
            this.watchPix()
          }, 60000)
        }
      }
    },
    valueXLSX: (value) => {
      if (value) {
        let real = value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
        return real.substr(3);
      }
      return 0;
    },
    dateXLSX: (value) => {
      var d = new Date(value)
      return d.toLocaleDateString() + " " + d.toLocaleTimeString()
    },
  },
  data: () => ({
    expanded: [],
    headers: [
      {
        text: "Data",
        align: "left",
        value: "date",
        sortable: true
      },
      { text: "Tipo", value: "type", sortable: true },
      { text: "CPF/CPNJ", value: "clientKey", sortable: true },
      { text: "Nome", value: "clientName", sortable: true },
      { text: "Valor", value: "value", sortable: true },
      { text: "TxId ( Banco )", value: "txId", sortable: false },
      { text: "endToEndId", value: "endToEndId", sortable: false },
      { text: "Id Transação", value: "depositId", sortable: false },
      { text: "Tipo Transação", value: "withdrawalId", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Utilizado", value: "used", sortable: false },
      { text: "Uso externo", value: "externalUsed", sortable: false },
      { text: "Ações", value: "actions", sortable: false, align: "center" }
    ],
    sheet_fields: {
      "CPF/CNPJ": {
        field: 'client_key',
      },
      Valor: {
        field: 'value',
        callback: (value) => {
          if (value) {
            let real = value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
            return real.substr(3)
          }
          return 0
        }
      },
      Data: {
        field: 'date',
        callback: (value) => {
          var d = new Date(value)
          return d.toLocaleDateString() + " " + d.toLocaleTimeString()
        }
      },
      "EndToEndId": {
        field: 'endToEndId'
      },
      "Deposito": {
        field: 'deposit_id'
      },
      "Saque": {
        field: 'withdrawal_id'
      },
      "Despesa": {
        field: 'expense_id'
      }
    },
    valid: false
  }),
  
  watch: {
    pagination: {
      handler() {
        this.getItems({ resetPage: false })
      },
    },
  },
}
</script>

<style></style>