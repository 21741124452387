import axios from 'axios';
import endPoints from '../../../config/endPoints';
import { getField, updateField } from 'vuex-map-fields';


const defaultFilter = {
    contact_id: '',
    end: '',
    id_agent: '',
    id_instance: '',
    start: '',
    user_id: '',
    type_report: 0,
    origin: '',
    id_transaction: '',
    type_annotated: ''
}

const defaultMessage = {
    message_to_send: '',
    instance_id: null,
    message_type: null,
    dev_mode: false
}

const formatDate = (d) => {
    var day, month, year;
    month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export default {
    namespaced: true,
    state: {
        agents_annotated: [],
        annotated: [],
        contacts: [],
        dialogFilter: false,
        dialogSendMessageModal: false,
        editedItem: {},
        filter: defaultFilter,
        filtered: false,
        loading: false,
        items: [],
        contact_id: '',
        pagination: {
            page: 1,
            itemsPerPage: 10
        },
        totalItems: 0,
        overlay: false
    },
    mutations: {
        showLoading: (state, payload) => {
            state.loading = payload.show
        },
        setContacts: (state, payload) => {
            state.contacts = payload.contacts
        },
        setAnnotated: (state, payload) => {
            state.annotated = payload.annotated
        },
        setFilter: (state, payload) => {
            state.filter = payload.filter
        },
        setFiltered: (state, payload) => {
            state.filtered = payload.filtered
        },
        setAgentsAnnotated: (state, payload) => {
            state.agents_annotated = payload.agents
        },
        setItems: (state, payload) => state.items = payload.items,
        setTotalItems: (state, total) => state.totalItems = total,
        setOverlay: (state, payload) => { 
            state.overlay = payload.overlay
        },
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        showSendMessageModal: (state, payload) => { 
            state.editedItem = Object.assign({}, defaultMessage)
            state.dialogSendMessageModal = payload.show 
        },
        updateField
    },
    getters: {
        getField
    },
    actions: {
        clearFilter: ({ commit }) => {
            commit('setFilter', { filter: Object.assign({}, defaultFilter) })
            commit('setFiltered', { filtered: false })
            commit('showFilter', { show: false, actionFilter: true })
        },
        getContacts({ commit }, payload) {
            if (payload.search) {
                axios.get(endPoints.contact_list + "/search_contacts/" + payload.search).then(response => {
                    var payloadContacts = {
                        contacts: response.data
                    }
                    if (response.data == null) {
                        payloadContacts.contacts = []
                    }
                    commit('setContacts', payloadContacts)
                });
            }
        },
        getAgents({ commit }, payload) {
            if (payload.search) {
                axios.get(endPoints.agents + "/agent_by_name/" + payload.search).then(response => {
                    var payloadAgents = {
                        agents: response.data
                    }
                    if (response.data == null) {
                        payloadAgents.agents = []
                    }
                    commit('setAgentsAnnotated', payloadAgents)
                });
            }
        },
        validateAnnotated: ({ dispatch }, item) => {            
            axios.post(endPoints.annotated + "/validate_annotated", item).then(() => {
                dispatch('getItems', { resetPage: true })
            })
        },
        getItems: ({ commit, state }) => {
            let url = endPoints.annotated
            let filter = state.filter
            url += '?page=' + state.pagination.page + '&' + 'itemsPerPage=' + state.pagination.itemsPerPage;
            let filtered = false
            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] != null) {
                    if (field != "type_report") {
                        filtered = true
                    }

                    if (field == "end" || field == "start") {
                        var e = new Date(filter[field])

                        url += "&" + field + "=" + formatDate(e) + " " + e.toLocaleTimeString()
                    } else if (field === "id_instance") {
                        url += "&" + field + "=" + filter[field].join('|')
                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });

            axios.get(url).then(items => {
                commit('setFiltered', { filtered: filtered })
                commit('setTotalItems', items.data.total)
                commit('showLoading', { show: false })

                commit('setItems', { items: items.data.data })
            });
        },
        getItemsNoPage: ({ state }) => {
            let url = endPoints.annotated;
            let filter = state.filter;
            url += '?page=' + '1' + '&' + 'itemsPerPage=' + '9999999';
        
            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    if (field === "end") {
                        var e = filter.end
                        url += "&" + field + "=" + formatDate(e) + " " + e.toLocaleTimeString()
                    } else if (field === "start") {
                        var s = filter.start
                        url += "&" + field + "=" + formatDate(s) + " " + s.toLocaleTimeString()
                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });
            return axios.get(url).then(items => {
                return items.data.data
            });
        },
        sendManageAnnotatedMessage: ({ commit, state }) => {
            const payload = {
                "message_to_send": state.editedItem.message_to_send,
                "instance_id": state.editedItem.instance_id,
                "message_type": state.editedItem.message_type,
                "dev_mode": state.editedItem.dev_mode
            };
            
            axios.post(endPoints.annotated + "/send_messages", payload).then(() => {
                commit('setOverlay', { overlay: false })|
                commit('showSendMessageModal', { show: false })
            })
        }
    }
}