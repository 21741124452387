<template>
    <div style="display: inline;">
        <div class="chips-chat-list" v-for="am in automatic_messages" :key="am._id">
            <v-chip v-if="am.childrens.length == 0" :color="am.color ? am.color : 'info'" class="ma-1"
                @click="clickInName({ chip: am, origin: origin })">
                {{ am.name }}
            </v-chip>
            <v-menu v-if="am.childrens.length != 0" offset-y top>
                <template v-slot:activator="{ on, attrs }">
                    <v-chip class="ma-1 chip-menu" :color="am.color ? am.color : 'info'" v-bind="attrs" v-on="on">
                        <v-icon class="mr-1">mdi-arrow-up-drop-circle-outline</v-icon>
                        {{ am.name }}
                    </v-chip>
                </template>
                <v-list>
                    <v-list-item v-for="(c, index) in am.childrens" :key="index" @click="clickInName({ chip: c, origin: origin })">
                        <v-list-item-title>{{
                            c.name
                            }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
        <v-menu v-if="flashFilter && origin=='chat'" :close-on-content-click="false" offset-y top content-class="teste">
            <template class="tamanho-bom" v-slot:activator="{ on, attrs }">
                <v-chip class="ma-1 chip-menu" color="info" v-bind="attrs" v-on="on"><v-icon
                        small>mdi-star</v-icon></v-chip>
            </template>
            <template>
                <div class="filter-flash fundo-branco">
                    <v-text-field clearable required label="Pesquisar *" v-model="searchAutomaticMessage"
                        outlined></v-text-field>
                </div>
            </template>
            <template>
                <v-row class="fundo-branco" justify="space-between">
                    <v-card class="alturaMeT" style="overflow-y: auto">
                        <v-col>
                            <template>
                                <v-treeview class="otherthingT alturaMA" :active.sync="active_flash"
                                    :items="automatic_messages" :open.sync="open_flash" activatable color="info"
                                    open-on-click transition item-children="childrens" item-key="_id">
                                    <template v-slot:prepend="{
                                        item,
                                    }">
                                        <v-icon small v-if="
                                            item.childrens
                                                .length < 1
                                        ">
                                            mdi-whatsapp
                                        </v-icon>
                                    </template>
                                    <template v-if="
                                        automatic_messages.length <
                                        1
                                    ">
                                        <p style="
                                                                text-align: center;
                                                                color: gray;
                                                            ">
                                            Não foram econtradas
                                            mensagens
                                            automáticas para sua
                                            pesquisa
                                        </p>
                                    </template>
                                </v-treeview>
                            </template>
                        </v-col>
                    </v-card>
                    <v-divider vertical></v-divider>
                    <v-card class="alturaMeW" style="
                                            overflow-y: auto;
                                            margin-botton: 15px;
                                        ">
                        <v-col class="d-flex text-center box-message-automatic">
                            <v-scroll-y-transition mode="out-in">
                                <div class="message own" v-if="!selected">
                                    <div class="off">
                                        Nenhuma mensagem
                                        selecionada
                                    </div>
                                </div>
                                <div class="message own" v-else>
                                    <v-btn @click="
                                        setContentMessage({
                                            content:
                                                getMessageContent(
                                                    selected.content,
                                                ),
                                        })
                                        " outlined small style="
                                                            margin-right: 13%;
                                                        " color="green">
                                        Preencher
                                    </v-btn>
                                    <v-btn @click="
                                        sendChipMessage({
                                            chip: selected,
                                        })
                                        " outlined small style="
                                                            margin-right: -9%;
                                                        " color="blue">
                                        Enviar
                                    </v-btn>
                                    <div class="wpp">
                                        <div v-html="getMessageContent(
                                            selected.content,
                                        )
                                            "></div>
                                    </div>
                                </div>
                            </v-scroll-y-transition>
                        </v-col>
                    </v-card>
                </v-row>
            </template>
        </v-menu>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mapMutations } from 'vuex/dist/vuex.common.js'
export default {
    props: {
        clickInName: Function,
        origin: String,
    },
    created() {
        this.getAutomaticMessages({ type: 1 })
    },
    data: () => ({
        searchAutomaticMessage: '',
        active_flash: [],
        open_flash: [],

    }),
    watch: {
        searchAutomaticMessage(val) {
            var payload = {
                filter: val,
                type: 1,
            }
            this.getAutomaticMessages(payload)
        },
    },
    computed: {
        ...mapState('automatic_messages', ['automatic_messages']),
        ...mapState('chat', ['flashFilter']),
        am2() {
            return this.automatic_messages.map((a) => (a.id = 2))
        },
        selected() {
            if (!this.active_flash[0]) {
                return undefined
            }

            const id = this.active_flash[0]
            let am = this.automatic_messages.find(
                (automatic_message) => automatic_message._id === id,
            )

            if (am == undefined) {
                let children_am = []
                this.automatic_messages.forEach((element) => {
                    if (element.childrens.length > 0) {
                        element.childrens.forEach((son) => {
                            children_am.push(son)
                        })
                    }
                })
                console.log(children_am)
                am = children_am.find(
                    (automatic_message) => automatic_message._id === id,
                )
            }
            console.log(am)
            return am
        },
    },
    methods: {
        ...mapMutations('chat', ['setContentMessage']),
        getMessageContent(content) {
            if (typeof content == 'string' && content.length > 0) {
                var messageTreated = content
                    .replaceAll('[nome_usuario]', this.getUserName)
                    .replaceAll('[parte_do_dia]', this.getHelloPart)
                return messageTreated
            }

            return ''
        },
        ...mapActions('automatic_messages', ['getAutomaticMessages']),

    },
}
</script>