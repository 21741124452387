import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';
import defaultChipsTransfer from '../../financial/chips_transfer/default_chips_transfer'
import defaultInfo from '../../financial/chips_transfer/default_info'
import defaultDeposit from '../../financial/deposit/default_deposit'

import chips_transfer_template from '../../financial/chips_transfer/chips_transfer_template'
import get_info_template from '../../financial/chips_transfer/get_info_template'

import { getMessageContent } from './automatic_messages_helper';

const all_chips = [{ id: 1, label: 'VIP', status: 0, active_color: 'orange' }, { id: 2, label: 'Limite VIP', status: 0, active_color: 'green' },
{ id: 3, label: 'RB', status: 0, active_color: 'blue' }, { id: 4, label: 'Cuidado', status: 0, active_color: 'red' }, { id: 5, label: 'Limite', status: 0, active_color: 'purple' },
{ id: 6, label: 'Dono de Clube', status: 0, active_color: 'black' }, { id: 7, label: 'Gerente', status: 0, active_color: 'black' }, { id: 8, label: 'Atenção', status: 0, active_color: 'yellow' },
{ id: 9, label: 'Limite 0', status: 0, active_color: 'yellow' }, { id: 10, label: 'S. Anotado', status: 0, active_color: 'grey' }, { id: 11, label: 'Prioritário', status: 0, active_color: 'black' },
]

const defaultClientDetails = {
    displayName: '',
    limit: null,
    deals: [],
}

export default {
    namespaced: true,
    state: {
        automatic_messages: [],
        annotated: {},
        audit_mode: false,
        client_details: defaultClientDetails,
        showClientDetails: false,
        contact_annotated: {
            current_week: 0,
            pending_payment: 0,
            observation: '',
            origin: null,
            changed_value: 0,
            description: '',
            new_annotated: false
        },
        filter: {
            start: '',
            end: '',
            value: '',
            id_agent: ''
        },
        filterTransfer: {
            name_pppoker: '',
            id_pppoker: '',
            id_agent: '',
            balance: '',
            name_supreme: '',
            id_supreme: '',
            chips: '',
            status: '',
            contact: ''
        },
        filterInfo: {
            id_pppoker: '',
        },
        annotated_list: [],
        blockHistoryItems: [],
        attendances: [],
        chats: [],
        chips_contact: [],
        contact_id: '',
        contact_description: '',
        contacts_mode: '',
        editAnnotated: {},
        dialog_audit: false,
        dialogRakeback: false,
        dialogTest: false,
        dialogNoBound: false,
        dialogSupreme: false,
        dialog_annotated: false,
        dialog_block_history: false,
        dialog_contacts: false,
        dialog_player_data: false,
        dialog_recover: false,
        dialoGetInfo: false,
        deposit_rakeback: {},
        transfer_form: {},
        info_form: {},
        chips_transfer: [],
        editedChipsTransfer: {},
        flashFilter: false,
        instances: [],
        instanceFilter: [],
        lastEvent: '',
        loading: true,
        limit: 30,
        messages: [],
        messageText: '',
        messagesByChatId: {},
        modeTransfer: '',
        notificationMessage: '',
        notificationType: "success",
        old_observation: '',
        old_annotated: '',
        openedChat: {},
        pagination_annotated: {
            page: 1,
            itemsPerPage: 10
        },
        reply_message: {},
        selected_messages: [],
        scrollPosition: 0,
        showNotification: false,
        showContactDescription: false,
        inputRakebackAvailable: false,
        rakebackCalculate: {},
        totalItemsAnnotated: 0,
        ws: {},
        rakes: [],
        agents: [],
        rakebacks: [
            { club: null, rake: 0 }
        ],
        showPlayers: false,
        showGroup: false,
        totalRakes: 0,
        totalLimit: 0,
        agentMasterId: 0,
        rakebackAvailable: 0,
        rakebackAcumulate: 0,
        dialogDeposit: false,
        occurrences: [],
        occurrencesSelected: [],
        occurrenceDialog: false,
        possible_origins: [{ id: 4, name: "Correção de Saldo" },
        { id: 5, name: "Ajuste Fechamento" },
        { id: 6, name: "Fechamento Fora do Sistema" },
        { id: 7, name: "Multa Suprema" },
        ],
        payloadFinishAt: {},
        showButtonIgnoreExists: false,
        sendReceipt: false,
        showFormDepositRakeback: false,
        dialogDescriptionFileToSend: false,
        messageToSend: {},
    },
    mutations: {
        setMessageToSend: (state, message) => state.messageToSend = message,
        setImageBase64MessageToSend: (state, base64img) => state.messageToSend.image_base64 = base64img,
        setDialogDescriptionFileToSend: (state, show) => state.dialogDescriptionFileToSend = show,
        setShowClientDetails: (state, show) => state.showClientDetails = show,
        closeDialog: (state) => { state.occurrenceDialog = false },
        openDialog: (state) => { state.occurrenceDialog = true },
        setOccurrences: (state, occurrences) => state.occurrences = occurrences,
        setPayloadFinish: (state, payload) => state.payloadFinishAt = payload,
        addMessage: (state, message) => state.messages.push(message),
        changeLimit: (state, limit) => state.limit = limit,
        closeWebSocketConnection: (state) => state.ws.close(),
        closeDialogSupreme: (state) => {
            state.dialogSupreme = false
            state.editedChipsTransfer = {}
            state.transfer_form = {}
            state.chips_transfer = []
        },
        loading: (state, payload) => state.loading = payload.show,
        setContactAnnotated: (state, payload) => {
            state.old_observation = payload.contact_annotated.observation
            state.contact_annotated = payload.contact_annotated
        },
        showPlayerData: (state, payload) => {
            state.dialog_player_data = payload.show
        },
        setClientDetails: (state, client_details) => state.client_details = client_details,
        setAnnotatedList: (state, payload) => state.annotated_list = payload.annotated,
        setBlockHistoryItems: (state, payload) => state.blockHistoryItems = payload.items,
        setAttendances: (state, attendances) => state.attendances = attendances,
        setEditAnnotated: (state, payload) => state.editAnnotated = payload.chat,
        setContactId: (state, contact_id) => state.contact_id = contact_id,
        setChats: (state, chats) => state.chats = chats,
        setInstances: (state, instances) => state.instances = instances,
        setOpenedChat: (state, openedChat) => state.openedChat = openedChat,
        setMessages: (state, messages) => {
            state.messages = messages
        },
        setMessage: (state, messageText) => state.messageText = messageText,
        setReplyMessage: (state, payload) => state.reply_message = payload.message,
        setScrollPosition: (state, payload) => state.scrollPosition = payload.scrollPosition,
        setLastEvent: (state, payload) => state.lastEvent = payload.type,
        setWs: (state, ws) => state.ws = ws,
        setTotalItemsAnnotated: (state, payload) => {
            state.totalItemsAnnotated = payload.total
        },
        showDialogBlockHistory: (state, payload) => state.dialog_block_history = payload.show,
        showDialogAnnotated: (state, payload) => {
            state.dialog_annotated = payload.show
            if (payload.show == false) {
                state.annotated_list = []
            }
        },
        showDialogAudit: (state, payload) => {
            state.dialog_audit = payload.show
        },
        showDialogRakeback: (state, payload) => {
            state.dialogRakeback = payload.show
            if (state.dialogRakeback == false) {
                state.rakebackAvailable = 0
                state.agentMasterId = 0
                state.totalRakes = 0
            }
        },
        showDialogTest: (state, payload) => {
            state.dialogTest = payload.show
        },
        showDialogNoBound: (state, payload) => {
            state.dialogNoBound = payload.show
        },
        showDialogSupreme: (state, payload) => {
            console.log(state)
            console.log(payload)
            console.log(payload.transfer.name_supreme)
            state.editedChipsTransfer = Object.assign({}, defaultChipsTransfer)
            if (payload.transfer) {
                if (payload.transfer.contact) {
                    state.editedChipsTransfer.contact = payload.transfer.contact
                } else { state.editedChipsTransfer.contact = state.contact_id }
                state.editedChipsTransfer.id = payload.transfer.Id
                state.editedChipsTransfer.balance = payload.transfer.balance
                state.editedChipsTransfer.chips = payload.transfer.chips
                state.editedChipsTransfer.id_agent = payload.transfer.id_agent
                state.editedChipsTransfer.id_pppoker = payload.transfer.id_pppoker
                state.editedChipsTransfer.name_pppoker = payload.transfer.name_pppoker
                state.editedChipsTransfer.name_supreme = payload.transfer.name_supreme
                state.editedChipsTransfer.id_supreme = payload.transfer.id_supreme
                state.editedChipsTransfer.status = 1
                state.modeTransfer = 'NO'
            } else {
                state.editedChipsTransfer.status = 0
                state.editedChipsTransfer.id_pppoker = state.transfer_form.id_pppoker
                state.editedChipsTransfer.name_supreme = state.transfer_form.name_supreme
                state.editedChipsTransfer.id_supreme = payload.transfer.id_supreme
            }
            state.editedChipsTransfer.contact = state.contact_id
            state.dialogSupreme = payload.show
            state.mode = "EDIT"
        },
        showRakebackAvailable: (state, payload) => {
            state.inputRakebackAvailable = payload.show
        },
        setRakes: (state, rakes) => {
            state.rakes = rakes
        },
        setDeposit: (state, deposit) => {
            state.deposit_rakeback = deposit
        },
        setChipsTransferForm: (state, chips_transfer) => {
            state.transfer_form = chips_transfer
        },
        setInfoForm: (state, get_info) => {
            state.info_form = get_info
        },
        setDialogGetInfo: (state) => {
            if (state.dialoGetInfo == true) {
                state.dialoGetInfo = false
                state.info_form = {}
            } else {
                state.dialoGetInfo = true
            }
        },
        setChipsTransfer: (state, chips_transfer) => {
            state.chips_transfer = chips_transfer.transfer
        },
        setTotalRakes: (state, total) => {
            state.totalRakes = total
        },
        setRakebackAvailable: (state, rakeback) => {
            state.rakebackAvailable = rakeback
        },
        setRakeCalculate: (state, payload) => {
            console.log(payload)
            state.rakebackCalculate = payload.rakebackCalculate
        },
        setRakebackAcumulate: (state, rakeback) => {
            state.rakebackAcumulate = rakeback
        },
        setAgentId: (state, idAgent) => {
            state.agentMasterId = idAgent
        },
        setAgents: (state, agents) => {
            state.agents = agents
        },
        setAutomaticMessages: (state, payload) => {
            state.automatic_messages = payload.messages
        },
        setContentMessage: (state, payload) => {
            let content = payload.content
            state.messageText = content
        },
        setFlashFilter: (state) => {
            if (state.flashFilter == true) {
                state.flashFilter = false
            } else {
                state.flashFilter = true
            }
        },
        setTotalLimit: (state, total) => {
            state.totalLimit = total
        },
        setAgentMaster: (state, master) => {
            state.agentMasterId = master
        },
        showDialogContacts: (state, payload) => {
            state.dialog_contacts = payload.show,
                state.contacts_mode = payload.mode
        },
        showDialogRecoverMessages: (state, payload) => {
            state.dialog_recover = payload.show
        },
        showDialogContactDescription: (state, payload) => {
            state.showContactDescription = payload.show
            state.sendReceipt = payload.contact_description.sendReceipt || false;
            if (payload.contact_description) {
                state.contact_description = payload.contact_description.text
            } else {
                state.contact_description = ''
            }
            state.old_annotated = payload.old_annotated
            if (payload.chips_contact) {
                if (payload.chips_contact.length < all_chips.length) {
                    let chips = payload.chips_contact

                    all_chips.forEach((a) => {
                        var c = payload.chips_contact.filter(pc => pc.id == a.id)
                        if (c.length == 0) {
                            chips.push(a)
                        }
                    })

                    state.chips_contact = chips
                } else {
                    state.chips_contact = payload.chips_contact
                }
            } else {
                state.chips_contact = all_chips
            }
        },
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        setShowButtonIgnoreExists: (state, show) => state.showButtonIgnoreExists = show,
        setShowFormDepositRakeback: (state, show) => state.showFormDepositRakeback = show,
        updateField
    },
    getters: {
        getField,
        openedChat: (state) => {
            return state.openedChat
        },
        userOpenChat: (state) => {
            return {
                id: state.openedChat.user_id,
                name: state.openedChat.user_attendance,
            }
        },
        instances: (state) => {
            return state.instances
        },
        getFinishAt: (state) => { return state.payloadFinishAt },
    },
    actions: {
        openDialogSendDocument: ({ commit }, payload) => {
            commit('setMessageToSend', payload.message)
            commit('setDialogDescriptionFileToSend', true)
        },
        closeDialogSendDocument: ({ commit }) => {
            commit('setDialogDescriptionFileToSend', false)
            commit('setMessageToSend', {})
        },
        annotatedAgent: ({ commit, state }) => {
            let url = endPoints.annotated

            url += '?page=' + state.pagination_annotated.page + '&' + 'itemsPerPage=' + state.pagination_annotated.itemsPerPage;

            url += "&contact_id=" + state.openedChat.contact_id + "&type_report=" + 1

            axios.get(url).then(items => {
                var data = items.data.data
                if (data == null) {
                    data = []
                }
                commit('setTotalItemsAnnotated', { total: items.data.total })
                commit('setAnnotatedList', { annotated: data })
                commit('showDialogAnnotated', { show: true })
            });
        },
        changeLimit: ({ commit, state }, payload) => {
            if (state.messages.length >= state.limit) {
                let limit = {
                    limit: state.limit + 30
                }
                state.ws.send(JSON.stringify({ type: "changeLimit", data: limit }))
                commit('changeLimit', limit.limit)
                commit('loading', { show: true })
                if (state.audit_mode == false) {
                    commit('setScrollPosition', { scrollPosition: payload.scrollPosition })
                } else {
                    commit('setScrollPosition', { scrollPosition: 0 })
                }
                commit('setLastEvent', { type: 'changeLimit' })
            }
        },
        updateInstancesFilter: ({ state }) => {
            console.log(state.showPlayers)
            state.ws.send(JSON.stringify({ type: "filterInstances", data: { instances_id: state.instanceFilter, view_contacts: state.showPlayers, view_groups: state.showGroup } }))
        },
        clearContactId: ({ commit, dispatch }) => {
            commit('setOpenedChat', {})
            commit('setContactId', '')
            commit('setMessage', '')
            commit('setMessages', [])
            commit('message/setSelectedMessages', { messages: [] }, { root: true })
            dispatch('menu/openLink', '/chat', { root: true })
            commit('setLastEvent', { type: 'clearContact' })
            commit('setReplyMessage', { message: {} })
        },
        openDialogContactDescription: ({ dispatch }, payload) => {
            dispatch('dialogAnnotated', payload)
            dispatch('getWeeklyAdvanceAndLimitByContactId')
            dispatch('getDetailsRakebackClientSox')
        },
        getDetailsRakebackClientSox: ({ commit, state }) => {
            const url = endPoints.client_details_rakeback + '/' + state.openedChat.contact_id;

            axios.get(url).then((response) => {
                commit('setClientDetails', response.data)
                commit('setShowClientDetails', true)
            }).catch((error) => {
                console.log(error)
                let notification = {
                    show: true,
                    message: "Não foi possivel buscar os detalhes do cliente!",
                    type: "error"
                }
                commit('setClientDetails', Object.assign({}, defaultClientDetails))
                commit('setShowClientDetails', false)
                commit('showNotification', notification)
            })
        },
        dialogAnnotated: ({ commit, state }, payload) => {
            var url = endPoints.annotated + "/contact/" + state.openedChat.contact_id

            axios.get(url).then((response) => {
                let annotated = response.data
                let old_annotated = ''
                annotated.changed_value = 0
                annotated.add_to = 0
                annotated.discount_from = 1
                if (state.openedChat.description.annotated) {
                    old_annotated = state.openedChat.description.annotated
                }
                annotated.description = ''
                annotated.origin = null
                commit('setContactAnnotated', { contact_annotated: annotated })
                commit('showDialogContactDescription', { old_annotated: old_annotated, show: true, contact_description: payload.contact_description })
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status == 404) {
                        let old_annotated = ''
                        commit('setContactAnnotated', { contact_annotated: { add_to: 0, changed_value: 0, current_week: 0, discount_from: 1, observation: '', pending_payment: 0, contact_id: state.openedChat.contact_id, new_annotated: true } })
                        if (state.openedChat.description.annotated) {
                            old_annotated = state.openedChat.description.annotated
                        }
                        commit('showDialogContactDescription', { old_annotated: old_annotated, show: true, contact_description: payload.contact_description })
                    } else {
                        let notification = {
                            show: true,
                            message: "Não foi possivel buscar os anotados!",
                            type: "error"
                        }
                        commit('showNotification', notification)
                    }
                } else {
                    let notification = {
                        show: true,
                        message: "Não foi possivel buscar os anotados!",
                        type: "error"
                    }
                    commit('showNotification', notification)
                }
            })
        },
        forwardReceipt: ({ commit, state, rootGetters }, payload) => {
            let instances = rootGetters["instances/getInstances"]
            let instance = instances.find((i) => {
                return i.id == state.openedChat.instance_id
            })

            if (!instances || !instance.cg_id) {
                let notification = {
                    show: true,
                    message: "Contato do grupo de comprovantes não foi definido!",
                    type: "error"
                }
                commit('showNotification', notification)
                return
            }
            let data = {
                show: true,
                message: payload.message,
                instance_id: instance.id,
                forward_mode: 'RECEIPT',
                forward_contact: [{ _id: instance.cg_id, instance_id: instance.id, name: [instance.cg_name] }]
            }
            commit('message/showDialogForwardMessage', data, { root: true })
            commit('setLastEvent', { type: 'forwardReceipt' })
        },
        getInstances: ({ commit }) => {
            let url = endPoints.instances;
            url += '?page=' + 1 + '&' + 'itemsPerPage=' + 10;
            axios.get(url).then(instances => {
                commit('setInstances', instances.data.data)
            });
        },
        getWeeklyAdvanceAndLimitByContactId: ({ state, dispatch }) => {
            const url = endPoints.annotated + '/weekly_advance_limit/' + state.openedChat.contact_id;
            axios.get(url).then(weeklyAdvanceAndLimit => {
                dispatch('rakeback/setWeeklyAdvanceAndLimit', weeklyAdvanceAndLimit.data, { root: true })
            });
        },
        getDetailsClientByContactId: ({ state, commit, dispatch }) => {
            commit('rakeback/setLoadingRakeback', { show: true }, { root: true })
            const url = endPoints.client_details + '/' + state.openedChat.contact_id;
            axios.get(url).then(response => {
                dispatch('rakeback/setClubsAndAgentsClient', response.data, { root: true })
            });
        },
        getRakesPass: ({ commit, state }) => {
            let url = endPoints.rakeback + '/rakes_week_by_agent/';

            let filter = {};
            filter.status = 2;
            filter.number_agent = state.contact_id;

            url += '?page=' + 1 + '&' + 'rakesPerPage=' + 100;

            Object.keys(filter).forEach(function (field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    url += "&" + field + "=" + filter[field]
                }
            });

            axios.get(url).then(sum => {
                commit('setTotalRakes', sum.data.value)
                commit('setTotalLimit', sum.data.limit_annotated)

            }).then(function () {

                commit('setDeposit', Object.assign({}, defaultDeposit, { type: 0, bank: 22, payment_type: 1 }))
                commit('showDialogRakeback', { show: true })
            })
        },
        getChipsTransfer: ({ commit, state }, payload) => {
            var m = payload.message
            var message = m.content
            var content = m.content
            var chips_transfer = JSON.parse(JSON.stringify(defaultChipsTransfer))

            if (message) {
                var lines = content.split("\n")
                var hasHeader = false
                if (lines[0].toLowerCase().indexOf("formu") != -1) {
                    hasHeader = true
                }

                var index = 0;
                lines = lines.filter((l) => l != "")

                lines.forEach((l, i) => {

                    if (!hasHeader) {
                        index = i + 1
                    } else {
                        index = i
                    }

                    if (chips_transfer_template[index]) {

                        var template = chips_transfer_template[index]
                        var line = l.split(":")
                        var text = (line[1]) ? line[1] : line[0];
                        if (text !== "" && text !== undefined && text !== '' && text != null) {
                            if (text[0] == " ") text = text.replace(" ", "")
                        } else {
                            text = ""
                        }

                        var regex = /^[a-zA-Z0-9-,.!? ]*$/

                        if (!regex.test(text)) {
                            text = ""
                        }
                        chips_transfer[template.field] = text

                    }
                })
            }

            commit('setChipsTransferForm', chips_transfer)

            let url = endPoints.chips_transfer + "/in_chat";
            let filter = Object.assign({}, state.filterTransfer)
            filter.contact = state.contact_id;

            url += '?page=' + 1 + '&' + 'routesPerPage=' + 10 + '&id_pppoker=' + state.transfer_form.id_pppoker;
            commit('setPage', { page: 1, routesPerPage: 10 })

            Object.keys(filter).forEach(function (field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    url += "&" + field + "=" + filter[field]
                }
            });

            axios.get(url).then(transfer => {
                if (transfer.data.data == null) {
                    commit('showDialogTest', { show: true })
                    commit('setChipsTransfer', { transfer: transfer.data.data })
                } else if (transfer.data.data[0].is_bound == "true") {
                    commit('showDialogSupreme', { show: true, transfer: transfer.data.data[0] })
                    commit('setChipsTransfer', { transfer: transfer.data.data })
                } else if (transfer.data.data[0].is_bound == "false") {
                    commit('setChipsTransfer', { transfer: transfer.data.data })
                    commit('showDialogNoBound', { show: true })
                }
            })
        },
        getInfoRegister: ({ commit, state }, payload) => {
            var m = payload.message
            var message = m.content
            var content = m.content
            var get_info = JSON.parse(JSON.stringify(defaultInfo))

            if (message) {
                var lines = content.split("\n")
                var hasHeader = false
                if (lines[0].toLowerCase().indexOf("formu") != -1) {
                    hasHeader = true
                }

                var index = 0;
                lines = lines.filter((l) => l != "")

                lines.forEach((l, i) => {

                    if (!hasHeader) {
                        index = i + 1
                    } else {
                        index = i
                    }

                    if (get_info_template[index]) {

                        var template = get_info_template[index]
                        var line = l.split(":")
                        var text = (line[1]) ? line[1] : line[0];
                        if (text !== "" && text !== undefined && text !== '' && text != null) {
                            if (text[0] == " ") text = text.replace(" ", "")
                        } else {
                            text = ""
                        }

                        var regex = /^[a-zA-Z0-9-,.!? ]*$/

                        if (!regex.test(text)) {
                            text = ""
                        }
                        get_info[template.field] = text

                    }
                })
            }

            commit('setInfoForm', get_info)

            let url = endPoints.chips_transfer + "?id_pppoker=" + state.info_form.id_pppoker;

            axios.get(url).then(infos => {
                console.log(infos.data.data)
                commit("setDialogGetInfo")
            })
        },
        saveChipsTransferChat: async ({ dispatch, commit, state }) => {
            var url = endPoints.chips_transfer;
            let func = '';
            let data = Object.assign({}, state.editedChipsTransfer)
            data.chips = parseFloat(data.chips)
            data.balance = parseFloat(data.balance)

            state.chips_transfer.forEach((only) => {
                only.chips = parseInt(only.chips)
                only.club_destiny = parseInt(only.club_destiny)
                only.name_supreme = data.name_supreme
                only.id_supreme = data.id_supreme
            })

            if (!data.id) {
                func = axios.post;
                url = endPoints.chips_transfer;
            } else {
                func = axios.put;
                url += '/updateChipsTransfers';
            }

            let tranfers = state.chips_transfer
            console.log(tranfers)

            func(url, tranfers).then(
                () => {
                    dispatch('getChipsTransfers', { resetPage: true })
                    let notification = {
                        show: true,
                        message: "Trasnsferência salva com sucesso",
                        type: "success"
                    }
                    commit('loading', { show: false })
                    commit('showOverlay', { show: false })
                    if (state.screen != 'messages') {
                        commit('showNotification', notification)
                        commit('closeDialogSupreme')
                        commit('showOverlay', { show: false })
                    } else {
                        commit('closeDialogSupreme')
                        commit('message/showDialogFail', { show: false }, { root: true })
                        commit('message/showNotification', notification, { root: true })
                    }
                },
                error => {
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                    commit('showOverlay', { show: false })
                }
            );
        },
        closeDialogRakeback: ({ commit }) => {
            commit('showDialogRakeback', { show: false })
            commit('setShowFormDepositRakeback', false)
            commit('showRakebackAvailable', { show: false })
            commit('setDeposit', Object.assign({}, defaultDeposit))
            commit('rakeback/clearRakebacks', {}, { root: true })

        },
        calcRakebackSox: async ({ commit, state, rootGetters }, payload) => {
            console.log(payload.rakebacks)

            let rakesFor = []
            payload.rakebacks.forEach(rake => {
                console.log(rake)
                let rakeFor = {}
                rakeFor.club = parseInt(rake.club)
                rakeFor.league = parseInt(rake.league)
                rakeFor.rake = parseFloat(rake.rake)
                rakesFor.push(rakeFor)
            })

            let data = {
                contact_id: state.contact_id,
                rakes: rakesFor

            }


            var url = endPoints.rakeback + '/calc_rake_sox';
            let func = axios.post;
            const weeklyAdvance = rootGetters["rakeback/weeklyAdvance"]
            func(url, data).then(
                (rakeSave) => {
                    commit('setRakebackAvailable', (rakeSave.data.valueTotal - weeklyAdvance).toFixed(2))
                    commit('setRakeCalculate', { rakebackCalculate: rakeSave.data })
                    commit('setShowFormDepositRakeback', true)
                    commit('showRakebackAvailable', { show: true })
                },
            ).catch((error) => {
                let notification = {
                    show: true,
                    message: error.response.data.message,
                    type: "error"
                }
                commit('showNotification', notification)
            });
        },
        createNewDepositRake({ state, commit }, payload) {
            let rakesFor = []
            payload.rakebacks.forEach(rake => {
                console.log(rake)
                let rakeFor = {}
                rakeFor.club = parseInt(rake.club)
                rakeFor.league = parseInt(rake.league)
                rakeFor.rake = parseFloat(rake.rake)
                rakesFor.push(rakeFor)
            })

            let data = {
                contact_id: state.contact_id,
                value: parseFloat(state.rakebackAvailable),
                id_pppoker: state.deposit_rakeback.id_pppoker,
                id_instance: state.deposit_rakeback.club,
                rakes: rakesFor,
            }

            console.log(data)
            var url = endPoints.deposits + '/rakeback';
            axios.post(url, data).then(
                () => {
                    commit('message/setSelectedMessages', { messages: [] }, { root: true })
                    // dispatch('deposit/save', { origin: "RAKEDEPOSIT", deposit: state.deposit_rakeback }, { root: true })
                    let notification = {
                        show: true,
                        message: "Adiantamento de Rakeback solicitado com sucesso",
                        type: "success"
                    }
                    commit('showDialogRakeback', { show: false })
                    commit('showNotification', notification)
                }
            )
            error => {
                let notification = {
                    show: true,
                    message: error.response.data.message,
                    type: "error"
                }
                commit('showNotification', notification)
                commit('showDialogRakeback', { show: false })
            }
        },
        onEnterPressed({ commit, dispatch, state }, event) {

            if (!event.ctrlKey && !event.shiftKey) {
                event.preventDefault()
                dispatch('onSendMessage', true)
            } else {
                commit('setMessage', state.messageText + '\n')
            }
        },
        onSendDocument({ rootGetters, state, dispatch }, payload) {
            console.log(payload)
            var file = payload.files[0]

            var type = file.type

            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });

            toBase64(file).then((fileBase64) => {
                let message = {
                    user_id: rootGetters["login/getUserId"],
                    contact_id: state.contact_id,
                    instance_id: state.openedChat.instance_id,
                    content: '',
                    is_group: state.openedChat.is_group,
                    type: 0
                }
                if (type.indexOf("image") != -1) {
                    message.image_base64 = fileBase64
                } else if (type.indexOf("application") != -1) {
                    message.document_mimetype = type
                    message.document_name = file.name
                    message.document_thumb = fileBase64
                }

                if (state.reply_message._id) {
                    message.reference_id = state.reply_message.message_id
                    message.references = [state.reply_message]
                } else {
                    message.reference_id = ""
                    message.references = []
                }
                dispatch('openDialogSendDocument', { message: message })
                // commit('setReplyMessage', { message: {} })

            })
        },
        openPlayerData({ commit, state }) {
            var url = endPoints.players + "/player_data/" + state.openedChat.contact_id
            axios.get(url).then((response) => {
                commit('showPlayerData', { show: true })
                commit('player/setPlayerIdsChat', { player_ids_chat: response.data.player_ids }, { root: true })
                commit('player/setPlayerAccountsChat', { player_accounts: response.data.player_accounts }, { root: true })
                commit('player/setPlayerChat', { player: response.data.player }, { root: true })
            })
        },
        onSendContact({ commit, dispatch, rootGetters, state }, payload) {

            let message = {
                user_id: rootGetters["login/getUserId"],
                contact_id: state.contact_id,
                send_contact_id: payload.contact._id,
                send_contact_name: payload.contact.name[0],
                instance_id: state.openedChat.instance_id,
                content: '',
                is_group: state.openedChat.is_group,
                type: 0
            }

            if (state.reply_message._id) {
                message.reference_id = state.reply_message.message_id
                message.references = [state.reply_message]
            } else {
                message.reference_id = ""
                message.references = []
            }

            commit('setReplyMessage', { message: {} })

            dispatch('sendMessage', message)
            commit('showDialogContacts', { show: false, mode: '' })

        },
        onSendMessage({ dispatch, commit, rootGetters, state }, removeBreakLine = false) {
            let text = state.messageText || state.messageToSend.content || ''
            let content = ""
            if (text){
                if (removeBreakLine) {
                    content = text.substr(0, (text.length - 1))
                } else {
                    content = text
                }
            }

            let message = {
                user_id: rootGetters["login/getUserId"],
                contact_id: state.contact_id,
                instance_id: state.openedChat.instance_id,
                content: content,
                is_group: state.openedChat.is_group,
                reference_id: "",
                type: 0,
            }

            if (state.messageToSend.image_base64) {
                message.image_base64 = state.messageToSend.image_base64
            }

            if (state.messageToSend.document_thumb) {
                message.document_thumb = state.messageToSend.document_thumb
                message.document_name = state.messageToSend.document_name
                message.document_mimetype = state.messageToSend.document_mimetype
            }

            if (state.reply_message._id) {
                message.reference_id = state.reply_message.message_id
                message.references = [state.reply_message]
            } else {
                message.reference_id = ""
                message.references = []
            }
            console.log(message)
            commit('setReplyMessage', { message: {} })
            dispatch('sendMessage', message)
            dispatch('closeDialogSendDocument')
        },
        openChat({ commit, dispatch, state }, chat) {
            if (state.openedChat.contact_id != chat.contact_id) {
                state.messagesByChatId[state.openedChat.contact_id] = state.messageText
                let message = state.messagesByChatId[chat.contact_id] || ''
                commit('loading', { show: true })
                commit('setOpenedChat', chat)
                commit('setContactId', chat.contact_id)
                commit('setMessage', message)
                commit('setMessages', [])

                commit('message/setSelectedMessages', { messages: [] }, { root: true })

                dispatch('menu/openLink', '/chat/' + chat.contact_id, { root: true })

                commit('setLastEvent', { type: 'openChat' })
                commit('setReplyMessage', { message: {} })
                commit('changeLimit', 30)
            }
        },
        recoverMessages({ commit, state }, payload) {
            var url = endPoints.httpchats + "/recover_messages"
            var data = {
                contact_id: state.openedChat.contact_id,
                instance_id: state.openedChat.instance_id,
                quantity: payload.quantity,
                deleteLastQuantityMessages: payload.deleteLastQuantityMessages
            }
            commit('loading', { show: true })
            commit('showDialogRecoverMessages', { show: false })
            axios.post(url, data).then(() => {
                let notification = {
                    show: true,
                    message: "Mensagens sincronizadas com sucesso!",
                    type: "success"
                }
                commit('showNotification', notification)
                commit('loading', { show: false })
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Ocorreu um erro ao recuperar as mensagens!",
                    type: "error"
                }
                commit('showNotification', notification)
                commit('loading', { show: false })
            })
        },
        generateHash: async ({ commit }, payload) => {
            console.log(payload)
            var url = endPoints.httpchats + "/hash/" + payload
            let novoHash;
            await axios.put(url).then((response) => {
                novoHash = response.data
            }).catch((error) => {
                console.log(error)
                commit('showNotification', { show: true, message: "Ocorreu um erro ao gerar o hash!", type: "error" })
            })
            return novoHash
        },
        sendAnnotated({ commit, dispatch, rootGetters, state }) {
            let content = "*💰RELAÇÃO DE VALORES💰*"
            var url = endPoints.annotated + "/contact/" + state.openedChat.contact_id

            axios.get(url).then((response) => {
                const annotated = response.data

                let now = new Date()
                let today = now.getDay()

                // let last_monday = new Date()
                let last_sunday = new Date()
                let current_monday = new Date()
                let current_sunday = new Date()

                if (today == 0) {
                    // last_monday.setDate(now.getDate() + (today - 13))
                    last_sunday.setDate(now.getDate() + (today - 7))

                    current_monday.setDate(now.getDate() + (today - 6))
                } else {
                    // last_monday.setDate(now.getDate() + (-7 - (today - 1)))
                    last_sunday.setDate(now.getDate() - (today))

                    current_monday.setDate(now.getDate() + (-today + 1))
                    current_sunday.setDate(now.getDate() + (7 - today))
                }

                // last_monday = (last_monday.getDate() < 10 ? '0' + last_monday.getDate() : last_monday.getDate()) + '/' + ((last_monday.getMonth() + 1) < 10 ? '0' + (last_monday.getMonth() + 1) : (last_monday.getMonth() + 1)) + '/' + last_monday.getFullYear()
                last_sunday = (last_sunday.getDate() < 10 ? '0' + last_sunday.getDate() : last_sunday.getDate()) + '/' + ((last_sunday.getMonth() + 1) < 10 ? '0' + (last_sunday.getMonth() + 1) : (last_sunday.getMonth() + 1)) + '/' + last_sunday.getFullYear()
                current_monday = (current_monday.getDate() < 10 ? '0' + current_monday.getDate() : current_monday.getDate()) + '/' + ((current_monday.getMonth() + 1) < 10 ? '0' + (current_monday.getMonth() + 1) : (current_monday.getMonth() + 1)) + '/' + current_monday.getFullYear()
                current_sunday = (current_sunday.getDate() < 10 ? '0' + current_sunday.getDate() : current_sunday.getDate()) + '/' + ((current_sunday.getMonth() + 1) < 10 ? '0' + (current_sunday.getMonth() + 1) : (current_sunday.getMonth() + 1)) + '/' + current_sunday.getFullYear()

                content += "\n\nOlá! Você tem um valor total de anotados de *" + (annotated.current_week + annotated.pending_payment).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) + "*."
                content += " Desse valor, *" + annotated.pending_payment.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) + "* são referentes *aos fechamentos anteriores a " + last_sunday + "* e *"
                content += annotated.current_week.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) + "* são referentes a essa semana *(" + current_monday + " a " + current_sunday + ")*."
                content += "\n\nAtenciosamente, *Suporte SX.*"

                let message = {
                    user_id: rootGetters["login/getUserId"],
                    contact_id: state.contact_id,
                    instance_id: state.openedChat.instance_id,
                    content: content,
                    is_group: state.openedChat.is_group,
                    type: 0
                }

                dispatch('sendMessage', message)
            }).catch((error) => {
                let notification = {
                    show: true,
                    type: "error"
                }
                if (error.response) {
                    if (error.response.status == 404) {
                        notification.message = "Anotados não encontrado!"
                    } else {
                        notification.message = "Ocorreu um erro ao buscar os anotados!"
                    }
                } else {
                    notification.message = "Ocorreu um erro ao buscar os anotados!"
                }
                commit('showNotification', notification)
            })
        },
        showAutomaticMessages({ commit }) {
            let url = endPoints.automatic_message;

            axios.get(url).then(messages => {
                let at_messages = []
                if (messages.data != null) {
                    at_messages = messages.data
                }
                commit('setAutomaticMessages', { messages: at_messages })
                commit('loading', { show: false })
            });
        },
        editContactDescription({ commit }, payload) {
            var url = endPoints.httpchats + "/chat/" + payload.item.ag_group_id
            axios.get(url).then((response) => {
                if (response.data) {
                    let payload = {
                        show: true,
                        contact_description: response.data.description.text,
                        annotated: response.data.description.annotated,
                        chips_contact: response.data.description.chips
                    };
                    commit('setEditAnnotated', { chat: response.data })
                    commit('showDialogContactDescription', payload)
                }
            })
        },
        readFromWebSocket({ commit }, data) {

            commit('loading', { show: false })
            if (data.chats) {
                if (data.chats_changed == true) {
                    commit('setChats', data.chats)
                }
            } else {
                if (data.chats_changed == true) {
                    commit('setChats', [])
                }
            }

            if (data.messages) {
                if (data.messages_changed == true) {
                    commit('setMessages', data.messages.reverse())
                }
            } else {
                commit('setMessages', [])
            }

        },
        sendAgentInfo({ state, rootGetters, dispatch }, payload) {

            let content = '🌟Olá. Segue abaixo os dados do seu *Novo Login de Agente na Suprema* 🌟' +
                '\n\n' +
                '*ID Suprema:* ' + payload.item.id_supreme +
                '\n*Nome Suprema:* ' + payload.item.name_supreme +
                '\n*Senha Suprema:* ' + payload.item.newagent_password

            var messageChip = {
                user_id: rootGetters["login/getUserId"],
                contact_id: state.contact_id,
                instance_id: state.openedChat.instance_id,
                content: content,
                image_base64: "",
                is_group: state.openedChat.is_group,
                type: 0
            }
            dispatch('sendMessage', messageChip)
        },
        sendPlayerInfo({ state, rootGetters, dispatch }, payload) {
            let content = ""
            if (payload.item.newagent_id) {
                content = '🌟Olá. Segue abaixo o novo *ID do seu Agente Na Suprema* 🌟' +
                    '\n\n' +
                    '*ID do Agente:* ' + payload.item.newagent_id


                var messageChip = {
                    user_id: rootGetters["login/getUserId"],
                    contact_id: state.contact_id,
                    instance_id: state.openedChat.instance_id,
                    content: content,
                    image_base64: "",
                    is_group: state.openedChat.is_group,
                    type: 0
                }
                dispatch('sendMessage', messageChip)
            }
        },
        fillMessageByAutomaticMessage({ state,commit, rootGetters }, payload) {
            const text = getMessageContent(rootGetters["login/getUserName"], payload.chip.content)
            if (payload.chip.image && payload.chip.image != "" && !state.dialogDescriptionFileToSend) {
                commit('setDialogDescriptionFileToSend', true)
                state.messageToSend.image_base64 = payload.chip.image
            }
            if (payload.origin == 'chat') {
                state.messageText = text
            } else if (payload.origin == 'message_send') {
                state.messageToSend.content = text
            }

        },

        sendChipMessage({ dispatch, rootGetters, state }, payload) {
            console.log(payload)
            var messageChip = {
                user_id: rootGetters["login/getUserId"],
                contact_id: state.contact_id,
                instance_id: state.openedChat.instance_id,
                content: getMessageContent(rootGetters["login/getUserName"], payload.chip.content),
                image_base64: payload.chip.image,
                is_group: state.openedChat.is_group,
                type: 0
            }
            dispatch('sendMessage', messageChip)
            if (payload.chip.contact) {
                let messageContact = {
                    user_id: rootGetters["login/getUserId"],
                    contact_id: state.contact_id,
                    send_contact_id: payload.chip.contact.contact_id,
                    send_contact_name: payload.chip.contact.name,
                    instance_id: state.openedChat.instance_id,
                    content: '',
                    is_group: state.openedChat.is_group,
                    type: 0
                }
                dispatch('sendMessage', messageContact)
            }
        },
        setChatUnreaded(_, chat) {
            chat.readed = !chat.readed
            axios.post(endPoints.httpchats + "/unreaded", chat);
        },
        startChat({ commit, dispatch }, payload) {
            var data = payload.contact
            data.instance_id = payload.instance_id
            axios.post(endPoints.httpchats + "/start_chat", data).then((response) => {
                if (response.data) {
                    var chat = response.data
                    commit('showDialogContacts', { show: false, mode: '' })
                    dispatch('openChat', chat)
                    commit('setLastEvent', { type: 'startChat' })
                }
            }).catch((error) => {
                let notification = {
                    show: true,
                    message: error.response.data.message,
                    type: "error"
                }
                commit('showNotification', notification)
                commit('showDialogContacts', { show: false, mode: '' })
            })
        },
        sendMessage({ commit, state }, message) {
            console.log(message)
            state.ws.send(JSON.stringify({ type: "sendMessage", data: message }))
            commit('setMessage', '')
            commit('setReplyMessage', { message: {} })
            commit('setLastEvent', { type: 'sendMessage' })
        },

        getOccurrences: ({ commit }) => {
            commit('loading', true)
            let url = endPoints.httpchats + "/occurrences"
            axios.get(url).then(occurrences => {
                commit('loading', false)
                commit('setOccurrences', occurrences.data.data)
                commit("openDialog")
            }).catch(() => {

                commit('loading', false)
                commit('setOccurrences', [])
                commit("openDialog")
            })
        },
        saveOccurrences: ({ commit, state }) => {
            let url = endPoints.httpchats + "/occurrences"
            let data = { occurrences: state.occurrencesSelected, attendance: state.openedChat.attendance_id }
            console.log(data)
            console.log("!opa")
            if (state.occurrencesSelected) {
                axios.post(url, data).then(() => {
                    let notification = {
                        show: true,
                        message: "Atendimento finalizado com sucesso!",
                        type: "success"
                    }
                    commit('showNotification', notification)
                }).catch(error => {
                    console.log(error.response.data.message)
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                })
            }
        },

        finishAttendance({ commit, dispatch, state }, payload) {
            if (payload.type == "message") {
                state.ws.send(JSON.stringify({ type: "finishAttendance", data: { message: payload.message } }))
                let notification = {
                    show: true,
                    message: "Atendimento Finalizado.",
                    type: "success"
                }
                commit('message/setSelectedMessages', { messages: [] }, { root: true })
                commit('showNotification', notification)
            } else {
                state.ws.send(JSON.stringify({ type: "finishAttendance", data: { chat: state.openedChat } }))
                commit('setContactId', '')
                commit('setMessage', '')
                commit('setMessages', [])
                commit('setOpenedChat', {})
                commit('message/setSelectedMessages', { messages: [] }, { root: true })
                dispatch('menu/openLink', '/chat', { root: true })
            }
            commit('setLastEvent', { type: 'finishAt' })
            commit('closeDialog')
        },

        finishChatGroup({ commit, dispatch, state }) {
            state.ws.send(JSON.stringify({ type: "finishChatGroup", data: { chat: state.openedChat } }))
            commit('setContactId', '')
            commit('setMessage', '')
            commit('setMessages', [])
            commit('setOpenedChat', {})
            commit('message/setSelectedMessages', { messages: [] }, { root: true })
            dispatch('menu/openLink', '/chat', { root: true })
            commit('setLastEvent', { type: 'finishChat' })
        },
        saveDescriptionChange({ commit, state }, payload) {
            let chat = {};
            let wait = payload.screen === 'agent';

            chat = wait ? state.editAnnotated : state.openedChat;

            chat.description = {
                text: state.contact_description,
                annotated: state.old_annotated,
                chips: state.chips_contact,
                sendReceipt: state.sendReceipt
            };

            axios.put(endPoints.httpchats + "/description?wait=" + wait, chat)
                .then(() => {
                    commit('showNotification', { show: true, message: "Descrição Atualizada com sucesso!", type: "success" })
                    commit('showDialogContactDescription', { show: false, description: '', contact_description: '' });
                    commit('setShowButtonIgnoreExists', false);
                })
                .catch(error => {
                    commit('showNotification', { show: true, message: error, type: "error" });
                });


            commit('setLastEvent', { type: 'saveDescription' });
        },

        saveAnnotatedChange({ commit, state }, payload) {
            if (!payload.check_exists) {
                state.contact_annotated.check_exists = payload.check_exists;
            }
            if (state.contact_annotated.changed_value && state.contact_annotated.changed_value != 0 || (
                state.contact_annotated.observation != state.old_observation
            )) {
                state.contact_annotated.changed_value = parseFloat(state.contact_annotated.changed_value);
                state.contact_annotated.origin = parseFloat(state.contact_annotated.origin);
                state.contact_annotated.instance_id = state.openedChat.instance_id;
                state.contact_annotated.contact_name = state.openedChat.contact_name.replace("⭐", "");

                axios.post(endPoints.annotated, state.contact_annotated)
                    .then(() => {
                        commit('setContactAnnotated', {
                            contact_annotated: {
                                changed_value: 0, current_week: 0, observation: '', pending_payment: 0,
                                contact_id: state.openedChat.contact_id, new_annotated: true
                            }
                        });
                        commit('showNotification', { show: true, message: "Anotados Atualizado com sucesso!", type: "success" });
                        commit('showDialogContactDescription', { show: false, description: '' });
                        commit('setShowButtonIgnoreExists', false);
                    })
                    .catch(error => {
                        console.error(error.response.data.message);
                        if (error.response.data.message === "Já existe um anotado igual criado nos últimos 5 minutos. Verifique e confirme a operação") {
                            commit('setShowButtonIgnoreExists', true);
                        }
                        commit('showNotification', { show: true, message: error.response.data.message, type: "error" });
                    });
            }
        },
        saveContactDescriptionChange({ commit, state }, payload) {
            let chat = {}
            let wait = false
            if (payload.screen == 'agent') {
                chat = state.editAnnotated
                wait = true
            } else {
                chat = state.openedChat
                wait = false
            }

            if (payload.check_exists == false) {
                state.contact_annotated.check_exists = payload.check_exists
            }

            chat.description = {
                text: state.contact_description,
                annotated: state.old_annotated,
                chips: state.chips_contact,
                sendReceipt: state.sendReceipt
            }

            axios.put(endPoints.httpchats + "/description?wait=" + wait, chat).then(() => {
                // let notification = {
                //     show: true,
                //     message: "Descrição Atualizada com sucesso!",
                //     type: "success"
                // }
                // commit('showNotification', notification)
            }).catch((error) => {
                let notification = {
                    show: true,
                    message: error,
                    type: "error"
                }
                commit('showNotification', notification)
            });
            if (state.contact_annotated.changed_value && state.contact_annotated.changed_value != 0 || (
                state.contact_annotated.observation != state.old_observation
            )) {
                state.contact_annotated.changed_value = parseFloat(state.contact_annotated.changed_value)
                state.contact_annotated.origin = parseFloat(state.contact_annotated.origin)
                state.contact_annotated.instance_id = state.openedChat.instance_id
                state.contact_annotated.contact_name = state.openedChat.contact_name.replace("⭐", "")

                axios.post(endPoints.annotated, state.contact_annotated).then(() => {
                    let notification = {
                        show: true,
                        message: "Anotados Atualizado com sucesso!",
                        type: "success"
                    }
                    commit('setContactAnnotated', { contact_annotated: { changed_value: 0, current_week: 0, observation: '', pending_payment: 0, contact_id: state.openedChat.contact_id, new_annotated: true } })
                    commit('showNotification', notification)
                    commit('showDialogContactDescription', { show: false, description: '' })
                    commit('setShowButtonIgnoreExists', false)
                }).catch(error => {
                    console.log(error.response.data.message)
                    if (error.response.data.message == "Já existe um anotado igual criado nos últimos 5 minutos. Verifique e confirme a operação") {
                        commit('setShowButtonIgnoreExists', true)
                    }
                    let notification = {
                        show: true,
                        message: error.response.data.message,
                        type: "error"
                    }
                    commit('showNotification', notification)
                })
            } else {
                commit('showDialogContactDescription', { show: false, description: '' })
            }
            commit('setLastEvent', { type: 'saveDescription' })
        },
        setConnectionByContactId({ commit, state }, contact_id) {
            if (contact_id) {
                state.ws.send(JSON.stringify({ type: "changeChat", data: { contact_id: contact_id, instance_id: state.openedChat.instance_id, is_group: state.openedChat.is_group } }))
            } else {
                commit('setContactId', '')
                commit('setMessage', '')
                commit('setMessages', [])
                commit('setOpenedChat', {})
                commit('message/setSelectedMessages', { messages: [] }, { root: true })
            }
            commit('setLastEvent', { type: 'changeContactId' })
        },
        startAttendance({ commit, state }, message) {
            if (message) {
                let payload = {
                    show: true,
                    message: "Atendimento Iniciado",
                    type: "success"
                }
                commit('showNotification', payload)
                state.ws.send(JSON.stringify({ type: "startAttendance", data: message }))
                commit('setLastEvent', { type: 'startAt' })
            }
        },
        startWebSocketConnection({ commit, dispatch, rootGetters, state }) {

            let accessToken = rootGetters["login/getAccessToken"]
            if (!state.ws || state.ws.readyState != 1) {
                commit('setChats', [])
                commit('setMessages', [])
                var ws = new WebSocket(endPoints.wschats + "/" + accessToken)
                ws.onmessage = (msgevent) => {
                    dispatch('readFromWebSocket', JSON.parse(msgevent.data))
                }
                ws.onopen = () => {
                    let payload = {
                        show: true,
                        message: "Conexão com o chat iniciada",
                        type: "success"
                    }
                    commit('showNotification', payload)
                    if (state.contact_id) {
                        state.ws.send(JSON.stringify({ type: "changeChat", data: { contact_id: state.contact_id, instance_id: state.openedChat.instance_id, is_group: state.openedChat.is_group } }))
                    }
                }
                ws.onclose = (event) => {
                    console.log(event)
                    let payload = {
                        show: true,
                        message: "A conexão com o chat foi encerrada. Recarregue a página.",
                        type: "error"
                    }
                    commit('showNotification', payload)
                    // commit('setContactId', '')
                    // commit('setOpenedChat', {})
                    commit('message/setSelectedMessages', { messages: [] }, { root: true })
                    dispatch('startWebSocketConnection')
                }
                commit('setWs', ws)
                commit('setLastEvent', { type: 'startConnection' })
            }
        },
        setLimitByMessagesPeriod({ commit, state }) {
            let start = state.dateAudit.getTime()
            let finish = new Date().getTime()
            if (state.audit_mode == true) {
                let url = endPoints.httpchats + '/attendances/messages_audit?contact_id=' + state.openedChat.contact_id + '&start=' + start + '&end=' + finish;

                axios.get(url).then(attendances => {
                    var data = attendances.data
                    if (data == null) {
                        data = 0
                    }
                    let total = data.total
                    console.log("Total", total)
                    if (total > 0) {
                        commit('setLastEvent', { type: 'changeLimit' })
                        commit('showDialogAudit', { show: false })
                        commit('setScrollPosition', { scrollPosition: 0 })
                        state.ws.send(JSON.stringify({ type: "changeLimit", data: { limit: total } }))
                    } else {
                        let notification = {
                            show: true,
                            message: "Nenhuma mensagem encontrada no periodo!",
                            type: "error"
                        }
                        commit('showNotification', notification)
                    }
                }, () => {
                    let notification = {
                        show: true,
                        message: "Ocorreu um erro ao alterar o limite por data!",
                        type: "error"
                    }
                    commit('overlay', { show: false })
                    commit('showNotification', notification)
                });
            }
        },
        toggleBlockAnnotated({ commit }, chat) {
            let url = endPoints.httpchats + "/blockAnnotated"
            chat.block_annotated = !chat.block_annotated
            const blockAnnotated = chat.block_annotated

            axios.post(url, chat).then(() => {
                let notification = {
                    show: true,
                    message: `Anotados ${blockAnnotated ? '' : 'des'}bloqueados com sucesso!`,
                    type: "success"
                }
                commit('showNotification', notification)
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Ocorreu um erro ao bloquear os anotados!",
                    type: "error"
                }
                commit('showNotification', notification)
            })
        },
        openBlockAnnotatedDialog({ commit }, payload) {
            const url = endPoints.httpchats + "/annotated_blocks/" + payload.contact_id

            axios.get(url).then((response) => {
                commit('showDialogBlockHistory', { show: true })
                commit("setBlockHistoryItems", { items: response.data.data })
            }).catch(() => {
                let notification = {
                    show: true,
                    message: "Ocorreu um erro ao buscar o histórico de bloqueio dos anotados",
                    type: "error"
                }
                commit('showDialogBlockHistory', { show: false })
                commit('showNotification', notification)
            })
        }
    }
}