<template>
    <div>
        <v-data-table
            :items="items"
            item-key="id"
            :headers="headers"
            class="table-margin"
            group-by="payment_option"
            :loading="loading"
            hide-default-footer
            v-if="!loading"
        >
            <template v-slot:group.header="{ items, isOpen, toggle }">
                <td colspan="2" class="text-start">
                    <v-btn icon @click="toggle">
                        <v-icon>{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                    </v-btn>
                    <strong>{{ items[0].payment_option }} </strong> 
                </td>
            </template>
            <template v-slot:[`item.balance`]="{ item }">
                <span>{{
                    item.balance ? item.balance.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    }) : "-"
                }}</span>
            </template>
            <template v-slot:[`item.is_active`]="{ item }">
                <v-chip
                    :color="item.is_active ? 'green' : 'red'"
                    dark
                >
                    {{ item.is_active ? 'Ativo' : 'Inativo' }}
                </v-chip>
            </template>
            <template #group.summary="{ items }">
                <th class="totals">SubTotal {{ items[0].payment_option }}:</th>
                <th class="totals text-right">{{ items.reduce((soma, item) => soma += item.balance, 0).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    }) }}</th>
            </template>
            <template v-slot:footer>
                <div class="v-data-table__wrapper">
                    <table class="v-data-table v-data-table--dense">
                        <tbody>
                            <tr>
                                <v-skeleton-loader v-if="hideTotalTypeBalances" type="list-item"></v-skeleton-loader>
                                <td v-if="!hideTotalTypeBalances">
                                    <strong>
                                        Total Ativos: 
                                    </strong>
                                </td>
                                <td class="text-right" v-if="!hideTotalTypeBalances ">
                                    <strong>
                                        {{ ' ' + totalTypeBalances.toLocaleString('pt-BR', {
                                                style: 'currency',
                                                currency: 'BRL'
                                            }) 
                                        }}
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <v-skeleton-loader v-if="hideTotalBalances" type="list-item"></v-skeleton-loader>
                                <td v-if="!hideTotalBalances">
                                    <strong>
                                        Total Geral: 
                                    </strong>
                                </td>
                                <td class="text-right" v-if="!hideTotalBalances">
                                    <strong>
                                        {{ ' ' + totalBalances.toLocaleString('pt-BR', {
                                                style: 'currency',
                                                currency: 'BRL'
                                            }) 
                                        }}
                                    </strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template> 
        </v-data-table>   
        <v-skeleton-loader v-if="loading" type="table"></v-skeleton-loader>
    </div>
   
</template>

<script>

export default {
    data() {
        return {
            headers: [
                { text: 'Meio de Pagamento', value: 'payment_option' },
                { text: 'Banco', value: 'sub_account_name' },
                { text: 'Saldo', value: 'balance', align: 'right' },
            ],
        }
    },
    props: {
        items: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            required: true,
            default: false
        },
        hideTotalTypeBalances: {
            type: Boolean,
            required: true,
            default: false
        },
        hideTotalBalances: {
            type: Boolean,
            required: true,
            default: false
        },
        totalTypeBalances: {
            type: Number,
            required: true,
            default: 0
        },
        totalBalances: {
            type: Number,
            required: true,
            default: 0
        }
    }
}
</script>