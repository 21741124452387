<template>
    <v-card class="contact-card-description">
        <v-card-text>
            <v-container grid-list-md>
                <v-layout wrap>
                    <v-flex xs12 sm12 md12>
                        <v-avatar v-if="avatar" size="40" class="avatar-header">
                            <v-img v-if="avatar" :src="avatar"></v-img>
                        </v-avatar>
                        <v-card-title>
                            {{ (contact_name) ? contact_name : contact_id }}
                        </v-card-title>
                    </v-flex>

                    <v-flex xs12 sm12 md4>
                        <v-text-field outlined v-model="weekly_advance" label="Adiantamento da Semana" :readonly="true"
                            prepend-inner-icon="mdi-handshake-outline">
                        </v-text-field>
                    </v-flex>

                    <v-flex xs12 sm12 md4>
                        <v-text-field outlined v-model="limit" label="Limite" :readonly="true"
                            prepend-inner-icon="mdi-cash-100"></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm12 md4 v-if="showFormDepositRakeback">
                        <v-text-field required label="Adiantamento Disponível para Depósito *"
                            v-model="rakebackAvailable" :readonly="true" outlined
                            prepend-inner-icon="mdi-cash-plus"></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm12 md12>
                        <v-card-title>
                            Novo Adiantamento
                        </v-card-title>



                    </v-flex>

                    <v-container>

                        <v-row>
                            <v-col v-for="(item, index) in rakebacks" :key="item.id" cols="12" sm="6" md="4"
                                class="mb-3">
                                <v-form ref="formRake" v-model="validRake" lazy-validation>
                                    <v-card :elevation="5" class="pa-1">
                                        <v-card-title class="text-h6">
                                            Rakeback {{ item.club ? getClubName(item.club) : index + 1 }}
                                        </v-card-title>
                                        <v-card-text>
                                            <v-autocomplete :rules="item.not_informed == true ? [] : [rules.required]"
                                                label="Clube" :items="clubsClient" v-model="item.club" readonly
                                                :disabled="item.not_informed" :error-messages="errors.collect('clube')"
                                                item-text="name" item-value="appId" data-vv-name="id"
                                                outlined></v-autocomplete>

                                            <v-text-field :rules="item.not_informed == true ? [] : [rules.required]"
                                                label="Rake Gerado *" v-model="item.rake" outlined
                                                :error-messages="errors.collect('value')" :disabled="item.not_informed"
                                                v-validate="'required:Valor,rake|decimal:2'"
                                                data-vv-name="rake"></v-text-field>


                                            <v-checkbox v-model="item.not_informed" label="Não Informar"
                                                class="mt-0"></v-checkbox>

                                        </v-card-text>

                                        <v-card-text class="text-center" v-if="showFormDepositRakeback">
                                            <strong>Adiantamento Disponível: </strong> R$ {{ getValueByClub(item.club)
                                            }}
                                        </v-card-text>
                                    </v-card>

                                </v-form>
                            </v-col>
                        </v-row>

                    </v-container>


                    <v-container v-if="showFormDepositRakeback">

                        <v-row class="pb-0">
                            <v-col cols="12" class="pb-0">
                                <v-card-title>
                                    Formulário de Depósito
                                </v-card-title>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols="auto">
                                <v-radio-group v-model="selectedOption" row>
                                    <v-radio label="Selecionar Agente" value="selectAgente"></v-radio>
                                    <v-radio label="Preencher Manualmente" value="manually"
                                        @change="changedOption"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>

                        <v-form ref="formDeposit" v-model="validDeposit" lazy-validation
                            v-if="selectedOption == 'selectAgente'">
                            <v-row>

                                <v-col cols="12" sm="6" md="6">

                                    <v-select :rules="[rules.required]" label="Agente*" :items="agentsClient"
                                        v-model="agentSelected" :readonly="false" :item-text="formatAgentText"
                                        @change="setFieldsDeposit" item-value="player_id" outlined></v-select>
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field label="Valor *" v-model="rakebackAvailable" :readonly="true"
                                        outlined></v-text-field>
                                </v-col>
                            </v-row>

                        </v-form>



                        <v-form ref="formDeposit" v-model="validDeposit" lazy-validation
                            v-if="selectedOption == 'manually'">
                            <v-row>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field label="Valor *" v-model="rakebackAvailable" :readonly="true"
                                        outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field :rules="[rules.required]" label="ID Suprema*"
                                        v-model="deposit_rakeback.id_pppoker" :readonly="false" outlined></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="4" md="4">
                                    <v-autocomplete :rules="[rules.required]" label="Clube*" :items="clubs"
                                        v-model="deposit_rakeback.club" :readonly="false" item-text="clubLabel"
                                        item-value="club" outlined></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>



                </v-layout>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-col>
                <v-btn text @click="closeRake">Cancelar
                </v-btn>
            </v-col>
            <v-col class="text-center">
                <v-btn text type="submit" right @click="calcRake">
                    Calcular
                </v-btn>
            </v-col>
            <v-col class="text-right">
                <v-btn v-if="showFormDepositRakeback" text type="submit" right @click="createDepositRake">
                    Criar Depósito
                </v-btn>
            </v-col>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
export default {
    computed: {
        ...mapState("chat", [
            "camp",
            "rakebackAvailable",
            "inputRakebackAvailable",
            "deposit_rakeback",
            "showFormDepositRakeback",
            "rakebackCalculate"
        ]),
        ...mapState("rakeback", ["agentsClient", "clubsClient"]),
        ...mapState("instances", ["instances"]),
        ...mapState("clubs", ["clubs"]),
        ...mapState("bank", { banks: "items" }),
        ...mapFields("rakeback", [
            "dialogReceipt",
            "weekly_advance",
            "limit",
            "editedRake.rake",
            "editedRake.date_picker",
            "editedRake.id_agent",
            "editedRake.club",
            "editedRake.type",
            "editedRake.bank",
            "rakebacks"
        ]),
        ...mapState("login", ["userData"]),
        chipsList() {
            return this.mode == 'VIEW' ? this.chips_contact.filter(c => c.status == 1) : this.chips_contact
        },
        showAddTo() {
            let roles = [2, 5, 80, 81, 97, 98, 99]
            return roles.indexOf(this.userData.role) != -1
        },
        formatAgentText() {
            return (agent) => `${agent.player_id} - ${agent.nickname} | ${agent.club.name} `;
        }
    },
    props: [
        'avatar',
        'contact_id',
        'contact_name',
        'description',
        'screen',
        'start_mode',
        'showContactDescription',
        'openedChat'
    ],
    created() {
        this.getClubs({ resetPage: false, noPages: true, onlyActive: true });
        this.$validator.extend("required", {
            getMessage: (field, [label]) => label + " deve ser informado",
            validate: (_, [, field]) => {
                if (this[field] || this[field] === 0) {
                    return true;
                } else {
                    return false;
                }
            },
        });
        this.mode = this.start_mode
    },
    data: () => ({
        validRake: false,
        validDeposit: false,
        selectedOption: 'selectAgente',
        agentSelected: {},
        rules: {
            required(value) {
                return !!value || 'Campo Obrigatório'
            },
        },
        possible_types: [
            { id: 0, description: "Envio de Fichas Jogador" },
            { id: 1, description: "Envio de Fichas Agência" },
            { id: 2, description: "Abater Anotados" },
            { id: 3, description: "Adicionar ao Saldo Positivo" },
            { id: 4, description: "Transferência de Fichas" }
        ],
        possible_status: [
            { id: 0, description: "Pendente" }
        ],
        payment_types: [
            { id: 1, description: "Anotado" }
        ],
        mode: '',
        // rakebacks: [{ club: 0, rake: 0 }]
    }),
    methods: {
        ...mapMutations("deposit", ["setBank", "setPaymentType"]),
        ...mapMutations('chat', ["showDialogContactDescription"]),
        ...mapActions('chat', [
            'saveContactDescriptionChange',
            'calcRakebackSox',
            'createNewDepositRake',
            'closeDialogRakeback'
        ]),
        ...mapActions("clubs", [
            "getClubs",
        ]),

        ...mapActions("deposit", ["save"]),
        changedOption() {
            this.agentSelected = null;
            this.deposit_rakeback.id_pppoker = '';
            this.deposit_rakeback.club = null;
        },
        setFieldsDeposit(e) {
            const agent = this.agentsClient.find(agent => agent.player_id === e);
            this.deposit_rakeback.id_pppoker = agent ? agent.player_id.toString() : null;
            this.deposit_rakeback.club = agent ? agent.club.appId : null;
        },
        async createDepositRake() {
            console.log(this.deposit_rakeback)
            const form = this.$refs.formDeposit;
            const valid = await form.validate();
            if (valid) {

                if (this.rakebackAvailable > 0) {
                    let rakebacksToSave = this.rakebacks.filter(rakeback => !rakeback.not_informed);
                    rakebacksToSave = rakebacksToSave.map(rakeback => {
                    const club = this.clubs.find(club => club.club === rakeback.club);
                    return {
                        ...rakeback,
                        league: club ? club.league : null,
                    };
                });
                    this.createNewDepositRake({ rakebacks: rakebacksToSave })
                }
            }

        },
        getClubName(id) {
            const club = this.clubs.find(club => club.club === id);
            return club ? club.clubLabel : "";
        },
        closeRake() {
            console.log("aqui")
            this.closeDialogRakeback()
        },
        getValueByClub(id) {
            console.log(this.rakebackCalculate)
            if (this.rakebackCalculate && this.rakebackCalculate.valuesPerClub) {
                let club = this.rakebackCalculate.valuesPerClub.find((item) => item.idClub == id)
                return club ? club.value.toFixed(2) : '0.00'

            }
            return '0.00'

        },
        async calcRake() {
            const form = this.$refs.formRake;
            let formsValid = true;
            await form.forEach(async (f) => {
                const valid = await f.validate();
                formsValid = valid && formsValid;
            });
            console.log("formsValid", formsValid)

            if (formsValid) {
                let rakebacksToCalc = this.rakebacks.filter(rakeback => !rakeback.not_informed);
                rakebacksToCalc = rakebacksToCalc.map(rakeback => {
                    console.log("rakeback", rakeback)

                    const club = this.clubs.find(club => club.club === rakeback.club);
                    return {
                        ...rakeback,
                        league: club ? club.league : null,
                    };
                });
                this.calcRakebackSox({ rakebacks: rakebacksToCalc });
            }
        },
        getTextBank(item) {
            if (item.account) {
                return item.name + ' | ' + item.account
            } else {
                return item.name
            }
        },
        typeSelectOnChange() {
            let idTransfer = this.banks.filter((bank) => bank.code === '1000')[0].id
            if (this.type == 4) {
                this.setBank({ bank: idTransfer })
                this.setPaymentType({ paymentType: 0 })
            } else if (this.bank === idTransfer) {
                this.setBank({ bank: '' })
            }
        }
    },
    watch: {
        showContactDescription: function () {
            if (this.showContactDescription == false) {
                this.mode = 'VIEW'
            }
        }
    }
}
</script>

<style>
@import './css/contact_description.css';
</style>
