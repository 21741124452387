<template>
    <div class="elevation-10">
        <v-tabs
            next-icon="mdi-arrow-right-bold-box-outline"
            prev-icon="mdi-arrow-left-bold-box-outline"
            show-arrows
            v-model="tab"
        >
            <v-tab>Bancos Ativos</v-tab>
            <v-tab>Bancos Inativos</v-tab>
            <v-tab>Clientes</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item :key="0">
                <v-toolbar flat class="elevation-5">
                    <div>
                        <v-toolbar-title>Saldo Bancos Ativos</v-toolbar-title>
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn  @click="getBalances({ active: true })">Atualizar</v-btn>   
                </v-toolbar>  
                <bank-balance-data-table 
                    :items="balancesActive" 
                    :loading="balancesActiveLoading" 
                    :hideTotalTypeBalances="balancesActiveLoading" 
                    :hideTotalBalances="balancesActiveLoading || balancesInactiveLoading"
                    :totalTypeBalances="totalActiveBalance"
                    :totalBalances="totalBalances"
                ></bank-balance-data-table>
            </v-tab-item>
            <v-tab-item :key="1">
                <v-toolbar flat class="elevation-5">
                    <div>
                        <v-toolbar-title>Saldo Bancos Inativos</v-toolbar-title>
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn  @click="getBalances({ active: false })">Atualizar</v-btn>   
                </v-toolbar>    
                <bank-balance-data-table 
                    :items="balancesInactive" 
                    :loading="balancesInactiveLoading" 
                    :hideTotalTypeBalances="balancesInactiveLoading" 
                    :hideTotalBalances="balancesActiveLoading || balancesInactiveLoading"
                    :totalTypeBalances="totalInactiveBalance"
                    :totalBalances="totalBalances"
                ></bank-balance-data-table>
            </v-tab-item>
            <v-tab-item :key="2">
                <v-toolbar flat class="elevation-5">
                    <div>
                        <v-toolbar-title>Saldo Clientes</v-toolbar-title>
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn  @click="getBalancesClients()">Atualizar</v-btn>   
                </v-toolbar>   
                <v-data-table
                    :headers="headersClient"
                    :items="balancesClients"
                    class="elevation-5"
                    hide-default-footer
                    show-expand
                    :expanded.sync="expanded"
                    :single-expand="singleExpand"
                    item-key="clientName"
                >
                    <template v-slot:expanded-item="{ item }">
                        <td colspan="3" >
                            <v-row class="mt-2">
                                <span class="text-h6">Saldo Dispónivel:</span>
                            </v-row>
                            <v-row v-for = "(config, index) in expandedConfig.complete" :key="index" class="align-center">
                                <v-icon class="mr-2" :color="config.color">{{ config.icon }}</v-icon>
                                <span>{{ config.label }}:</span>
                                <v-spacer></v-spacer>
                                <span :color="config.color" :class="getTextClass(config)">{{ item[config.key] ? getBalanceFormatted(item[config.key]) : "-" }}&nbsp;&nbsp;</span>
                                <span>(</span>
                                <v-icon>{{ config.type == 'add' ? 'mdi-plus' : 'mdi-minus' }}</v-icon>
                                <span>)</span>
                            </v-row>
                            <v-row class="mt-5 mb-2 align-center">
                                <strong>Total Dispónivel:</strong>
                                <v-spacer></v-spacer>
                                <span :class="getClassBalance(item.balanceCompleted)">{{  getBalanceFormatted(item.balanceCompleted) }}&nbsp;&nbsp;</span>
                            </v-row>
                            <v-row class="mt-2">
                                <span class="text-h6">Saldo Futuro:</span>
                            </v-row>
                            <v-row v-for = "(config, index) in expandedConfig.future" :key="index" class="align-center">
                                <v-icon class="mr-2" :color="config.color">{{ config.icon }}</v-icon>
                                <span>{{ config.label }}:</span>
                                <v-spacer></v-spacer>
                                <span :color="config.color" :class="getTextClass(config)">{{ item[config.key] ? getBalanceFormatted(item[config.key]) : "-" }}&nbsp;&nbsp;</span>
                                <span>(</span>
                                <v-icon>{{ config.type == 'add' ? 'mdi-plus' : 'mdi-minus' }}</v-icon>
                                <span>)</span>
                            </v-row>
                            <v-row class="mt-5 mb-2 align-center">
                                <strong>Total Futuro:</strong>
                                <v-spacer></v-spacer>
                                <span :class="getClassBalance(item.balancePending)">{{  getBalanceFormatted(item.balancePending) }}&nbsp;&nbsp;</span>
                            </v-row>
                        </td>
                    </template>
                    <template v-slot:[`item.balanceCompleted`]="{ item }">
                        {{ item.balanceCompleted  ? getBalanceFormatted(item.balanceCompleted) : "-" }}
                    </template>
                </v-data-table>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BankBalanceDataTable from './BankBalanceDataTable.vue';

export default {
    components: {
        'bank-balance-data-table': BankBalanceDataTable
    },  
    computed: {
        ...mapState('bank_balance', ['balancesActive', 'balancesInactive', 'balancesClients', 'balancesActiveLoading', 'balancesInactiveLoading', 'balancesClientsLoading']),
        totalActiveBalance() {
            return this.balancesActive.reduce((soma, item) => soma += item.balance, 0);
        },
        totalInactiveBalance() {
            return this.balancesInactive.reduce((soma, item) => soma += item.balance, 0);
        },
        totalBalances() {
            return this.totalActiveBalance + this.totalInactiveBalance;
        },
    },
    data() {
        return {
            headersClient: [
                { text: 'Cliente', value: 'clientName' },
                { text: "Saldo", value: 'balanceCompleted', align: 'right' },
            ],
            expandedConfig: {
                complete: [
                    { label: "Auto Atendimento (Completo)", key: "autoAttendanceCompleted", icon: 'mdi-cash', color: 'green', type: 'add'},
                    { label: "Saldo Adicionado (Completo)", key: "addBalanceCompleted", icon: 'mdi-cash', color: 'green', type: 'add'},
                    { label: "Saques (Completo)", key: "withdrawCompleted", icon: 'mdi-cash', color: 'red', type: 'remove'},
                    { label: "Saques (Em Progresso)", key: "withdrawInProgress", icon: 'mdi-cash', color: 'red', type: 'remove'},
                    { label: "Saldo Removido (Completo)", key: "removeBalanceCompleted", icon: 'mdi-cash', color: 'red', type: 'remove'},
                    { label: "Saldo Removido (Em Progresso)", key: "removeBalanceInProgress", icon: 'mdi-cash', color: 'red', type: 'remove'},
                ],
                future: [
                    { label: "Auto Atendimento (Pendente)", key: "autoAttendancePending", icon: 'mdi-cash', color: 'green', type: 'add'},
                    { label: "Auto Atendimento (Em Progresso)", key: "autoAttendanceInProgress", icon: 'mdi-cash', color: 'green', type: 'add'},
                    { label: "Saldo Adicionado (Pendente)", key: "addBalancePending", icon: 'mdi-cash', color: 'green', type: 'add'},
                    { label: "Saques (Pendente)", key: "withdrawPending", icon: 'mdi-cash', color: 'red', type: 'remove'},
                    { label: "Saldo Removido (Pendente)", key: "removeBalancePending", icon: 'mdi-cash', color: 'red', type: 'remove'},
                ]
            },
            tab: null,
            expanded: [],
            singleExpand: true
        }
    },
    created() {
        this.getBalances({ active: true });
        this.getBalances({ active: false });
        this.getBalancesClients();
    },
    methods: {
        ...mapActions('bank_balance', ['getBalances', 'getBalancesClients']),
        getBalanceFormatted(balance) {
            if(typeof balance !== 'number') {
                balance = parseFloat(balance);
            }
            return balance.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        },
        balanceIsPositive(balance) {
            if(typeof balance !== 'number') {
                balance = parseFloat(balance);
            }
            return balance >= 0;
        },
        getClassBalance(balance) {
            let classText = 'font-weight-bold text-right'
            if(this.balanceIsPositive(balance)) {
                return classText + ' green--text';
            } else {
                return classText + ' red--text';
            }
        },
        getTextClass(config) {
            let classText = 'font-weight-bold text-right'
            if(config.color === 'green') {
                return classText + ' green--text';
            } else {
                return classText + ' red--text';
            }
        }
    }
}
</script>