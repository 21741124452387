<template>
  <div class="image">
    <img  max-width="120" :src="imgsrc" lazy-src="./loading.png" @click="showExpandImage = !showExpandImage"  :style="{ width: imgWidth + 'px' }">
    <v-dialog
      v-model="showExpandImage"
    >
     <v-card height="90vh">
        <v-img max-height="100%" :src="imgsrc" contain></v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    props: {
    imgsrc: String,
    thumbsrc: String,
    imgWidth: { 
      type: Number,
      default: (window.innerWidth <= 480 ? 90 : 150)
    }
  },
    data: () => ({
      showExpandImage: false,
    }),
    methods: {
      getMaxHeight() {
        return 300
      }
    }
  }
</script>

<style>
  .image{
    margin: 10px 10px 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;
  }
  .message.own .image{
    margin-right: 0;
    margin-left: auto;
  }
  /* .image img{
    width: 150px;
  }
  @media (max-width: 480px) {
    .image img{
      width: 90px;
    }
  } */
</style>