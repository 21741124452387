import axios from 'axios'
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import router from '@config/router'
import store from './store/index'
import Vuelidate from 'vuelidate'
import VeeValidate from 'vee-validate'
import VueGoogleCharts from 'vue-google-charts'
import DatetimePicker from 'vuetify-datetime-picker'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib/index'
//import VueExcelXlsx from "vue-excel-xlsx";
import { EmojiPickerPlugin } from 'vue-emoji-picker'


import './sass/main.scss'

Vue.config.productionTip = false

VeeValidate.Validator.localize({
    pt: {
        messages: {
            required: (field) => field + ' não foi informado'
        }
    }
});
Vue.use(VueTelInputVuetify, { 
  vuetify,
 });
Vue.use(Vuelidate)
Vue.use(DatetimePicker)
Vue.use(VueGoogleCharts)
//Vue.use(VueExcelXlsx);
Vue.use(VeeValidate, {
    classes: true,
    classNames: {
        valid: 'is-valid',
        invalid: 'is-invalid'
    }

});
Vue.use(EmojiPickerPlugin)

// Define o token de acesso na requisição
axios.interceptors.request.use(function(config) {
  let url = config.url
  if(url.indexOf("validate_token") == -1) {
    let token = store.state.login.accessToken
    if (token) {
      config.headers.Authorization = `${token}`;
    }
  }
 

  // let ip = store.state.login.userIp
  // if (ip) {
  //   config.headers.UserIP = `${ip}`;
  // }
  return config;
}, function(err) {
  return Promise.reject(err);
});
Vue.prototype.$http = axios;

// Redireciona se não estiver autenticado
Vue.prototype.$http.interceptors.response.use(undefined, function(err) {
  return new Promise(function() {
      if (err?.response?.status == 401 && !err?.config?.__isRetryRequest) {
        console.log('reuqest 401')
        store.dispatch("login/unauthenticated", err)
      } else {
        throw err
      }
  })
})

fetch('https://api.ipify.org?format=json')
.then(x => x.json())
.catch( () => store.commit("login/setUserIp", {ip: '177.85.86.31'}))
.then(({ ip }) => {
    store.commit("login/setUserIp", {ip: ip})
})
.catch(() =>  store.commit("login/setUserIp", {ip: '177.85.86.31'}))

if(window.location.href.indexOf('/loginsx') == -1) {
  store.dispatch("login/getAccessTokenFromLocalStorage")
}

new Vue({
  vuetify,
  router,
  store: store,
  render: h => h(App)
}).$mount('#app')
