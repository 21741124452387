<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">Filtro</span>

        <v-col class="text-right">
          <v-btn v-if="filtered" color="white"  @click="clearFilter" right small depressed>
            <v-icon color="black">mdi-filter-remove</v-icon>
          </v-btn>
        </v-col>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-select
                  label="Tipo de Relatório"
                  :items="possible_reports"
                  v-model="type_report"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                  label="Tipo de Anotado"
                  :items="types_annotated"
                  v-model="type_annotated"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-datetime-picker
                  v-model="start"
                  date-format="dd/MM/yyyy"
                  clearText="Limpar"
                  okText="Confirmar"
                  :time-picker-props="timeProps"
                  label="De *"
                  :textFieldProps="{outlined: true}"
                >
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-datetime-picker
                  v-model="end"
                  date-format="dd/MM/yyyy"
                  clearText="Limpar"
                  okText="Confirmar"
                  label="Até *"
                  :time-picker-props="timeProps"
                  :textFieldProps="{outlined: true}"
                >
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-autocomplete
                  clearable
                  deletable-chips
                  v-model="contact_id"
                  :no-filter="true"
                  :items="contacts"
                  outlined
                  chips
                  small-chips
                  no-data-text="Nenhum contato encontrado"
                  :item-text="getTextContact"
                  item-value="_id"
                  placeholder="Digite aqui o contato do agente"
                  label="Contato"
                  :search-input.sync="searchContact"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                  label="Origem"
                  :items="possible_origins"
                  v-model="origin"
                  item-text="description"
                  item-value="id"
                  outlined
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  required
                  label="ID da Transação"
                  v-model="id_transaction"
                  data-vv-name="id_transaction"
                  outlined
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn text @click="showFilter({show: false})">Cancelar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text type="submit" right @click="filter">Filtrar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import debounce from '../../../util/debounce.js' 

export default {
  name: "WithdrawlsFilter",
  computed: {
    ...mapState("slots", [
      "slots"
    ]),
    ...mapState("manage_annotated", ["agents_annotated", "contacts", "filtered", "master_agents"]),
    ...mapFields("manage_annotated", [
      "filter.id_agent",
      "filter.contact_id",
      "filter.end",
      "filter.id_instance",
      "filter.start",
      "filter.user_id",
      "filter.type_report",
      "filter.origin",
      "filter.id_transaction",
      "filter.type_annotated"
      // ...
    ])
  },
  data: () => ({
    possible_reports: [
      { id: 0, description: "Posição Atual" },
      { id: 1, description: "Histórico de Movimentações" },
    ],
    types_annotated: [
      { id: 0, description: "Anotado"},
      { id: 1, description: "Adiantamento de Rakeback"}
    ],
    possible_origins: [
      { id: 0, description: "Manual" },
      { id: 1, description: "Fechamento" },
      { id: 2, description: "Deposito" },
      { id: 3, description: "Saque" },
      { id: 4, description: "Correção Fechamento" },
      { id: 5, description: "Correção de Saldo Incorreto" },
      { id: 6, description: "Correção de Valor Lançado Incorretamente" },
      { id: 7, description: "Multa Suprema" }
    ],
    timeProps: {
      format: "24hr",
    },
    searchContact: '',
    searchAgent: ''
  }),
  mixins: [debounce],
  mounted() {
    this.getContacts = this.debounce(this.getContacts, 500)
    this.getAgents = this.debounce(this.getAgents, 500)
  },
  methods: {
    ...mapMutations("manage_annotated", ["showFilter"]),
    ...mapActions("manage_annotated", ["getAgents", "getContacts", "getItems", "getMasterAgents"]),
    getTextContact(item) {
      if(item.name) {
        if(item.name.length == 1) {
          return item.name
        }
        
        var text = ""
        item.name.forEach((name) => {
          text += name + " | "
        })
        return text
      }
      else {
        return text
      }
    },
    filter() {
      this.getItems({resetPage: true});
      this.showFilter({ show: false });
    },
  },
  watch: {
    agents_annotated() {
      console.log('Trocou mas n trocou')
    },
    searchContact (val) {
      var payload = {
        search: val,
        contacts: this.selected_contact
      }
      this.getContacts(payload)
    },
    searchAgent (val) {
      var payload = {
        search: val
      }
      this.getAgents(payload)
    }
  }
};
</script>

<style>
</style>