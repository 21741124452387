import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import endPoints from "../../config/endPoints";
import { defaultErrorNotification } from "../../constants";

const defaultNumberItem = {
    phone: "",
};



export default {
    namespaced: true,
    state: {
        items: [],
        selectedItem: {},
        pagination: {
            page: 1,
            limit: 10,
            sortBy: [],
            sortDesc: []
        },

        notification: {
            show: false,
            message: "",
            type: "",
        },
        loading: false,
        dialog: false,
        mode: "",

    },
    mutations: {
        setItems(state, items) {
            state.items = items;
        },
        closeDialog: (state) => {
            state.selectedItem = {};
            state.dialog = false;
            state.mode = "LIST";
        },

        setShowDialog: (state, payload) => {
            console.log(payload)
            state.dialog = payload;
            state.selectedItem = Object.assign({}, defaultNumberItem);
        },
        setLoading(state, loading) {
            state.loading = loading;
        },
        setPage(state, { page, limit }) {
            state.pagination.page = page;
            state.pagination.limit = limit;
        },
        setTotalItems(state, total) {
            state.pagination.totalItems = total;
        },
        showNotification(state, notification) {
            state.notification = notification;
        },
        edit: (state, payload) => {
            state.selectedItem = Object.assign({}, payload.item);
            state.dialog = true;
            state.mode = "EDIT";
        },
        view: (state, payload) => {
            state.selectedItem = Object.assign({}, payload);
            state.selectedItem.backgroundImgUrl = payload.urlBucketAws + '/background.png'
            state.dialog = true;
            state.mode = "VIEW";
        },
        addConfig: (state) => {
            state.dialog = true;
            state.mode = "ADD";
            state.selectedItem = Object.assign({}, defaultNumberItem);
        },

        updateField,
    },
    actions: {
        closeDialog: ({ commit }) => {
            commit("closeDialog");
        },

        save: async ({ commit, state, dispatch }) => {
            let url = endPoints.help_numbers;
            let func = '';
            if (state.mode === "ADD") {
                func = axios.post;
            } else {
                func = axios.put;
                url += "/" + state.selectedItem.id;
            }
            commit("setLoading", true);
            try {
                await func(url, state.selectedItem);
                commit("showNotification", {
                    show: true,
                    message: "Número salvo com sucesso",
                    type: "success",
                });
                commit("closeDialog");
                dispatch("getItems", { resetPage: true });
            } catch (error) {
                commit(
                    "showNotification",
                    defaultErrorNotification(error?.response?.data?.message)
                );
            }
            commit("setLoading", false);
        },
        getItems: async ({ commit, state }, payload) => {
            let url = endPoints.help_numbers;
            if (payload.resetPage) {
                url += "?page=" + 1 + "&" + "limit=" + 10;
                commit("setPage", { page: 1, limit: 10 });
            } else {
                url +=
                    "?page=" +
                    state.pagination.page +
                    "&" +
                    "limit=" +
                    state.pagination.limit;
            }

            if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
                url += "&sort_by=" + (state.pagination.sortBy[0] === 'score' ? "id" : state.pagination.sortBy[0])
                url += "&sort_desc=" + state.pagination.sortDesc[0]
            }

            commit("setLoading", true);

            try {
                let items = await axios.get(url);
                commit("setItems", items.data)
                console.log(items.data)
                commit("setTotalItems", items.data.total);

            } catch (error) {
                commit(
                    "showNotification",
                    defaultErrorNotification(error?.response?.data?.message)
                );

            }
            commit("setLoading", false);
        },

    },
    getters: {
        getField,
    },
};
