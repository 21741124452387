import axios from "axios";
import endPoints from "../../../config/endPoints";

export default {
    namespaced: true,
    state: {
        balancesActive: [],
        balancesInactive: [],
        balancesClients: [],
        balancesActiveLoading: false,
        balancesInactiveLoading: false,
        balancesClientsLoading: false,
    },
    mutations: {
        setBalanceActive(state, payload) {
            console.log(payload)
            state.balancesActive = payload.items;
        },
        setBalancesInactive(state, payload) {
            state.balancesInactive = payload.items;
        },
        setBalanceActiveLoading(state, payload) {
            state.balancesActiveLoading = payload.show;
        },
        setBalancesInactiveLoading(state, payload) {
            state.balancesInactiveLoading = payload.show;
        },
        setBalancesClients(state, payload) {
            console.log("Items: ", payload.items)
            state.balancesClients = payload.items;
        },
        setBalancesClientsLoading(state, payload) {
            state.balancesClientsLoading = payload.show;
        }
    },  
    actions: {
        async getBalances({commit}, payload) {
            if(payload.active) {
                commit('setBalanceActiveLoading', {show: true})
            }
            else {
                commit('setBalancesInactiveLoading', {show: true})
            }
            let { data, error } = await axios.get(endPoints.bank_balances + `?active=${payload.active}`)
            if(payload.active) {
                commit('setBalanceActiveLoading', {show: false})
            }
            else {
                commit('setBalancesInactiveLoading', {show: false})
            }

            if (error) {   
                console.log(error)
            } else {
                if(payload.active) {
                    commit('setBalanceActive', {items: data.data})
                }
                else {
                    commit('setBalancesInactive', {items: data.data})
                }
            }
        },
        async getBalancesClients({commit}) {
            commit('setBalancesClientsLoading', {show: true})
            let { data, error } = await axios.get(endPoints.clients_24h + "/balance")
            commit('setBalancesClientsLoading', {show: false})
            if (error) {   
                console.log(error)
            } else {
                commit('setBalancesClients', {items: data.data})
            }
        }
    }
}